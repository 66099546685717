@font-face {
  font-family: 'WP-Insight';
  src: url('fonts/WP-Insight.eot?pli7vd');
  src: url('fonts/WP-Insight.eot?pli7vd#iefix') format('embedded-opentype'),
    url('fonts/WP-Insight.ttf?pli7vd') format('truetype'),
    url('fonts/WP-Insight.woff?pli7vd') format('woff'),
    url('fonts/WP-Insight.svg?pli7vd#WP-Insight') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.insight {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'WP-Insight' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.wpi-subscription:before {
  content: '\e929';
}
.wpi-info:before {
  content: '\e928';
}
.wpi-analysis:before {
  content: '\e923';
}
.wpi-building:before {
  content: '\e924';
}
.wpi-gear:before {
  content: '\e925';
}
.wpi-pie-chart:before {
  content: '\e926';
}
.wpi-report:before {
  content: '\e927';
}
.wpi-dashboard:before {
  content: '\e900';
}
.wpi-user:before {
  content: '\e901';
}
.wpi-users:before {
  content: '\e902';
}
.wpi-bookmark:before {
  content: '\e903';
}
.wpi-chart:before {
  content: '\e904';
}
.wpi-logout:before {
  content: '\e905';
}
.wpi-check:before {
  content: '\e906';
}
.wpi-square:before {
  content: '\e907';
}
.wpi-check-square-alt:before {
  content: '\e908';
}
.wpi-check-square:before {
  content: '\e909';
}
.wpi-circle:before {
  content: '\e90a';
}
.wpi-check-circle-alt:before {
  content: '\e90b';
}
.wpi-check-circle .path1:before {
  content: '\e90c';
  color: rgb(0, 0, 0);
}
.wpi-check-circle .path2:before {
  content: '\e90d';
  margin-left: -1em;
  color: rgb(250, 250, 250);
}
.wpi-close:before {
  content: '\e90e';
}
.wpi-close-circle:before {
  content: '\e90f';
}
.wpi-date:before {
  content: '\e910';
}
.wpi-search:before {
  content: '\e911';
}
.wpi-edit:before {
  content: '\e912';
}
.wpi-plus:before {
  content: '\e913';
}
.wpi-delete:before {
  content: '\e914';
}
.wpi-eye:before {
  content: '\e915';
}
.wpi-share:before {
  content: '\e916';
}
.wpi-sort-up:before {
  content: '\e917';
}
.wpi-sort-down:before {
  content: '\e918';
}
.wpi-sort-left:before {
  content: '\e919';
}
.wpi-sort-right:before {
  content: '\e91a';
}
.wpi-arrow-down:before {
  content: '\e91b';
}
.wpi-arrow-up:before {
  content: '\e91c';
}
.wpi-toggler:before {
  content: '\e91d';
}
.wpi-toggler-square .path1:before {
  content: '\e91e';
  color: rgb(0, 0, 0);
  opacity: 0.5;
}
.wpi-toggler-square .path2:before {
  content: '\e91f';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.wpi-toggler-square .path3:before {
  content: '\e920';
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.5;
}
.wpi-toggler-square .path4:before {
  content: '\e921';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.wpi-minus:before {
  content: '\e922';
}
