
// section gap
.SectionGap {
	margin-top: 50px;
	@include respond-below(xl){
		margin-top: 40px;
	}
	@include respond-below(lg){
		margin-top: 30px;
	}
	@include respond-below(md){
		margin-top: 25px;
	}
	@include respond-below(sm){
		margin-top: 20px;
	}
	@include respond-below(xs){
		margin-top: 15px;
	}
}
// col gap
.HasColGap {
	margin-bottom: -30px;
	> .col-md-1,
	> .col-md-2,
	> .col-md-3,
	> .col-md-4,
	> .col-md-5,
	> .col-md-6,
	> .col-md-7,
	> .col-md-8,
	> .col-md-9,
	> .col-md-10,
	> .col-md-11,
	> .col-md-12,
	> .col {
		margin-bottom: 30px !important;
	}
}

// page wrapper
.WpPageBody {
	display: flex;
}

// section title
.SectionTitleWrap {
	display: flex;
	align-items: center;
	margin-bottom: 50px;
	flex-wrap: wrap;
	@include respond-below(xl){
		margin-bottom: 40px;
	}
	@include respond-below(lg){
		margin-bottom: 30px;
	}
	@include respond-below(md){
		margin-bottom: 25px;
	}
	@include respond-below(sm){
		margin-bottom: 20px;
	}
	@include respond-below(xs){
		margin-bottom: 15px;
	}
}
.SectionTitle {
	font-weight: 700;
	line-height: 1,2;
	font-size: 1.375rem;
}

// back button
.BackButton {
	display: flex;
	align-items: center;
	.BackButtonIcon {
		font-size: 0.875rem;
		color: $secondary;
		display: flex;
		align-items: center;
		@include transition;
	}
	.BackButtonText {
		font-weight: 700;
		line-height: 1;
		font-size: 1.375rem;
		margin-left: 20px;
		@include transition;
		@include respond-below(xs){
			display: none;
		}
	}
	@include hover-focus{
		.BackButtonIcon{
			color: darken($primary, 35%);
		}
		.BackButtonText{
			color: darken($primary, 35%);
		}
	}
}

// applicant empty message
.EmptyMessage {
	padding: 30px 26.5px;
	font-size: 1.125rem;
	line-height: 1.5;
	background: #fff;
	border-radius: 10px;
	color: #ff5f74;
	font-weight: 500;
	&:not(first-child) {
		margin-top: 30px;
	}
	&:not(last-child) {
		margin-bottom: 30px;
	}
}


// panel design
.SectionPanel {
	padding: 30px 30px 35px;
	background: $white;
	border-radius: $b-radius-4;
	border: 1.5px solid $border-color;
	position: relative;
	display: inline-block;
	width: 100%;
	@include respond-below(md){
		padding: 20px 20px 25px;
	}
	@include respond-below(sm){
		padding: 15px 15px 20px;
	}
	.PanelTitle{
		display: flex;
		align-items: center;
	}
	.PanelItem {
		transition: all 0.5s linear;
		margin-top: 40px;
		@include respond-below(md){
			margin-top: 20px;
		}
	}
	.PanelToggler {
		background: $white;
		position: absolute;
		top: 100%;
		left: 50%;
		transform: translateX(-50%);
		width: 100px;
		height: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 0 0 $b-radius-2 $b-radius-2;
		color: $secondary;
		box-shadow: 0 5px 10px rgba($dark, 0.05);
		font-size: 0.75rem;
		cursor: pointer;
		.Icon {
			transform: rotateX(180deg);
			display: flex;
		}
	}
	&.PanelCollapsible {
		margin-bottom: 30px;
	}
	&.Collapsed {
		.PanelToggler {
			.Icon {
				transform: rotateX(0deg);
			}
		}
	}
	&.Disable {
		.PanelToggler {
			cursor: no-drop;
			color: $border-color;
		}
	}
}

.SecondarySidebarContent{
	flex: 1;
	height: 100%;
	display: flex;
	flex-direction: column;
	min-width: calc(100% - (300px + 30px));
	@include respond-below(hl){
		min-width: calc(100% - (250px + 30px));
	}
}

.ActionButtonGroup{
	display: flex;
	*:not(:last-child){
		margin-right: 10px;
	}
}