@import 'variables';
@import 'mixins/media';
@import 'reboot';
@import 'functions';
@import 'mixins/transition';
@import 'mixins/hover-focus';
@import 'utilities/background&color';
@import 'utilities/button';
@import 'utilities/navbar';
@import 'utilities/header';
@import 'utilities/dropdown';
@import 'utilities/utilities';
@import 'utilities/form';
@import 'utilities/pagination';
@import 'utilities/login';
@import 'utilities/modal';

//------------------------
//////body structure//////
//------------------------
.WpPageBody {
  .ContentArea {
    flex: 1;
    display: flex;
    flex-direction: column;
    border-radius: $b-radius-50 0 0 $b-radius-50;
    background: $light;
    min-height: 100vh;
    min-width: calc(100% - 300px);
    &Body {
      flex: 1;
      padding: 0 50px 50px;
      display: flex;
      flex-direction: column;
      @include respond-below(xl) {
        padding: 0 40px 40px;
      }
      @include respond-below(lg) {
        padding: 0 30px 30px;
      }
      @include respond-below(md) {
        padding: 0 25px 25px;
      }
      @include respond-below(sm) {
        padding: 0 20px 20px;
      }
      @include respond-below(xs) {
        padding: 0 15px 15px;
      }
    }
  }
}

//-------------------------
///////Every Section///////
//-------------------------

// overview section
.OverviewSection {
  .row-cols-xl-4 {
    @include respond-below(md) {
      margin-left: -5px;
      margin-right: -5px;
      &.HasColGap {
        margin-bottom: -10px !important;
        .col {
          margin-bottom: 10px !important;
        }
      }
    }
    .col {
      @include respond-below(xxl) {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
      }
      @include respond-below(xl) {
        flex: 0 0 50%;
        max-width: 50%;
      }
      @include respond-below(md) {
        padding-left: 5px;
        padding-right: 5px;
      }
      @include respond-below(sm) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
  .OverviewCard {
    padding: 20px 25px;
    border-radius: $b-radius-4;
    border: 1.5px solid $border-color;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: relative;
    @include transition;
    @include respond-below(hl) {
      padding: 25px 30px;
    }
    @include respond-below(md) {
      padding: 15px;
    }
    .ProductTitle {
      font-size: 1.6875rem;
      color: $dark;
      margin-bottom: 10px;
      line-height: 1.1;
      font-weight: 700;
      @include transition;
      @include respond-below(hl) {
        font-size: 1.375rem;
        margin-bottom: 10px;
      }
    }
    .WpiTooltip {
      position: absolute;
      top: 10px;
      right: 10px;
      .WpiTooltipButton {
        display: flex;
        color: $secondary;
        font-size: 16px;
      }
      .WpiTooltipText {
        position: absolute;
        visibility: hidden;
        opacity: 0;
        background: $dark;
        min-width: 130px;
        text-align: center;
        bottom: calc(100% + 10px);
        left: 50%;
        transform: translateX(-50%);
        color: $white;
        z-index: 1001;
        padding: 10px;
        border-radius: 5px;
        line-height: 1.1;
        font-weight: 400;
        font-size: 14px;
        @include transition();
        &:before {
          position: absolute;
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
          content: '';
          border-top: 5px solid $dark;
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
          padding-bottom: 5px;
        }
      }
      &:hover .WpiTooltipText {
        visibility: visible;
        opacity: 1;
      }
    }
    p {
      font-size: 1rem;
      color: $secondary;
      @include transition;
      &:not(:last-of-type) {
        margin-bottom: 2px;
      }
      @include respond-below(hl) {
        font-size: 0.9375rem;
      }
    }
    &:hover {
      background: $primary;
      .ProductTitle {
        color: $white;
      }
      p {
        color: $white;
      }
      .WpiTooltip {
        .WpiTooltipButton {
          color: $border-color;
        }
      }
    }
  }
}

// counter section
.CounterSection {
  @include respond-below(hl) {
    .row-cols-md-4 {
      margin-left: -10px;
      margin-right: -10px;
      &.HasColGap {
        margin-bottom: -20px;
        .col {
          margin-bottom: 20px !important;
        }
      }
      .col {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
  @include respond-below(xxl) {
    .row-cols-md-4 {
      margin-left: -5px;
      margin-right: -5px;
      &.HasColGap {
        margin-bottom: -10px;
        .col {
          margin-bottom: 10px !important;
        }
      }
      .col {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }
  @include respond-below(md) {
    .row-cols-md-4 {
      .col {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
  }
  @include respond-below(xs) {
    .row-cols-md-4 {
      .col {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
  .CounterCard {
    background: $white;
    padding: 25px;
    border-radius: $b-radius-4;
    border: 1.5px solid $border-color;
    height: 100%;
    position: relative;
    @include respond-below(hl) {
      padding: 20px;
    }
    @include respond-below(sm) {
      padding: 10px;
    }
    &.HasSelector {
      position: relative;
      @include respond-below(hl) {
        padding-top: 30px;
      }
      @include respond-below(sm) {
        padding-top: 20px;
      }
      .dropdown {
        position: absolute;
        right: 10px;
        top: 10px;
        @include respond-below(sm) {
          top: 5px;
          right: 5px;
        }
        .DropdownToggler {
          min-width: 150px !important;
          @include respond-below(xl) {
            min-width: 130px !important;
            min-height: 30px;
            font-size: 0.875rem;
          }
          @include respond-below(sm) {
            min-width: 130px !important;
            min-height: 25px;
            font-size: 0.875rem;
          }
        }
      }
      .CounterCardText {
        margin-top: 10px;
      }
    }
    &Content {
      display: flex;
      align-items: center;
      .CounterCardIcon {
        width: 100px;
        height: 100px;
        min-width: 100px;
        border-radius: $b-radius-4;
        font-size: 2.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        @include respond-between(xl, hl) {
          width: 80px;
          height: 80px;
          min-width: 80px;
          font-size: 2rem;
        }
        @include respond-below(xl) {
          width: 60px;
          height: 60px;
          min-width: 60px;
          font-size: 1.75rem;
        }
        @include respond-below(sm) {
          width: 40px;
          height: 40px;
          min-width: 40px;
          font-size: 1rem;
        }
      }
      .CounterCardText {
        margin-left: 20px;
        @include respond-below(sm) {
          margin-left: 10px;
        }
        h4 {
          font-size: 2rem;
          color: $dark;
          margin-bottom: 10px;
          font-weight: 700;
          @include respond-below(xl) {
            font-size: 1.5rem;
            margin-bottom: 5px;
          }
          @include respond-below(sm) {
            font-size: 1.5rem;
            margin-bottom: 2px;
          }
        }
        p {
          font-size: 1.125rem;
          color: $secondary;
          @include respond-below(xl) {
            font-size: 1rem;
          }
          @include respond-below(sm) {
            font-size: 0.75rem;
          }
        }
      }
    }
    .IncDec {
      display: flex;
      margin-top: 10px;
      align-items: center;
      color: $secondary;
      @include respond-below(xl) {
        font-size: 0.9375rem;
      }
      .Icon {
        display: flex;
        margin-right: 10px;
      }
      &.Inc {
        .Icon {
          color: $success;
        }
      }
      &.Dec {
        .Icon {
          color: $danger;
        }
      }
    }
    .WpiTooltip {
      position: absolute;
      top: 10px;
      right: 10px;
      .WpiTooltipButton {
        display: flex;
        color: $secondary;
        font-size: 16px;
      }
      .WpiTooltipText {
        position: absolute;
        visibility: hidden;
        opacity: 0;
        background: $dark;
        min-width: 130px;
        text-align: center;
        bottom: calc(100% + 10px);
        left: 50%;
        transform: translateX(-50%);
        color: $white;
        z-index: 1001;
        padding: 10px;
        border-radius: 5px;
        line-height: 1.1;
        font-weight: 400;
        font-size: 14px;
        @include transition();
        &:before {
          position: absolute;
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
          content: '';
          border-top: 5px solid $dark;
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
          padding-bottom: 5px;
        }
      }
      &:hover .WpiTooltipText {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

// graph section
.GraphSection {
  .row {
    @include respond-below(md) {
      margin-left: -5px;
      margin-right: -5px;
      &.HasColGap {
        margin-bottom: -10px !important;
        .col-md-3 {
          margin-bottom: 10px !important;
        }
      }
    }
    .col-md-3 {
      @include respond-below(xxl) {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
      }
      @include respond-below(md) {
        flex: 0 0 50%;
        max-width: 50%;
        padding-left: 5px;
        padding-right: 5px;
      }
      @include respond-below(xs) {
        flex: 0 0 100%;
        max-width: 100%;
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }
  .GraphCard {
    background: $white;
    border: 1.5px solid $border-color;
    border-radius: $b-radius-4;
    padding: 30px;
    height: 100%;
    display: flex;
    flex-direction: column;
    .GraphBody {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      img {
        max-width: 100%;
      }
    }
  }
}

// Data View Section
.DataViewSection {
  .DataViewFilter {
    background: $white;
    border: 1.5px solid $border-color;
    border-radius: $b-radius-4 $b-radius-4 0 0;
    padding: 30px;
    @include respond-below(md) {
      padding: 25px;
    }
    @include respond-below(xs) {
      padding: 10px;
    }
    .row {
      margin-left: -10px;
      margin-right: -10px;
      margin-bottom: -20px;
      @include respond-below(xl) {
        margin-left: -5px;
        margin-right: -5px;
        margin-bottom: -7px;
      }
      .Col-30,
      .Col-20,
      .Col-15 {
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 20px;
        @include respond-below(xl) {
          padding-left: 5px;
          padding-right: 5px;
          margin-bottom: 7px;
        }
      }
      .Col-30 {
        flex: 0 0 30%;
        max-width: 30%;
        @include respond-below(hl) {
          flex: 0 0 40%;
          max-width: 40%;
        }
        @include respond-below(xxl) {
          flex: 0 0 50%;
          max-width: 50%;
        }
        @include respond-below(lg) {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
      .Col-20 {
        flex: 0 0 20%;
        max-width: 20%;
        @include respond-below(xxl) {
          flex: 0 0 25%;
          max-width: 25%;
        }
        @include respond-below(lg) {
          flex: 0 0 50%;
          max-width: 50%;
        }
        @include respond-below(xs) {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
      .Col-15 {
        flex: 0 0 15%;
        max-width: 15%;
        @include respond-below(hl) {
          flex: 0 0 20%;
          max-width: 20%;
        }
        @include respond-below(xxl) {
          flex: 0 0 25%;
          max-width: 25%;
        }
        @include respond-below(lg) {
          flex: 0 0 50%;
          max-width: 50%;
        }
        @include respond-below(xs) {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
    }
  }
  .DataViewHeading {
    background: $white;
    border: 1.5px solid $border-color;
    display: flex;
    align-items: flex-end;
    padding: 30px 30px 0;
    flex-wrap: wrap-reverse;
    @include respond-below(hl) {
      padding: 25px 30px 0;
    }
    @include respond-below(xxl) {
      padding: 20px 25px 0;
    }
    @include respond-below(xs) {
      padding: 10px 10px 0;
    }
    &:not(:first-child) {
      margin-top: 10px;
    }
    &:first-child {
      border-radius: $b-radius-4 $b-radius-4 0 0;
    }
    &LeftContent {
      margin-bottom: 27px;
      min-height: 50px;
      display: flex;
      align-items: center;
      min-width: 250px;
      @include respond-below(hl) {
        min-height: 46px;
      }
      @include respond-below(xxl) {
        min-width: 200px;
        min-height: 40px;
      }
      @include respond-below(sm) {
        flex-wrap: wrap;
        margin-bottom: 10px;
        > * {
          margin-bottom: 10px;
        }
      }
      .form-control,
      .SelectOption {
        min-width: 200px;
        @include respond-below(xl) {
          min-width: 160px;
        }
      }
    }
    &RightContent {
      margin-bottom: 10px;
      min-height: 50px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      min-width: 250px;
      margin-left: auto;
      @include respond-below(hl) {
        min-height: 46px;
      }
      @include respond-below(xxl) {
        min-height: 40px;
      }
      @include respond-below(lg) {
        flex-wrap: wrap;
        margin-bottom: 0px;
        > * {
          margin-bottom: 10px;
        }
      }
      @include respond-below(sm) {
        min-width: unset;
      }
    }
    .TabCard {
      display: flex;
      .TabControl {
        @extend .SectionTitle;
        padding-bottom: 30px;
        border-bottom: 5px solid transparent;
        margin-top: 15px;
        cursor: pointer;
        color: $secondary;
        @include respond-below(lg) {
          padding-bottom: 20px;
        }
        &:hover {
          color: $dark;
        }
        &.Active {
          border-color: $primary !important;
          color: $dark;
        }
        &:not(:last-child) {
          margin-right: 30px;
        }
      }
    }
  }
  .DataViewTable {
    position: relative;
    .TableWrapper {
      display: block;
      width: 100%;
      overflow-x: auto;
      .DataTable {
        width: calc(100% - 1px);
        color: #212529;
        border-collapse: collapse !important;
        thead {
          tr {
            th {
              padding: 10px;
              .sort {
                margin-left: 15px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                cursor: pointer;
                .sort-up,
                .sort-down {
                  font-size: 8px;
                  display: flex;
                  color: lighten($secondary, 10%);
                  &.active {
                    color: $dark;
                  }
                }
                .sort-up {
                  margin-bottom: -1px;
                }
                .sort-down {
                  margin-top: -1px;
                }
              }
              &:first-of-type {
                padding-left: 30px;
              }
              &:last-of-type {
                padding-right: 15px;
              }
            }
            .TableToggler {
              width: 70px;
              .TogglerIcon {
                height: 30px;
                width: 30px;
                margin-left: auto;
                padding: 7px;
                background: $primary;
                border-radius: $b-radius-2;
                display: flex;
                justify-content: center;
                align-items: center;
                &:hover {
                  background: darken($primary, 5%);
                }
                img {
                  width: 100%;
                  display: flex;
                }
              }
            }
          }
          .Selector {
            width: 70px;
          }
          .dropdown {
            position: static !important;
            .dropdown-menu {
              left: calc(100% - 250px) !important;
              top: 40px !important;
            }
          }
        }
        tbody {
          background: $white;
          border: 2px solid $border-color;
          border-bottom: none !important;
          tr {
            td {
              font-size: 0.875rem;
              padding: 15px 10px;
              &:first-of-type {
                padding-left: 30px;
              }
              &:last-of-type {
                padding-right: 15px;
              }
              &.DetailsButtonWrapper {
                .LabelButton {
                  margin-top: 0;
                  visibility: hidden;
                  height: 0;
                  min-height: 0;
                  opacity: 0;
                  @include transition(all, 0.5s, ease-in-out, 0.25s);
                }
              }
              &.ActionButtonColumn {
                width: 220px;
                @include respond-below(hl) {
                  width: 200px;
                }
              }
              &.ActionButtonBigColumn {
                width: 320px;
              }
              &.TeamMemberImage {
                width: 110px;
                img {
                  width: 50px;
                  height: 50px;
                  border-radius: $b-radius-100;
                  @include respond-below(hl) {
                    width: 46px;
                    height: 46px;
                  }
                }
              }
            }
            &:not(:last-of-type) {
              border-bottom: 1.5px solid $border-color;
            }
            &.Deactivated {
              color: $danger;
            }
            @include hover-focus {
              .DetailsButtonWrapper {
                .LabelButton {
                  margin-top: 10px;
                  height: 24px;
                  min-height: 24px;
                  visibility: visible;
                  opacity: 1;
                }
              }
            }
          }
          .Selector {
            width: 70px;
          }
        }
        &.TableWithDetails {
          td {
            vertical-align: top !important;
          }
        }
      }
      &:last-child {
        &:after {
          content: '';
          width: 100%;
          height: 20px;
          background: $white;
          display: block;
          border-radius: 0 0 $b-radius-4 $b-radius-4;
          border: 1.5px solid $border-color;
          border-top: none !important;
        }
      }
    }
    .TableFooter {
      padding: 0 30px 10px;
      border: 1.5px solid $border-color;
      border-top: 1px solid $border-color;
      border-radius: 0 0 $b-radius-4 $b-radius-4;
      @include respond-below(md) {
        padding: 0 25px 10px;
      }
      @include respond-below(xs) {
        padding: 0 10px 5px;
      }
      background: $white;
      .TableFooterContent {
        display: flex;
        align-items: center;
        padding: 25px 0 20px;
        @include respond-below(md) {
          padding: 15px 0 10px;
        }
        @include respond-below(xs) {
          padding: 10px 0 5px;
          flex-wrap: wrap;
          > *:first-child {
            margin-bottom: 7px;
          }
        }
      }
    }
  }
}

// List view section
.ListViewSection {
  background: $white;
  border: 1.5px solid $border-color;
  border-radius: $b-radius-4;
  height: 100%;
  padding: 20px 0;
  .ListView {
    width: 100%;
    .ListViewItem {
      display: flex;
      padding: 20px 30px;
      @include respond-below(md) {
        padding: 10px 30px;
      }
      @include respond-below(xs) {
        padding: 5px 15px;
      }
      &.HasMultipleContent {
        flex-wrap: wrap;
        padding-bottom: 10px !important;
        .ListViewContent {
          min-width: calc(33.3333% - 10px);
          margin-bottom: 10px;
          @include respond-above(hl) {
            &:nth-child(1),
            &:nth-child(4),
            &:nth-child(7) {
              .ListViewContentTitle {
                min-width: 170px;
              }
            }
          }
        }
        @include respond-between(md, hl) {
          .ListViewContent {
            min-width: calc(50% - 10px);
            &:nth-child(odd) {
              .ListViewContentTitle {
                min-width: 170px;
              }
            }
          }
        }
        @include respond-between(md, xxl) {
          .ListViewContent {
            &:nth-child(odd) {
              .ListViewContentTitle {
                min-width: 140px;
              }
            }
          }
        }
        @include respond-below(md) {
          .ListViewContent {
            min-width: calc(100% - 10px);
            .ListViewContentTitle {
              min-width: 120px;
            }
          }
        }
      }
      .ListViewContent {
        display: flex;
        align-items: center;
        @include respond-below(xs) {
          flex-direction: column;
          align-items: flex-start;
        }
        .ListViewContentTitle {
          display: flex;
          align-items: center;
          margin-right: 30px;
          @include respond-below(xs) {
            margin-right: 0;
            margin-bottom: 5px;
          }
          &:before {
            content: '';
            height: 8px;
            width: 8px;
            min-width: 8px;
            display: inline-block;
            border-radius: $b-radius-100;
            margin-right: 10px;
          }
        }
        .AlertGroup {
          display: flex;
          width: 100%;
          flex-wrap: wrap;
          margin-bottom: -20px;
          margin-right: -10px;
          margin-left: -10px;
          @include respond-below(hl) {
            margin-bottom: -10px;
          }
          @include respond-below(md) {
            margin-bottom: -10px;
            margin-left: 0;
            margin-right: 0;
          }
          .Alert {
            flex: 1;
            flex: 0 0 calc(33.333333% - 20px);
            margin-bottom: 20px;
            margin-left: 10px;
            margin-right: 10px;
            @include respond-below(hl) {
              margin-bottom: 10px;
              flex: 0 0 calc(50% - 20px);
            }
            @include respond-below(md) {
              margin-bottom: 10px;
              margin-left: 0;
              margin-right: 0;
              flex: 0 0 calc(100%);
            }
            &.Active {
              position: relative;
              &:before {
                content: '\e906';
                font-family: 'WP-Insight' !important;
                height: 20px;
                width: 20px;
                font-size: 0.6275rem;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: $b-radius-50;
                background: $success;
                color: $white;
                position: absolute;
                top: 0;
                right: 0;
                transform: translate(50%, -50%);
              }
            }
          }
        }
        &:first-child {
          .ListViewContentTitle {
            min-width: 170px;
            @include respond-below(xxl) {
              min-width: 140px;
            }
            @include respond-below(md) {
              min-width: 120px;
            }
          }
        }
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
      @include hover-focus {
        background: $light;
      }
      &:not(:last-child) {
        border-bottom: 1.5px solid $border-color;
      }
    }
  }
}

// Details View Section
.DetailsViewSection {
  background: $white;
  border: 1.5px solid $border-color;
  border-radius: $b-radius-4;
  height: 100%;
  display: flex;
  .DetailsView {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    .DetailsViewHeading {
      display: flex;
      align-items: center;
      padding: 20px 30px;
      border-bottom: 1px solid $border-color;
      @include respond-below(sm) {
        flex-direction: column;
        align-items: flex-start;
        padding: 10px 15px;
      }
      > *:not(:last-child) {
        margin-right: 10px;
        @include respond-below(sm) {
          margin-right: 0;
          margin-bottom: 5px;
        }
      }
    }
    .DetailsViewContent {
      padding: 30px;
      height: 100%;
      display: flex;
      flex-direction: column;
      @include respond-below(hl) {
        .row {
          margin-bottom: -15px !important;
          .col-md-4 {
            flex: 0 0 50%;
            max-width: 50%;
            margin-bottom: 15px !important;
          }
        }
      }
      @include respond-below(sm) {
        padding: 15px;
        .row {
          margin-bottom: -10px !important;
          .col-md-4 {
            flex: 0 0 100%;
            max-width: 100%;
            margin-bottom: 10px !important;
          }
        }
      }
      .PaginationList {
        margin-top: 30px;
      }
    }
    .ListViewContent {
      display: flex;
      align-items: center;
      @include respond-below(xs) {
        flex-direction: column;
        align-items: flex-start;
      }
      .ListViewContentTitle {
        display: flex;
        align-items: center;
        margin-right: 30px;
        @include respond-below(xs) {
          margin-right: 0;
          margin-bottom: 5px;
        }
        &:before {
          content: '';
          height: 8px;
          width: 8px;
          min-width: 8px;
          display: inline-block;
          border-radius: $b-radius-100;
          margin-right: 10px;
        }
      }
      .AlertGroup {
        display: flex;
        width: 100%;
        .Alert {
          flex: 1;
          &:not(:last-child) {
            margin-right: 30px;
          }
        }
      }
      &:first-child {
        .ListViewContentTitle {
          min-width: 170px;
        }
      }
    }
  }
}

// Form view section
.FormViewSection {
  background: $white;
  border: 1.5px solid $border-color;
  border-radius: $b-radius-4;
  height: 100%;
  padding: 30px;
  @include respond-below(xs) {
    padding: 15px;
  }
  .FormViewDataWrapper {
    display: flex;
    height: 100%;
    @include respond-below(xxl) {
      flex-direction: column-reverse;
    }
    .FormViewDataGroupLeft {
      flex: 1;
    }
    .UpdateOption {
      margin-left: 40px;
      width: 120px;
      display: flex;
      flex-direction: column;
      @include respond-below(xxl) {
        margin-left: 0px;
        margin-bottom: 40px;
      }
    }
  }
  .FormViewGroup {
    display: flex;
    @include respond-below(md) {
      flex-direction: column;
    }
    .FormViewTitle {
      min-width: 220px;
      margin-right: 20px;
      margin-top: 15px;
      @include respond-below(xxl) {
        min-width: 180px;
        margin-top: 12px;
      }
      @include respond-below(md) {
        margin-top: 0 !important;
        margin-bottom: 3px;
      }
    }
    .FormViewData {
      width: 100%;
      padding: 13.5px 20px;
      border: 1.5px solid $border-color;
      border-radius: $b-radius-3;
      word-break: break-word;
      @include respond-below(hl) {
        padding: 12.5px 20px;
      }
      @include respond-below(hl) {
        padding: 10.5px 20px;
      }
      &.TextArea {
        min-height: 200px;
      }
    }
    .FormViewDataPretext {
      white-space: pre-wrap;
      @extend .FormViewData;
      background: $dark;
      color: $secondary;
      padding: 50px;
    }
    &:not(:last-child) {
      margin-bottom: 20px;
      @include respond-below(md) {
        margin-bottom: 10px;
      }
    }
  }
}

.UserRoleSetTable {
  .SelectOption {
    min-width: 250px;
  }
}

.TabControllerWrapper {
  background: darken($light, 4%);
  padding: 30px;
  border-radius: $b-radius-4;
  border: 1px solid $border-color;
  @include respond-below(xs) {
    padding: 15px;
  }
  .nav-item {
    .TabButton {
      @extend .Button;
      border-radius: 0;
      @include respond-below(sm) {
        padding: 4px 10px;
        min-width: 70px;
        min-height: 36px;
      }
      @include respond-below(xs) {
        padding: 4px 10px;
        min-width: 50px;
        min-height: 32px;
      }
    }
    &:first-child {
      .TabButton {
        border-top-left-radius: $b-radius-3;
        @include respond-below(sm) {
          border-top-left-radius: $b-radius-2;
        }
      }
    }
    &:last-child {
      .TabButton {
        border-top-right-radius: $b-radius-3;
        @include respond-below(sm) {
          border-top-right-radius: $b-radius-2;
        }
      }
    }
  }
  .tab-content {
    background: $white;
    padding: 30px;
    border-radius: $b-radius-3;
    border: 1px solid $border-color;
    @include respond-below(xs) {
      padding: 15px;
    }
    .CustomFieldCard {
      margin-bottom: 30px;
      height: calc(100% - 30px) !important;
      background: darken($light, 4%);
      border: 1px solid $border-color;
      border-radius: $b-radius-3;
      @include respond-below(xs) {
        margin-bottom: 15px;
        height: calc(100% - 15px) !important;
      }
      .Checkbox {
        font-size: 1rem;
        display: block;
        input[type='checkbox'] {
          + span:before {
            color: $primary;
            font-size: 0.875rem;
          }
          &:checked + span:before {
            color: $primary;
          }
        }
        span {
          color: $dark;
        }
        &:not(:last-child) {
          margin-bottom: 7px;
        }
      }
      &Head {
        padding: 15px 20px;
        border-bottom: 1px solid $light;
        @include respond-below(xs) {
          padding: 10px 10px;
        }
        &:not(.CheckLeft) {
          .Checkbox {
            display: block;
            position: relative;
            padding-right: 15px;
            span:before {
              position: absolute;
              margin-right: 0;
              right: 0;
            }
          }
        }
      }
      &Body {
        padding: 25px 20px;
        @include respond-below(xs) {
          padding: 15px 10px;
        }
      }
    }
    &:not(:first-child) {
      border-top-left-radius: 0;
    }
  }
}
