.FormGroup {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  label {
    font-size: 1rem;
    color: $dark;
    margin-bottom: 15px;
    height: 20px;
    @include respond-below(hl) {
      margin-bottom: 10px;
      height: 18px;
    }
    @include respond-below(xl) {
      margin-bottom: 5px;
      height: 15px;
    }
  }
  .Button {
    @include respond-below(hl) {
      width: 100%;
    }
  }
}

// form control
input.form-control {
  height: 50px;
  padding: 0 15px !important;
  font-size: 1rem;
  font-weight: 500;
  border-radius: $b-radius-3;
  color: $dark;
  background: $light;
  border: 1.5px solid $border-color;
  @include respond-below(hl) {
    height: 46px;
  }
  @include respond-below(xxl) {
    height: 40px;
  }
  &:focus {
    box-shadow: 0 0 5px rgba($dark, $shadow-opacity);
    border: 1.5px solid $border-color;
  }
}

// form control
textarea.form-control {
  padding: 15px !important;
  font-size: 1rem;
  font-weight: 500;
  border-radius: $b-radius-3;
  color: $dark;
  background: $light;
  border: 1.5px solid $border-color;
  resize: none;
  &:focus {
    box-shadow: 0 0 5px rgba($dark, $shadow-opacity);
    border: 1.5px solid $border-color;
  }
}
// for error message
.HasError {
  border: 1px solid $danger !important;
  &:focus {
    box-shadow: 0 0 5px rgba($danger, $shadow-opacity) !important;
  }
}

// select option
.SelectOption {
  position: relative;
  border-radius: $b-radius-3;
  border: 1.5px solid $border-color;
  background: $light;
  min-height: 50px;
  @include respond-below(hl) {
    min-height: 46px;
  }
  @include respond-below(xxl) {
    min-height: 40px;
  }
  .react-select__control {
    padding-left: 13px;
    min-height: 47px;
    width: 100%;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.4286;
    font-family: unset;
    border: 1.5px solid transparent;
    border-radius: $b-radius-3;
    background: transparent;
    color: $dark;
    appearance: none;
    @include respond-below(hl) {
      min-height: 43px !important;
    }
    @include respond-below(xxl) {
      min-height: 37px !important;
    }
    .react-select__value-container {
      padding: 0 !important;
    }
    &--is-focused {
      box-shadow: 0 0 5px rgba($dark, $shadow-opacity) !important;
      border: 1.5px solid $border-color !important;
    }
  }
}

// search form
.SearchForm {
  position: relative;
  height: 50px;
  min-width: 300px;
  background: $light !important;
  border-radius: $b-radius-3;
  border-radius: $b-radius-50 !important;
  @include respond-below(hl) {
    height: 46px;
  }
  @include respond-below(xxl) {
    height: 40px;
    min-width: 200px;
  }
  &::-ms-clear,
  &::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }
  /* clears the 'X' from Chrome */
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
  &:after {
    font-family: 'WP-Insight';
    content: '\e911';
    position: absolute;
    pointer-events: none;
    font-size: 1.125rem;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    color: $secondary;
    margin-left: 20px !important;
  }
}

// date picker
.DateRangePicker {
  width: 100% !important;
  .DateRangePickerInput {
    width: 100% !important;
    @extend input.form-control;
    font-family: unset !important;
    padding: 0 !important;
    background: #fff;
    .DataRangePickerInput__arrow {
      width: 30px !important;
    }
    .DateInput {
      background: unset !important;
      height: 100% !important;
      width: calc(50% - 30px) !important;
      .DateInput_input__focused,
      .DateInput_input {
        background: unset !important;
        font-size: unset !important;
        font-weight: unset !important;
        line-height: unset !important;
        height: 100% !important;
        padding: 0 15px !important;
      }
    }
  }
}

.DatePickerWrapper {
  position: relative;
  .CloseButton {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
  }
}
.Checkbox {
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 500;
  @include transition;
  input[type='checkbox'] {
    display: none;
    + span:before {
      font-family: 'WP-Insight';
      content: '\e907';
      color: $secondary;
      font-size: 0.875rem;
      margin-right: 10px;
    }
    &:checked + span:before {
      content: '\e909';
      color: $primary;
    }
  }
}

.Radio {
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 500;
  @include transition;
  input[type='radio'] {
    display: none;
    + span:before {
      font-family: 'WP-Insight';
      content: '\e90a';
      font-size: 0.75rem;
      margin-right: 10px;
    }
    &:checked + span:before {
      content: '\e90b';
    }
  }
}

// for disable
input:disabled {
  cursor: no-drop;
  background-color: darken($light, 2%) !important;
  color: lighten($secondary, 2%) !important;
}

.CustomSelectNumber {
  margin: 0 7px;
  .custom-select {
    padding: 2px 25px 2px 14px !important;
    height: 30px !important;
    border-radius: $b-radius-2;
    // background: transparent;
    border-color: $border-color;
    color: $primary;
    font-weight: 500;
    @include respond-below(sm) {
      height: 20px !important;
      padding: 0 20px 0 10px !important;
    }
  }
}

.ChangePlanBox,
.ModalTabContent {
  .form-control {
    background: $white !important;
  }
  .DatePicker,
  .SelectOption {
    background: $white !important;
    width: 100%;
  }
}

.ImageSelector {
  display: flex;
  flex-wrap: wrap;
  .ImgBox {
    border-radius: $b-radius-2;
    background: $light;
    height: 120px;
    width: 120px;
    min-width: 120px;
    border: 1px solid $border-color;
    overflow: hidden;
    position: relative;
    img {
      max-width: 100%;
      max-height: 100%;
    }
    .RemoveButton {
      position: absolute;
      top: 5px;
      right: 5px;
      background: $danger;
      color: $white;
      height: 16px;
      width: 16px;
      font-size: 8px;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      &.Hide {
        display: none;
      }
    }
  }
  .FileBrowseButton {
    color: $primary;
    font-style: italic;
    text-decoration: underline;
    font-weight: 500;
    display: inline-block;
    :hover {
      color: darken($primary, 20%);
    }
  }
}

.FileBrowseButton {
  cursor: pointer;
  input {
    display: none;
  }
}

.OrderableFormData {
  min-height: 40px;
  border: 1px solid $border-color;
  border-radius: $b-radius-2;
  padding: 3px 15px;
  display: flex;
  align-items: center;
  color: darken($secondary, 10%);
  width: 100%;
  @include respond-between(xl, xxl) {
    min-height: 36px;
  }
  @include respond-below(xl) {
    min-height: 36px;
  }
  @include respond-below(md) {
    min-height: 32px;
  }
  .OrderType {
    display: flex;
    align-items: center;
    margin: 5px 0;
    .OrderActive {
      margin-right: 10px;
      display: flex;
      align-items: center;
      input[type='radio'] {
        + span {
          color: $secondary;
          &:before {
            color: $secondary;
          }
        }
        &:checked + span {
          color: $primary;
          &:before {
            color: $primary;
          }
        }
      }
    }
    .dropdown {
      min-width: 125px;
      @include respond-below(xs) {
        min-width: 70px;
        .react-select__indicators {
          display: none;
        }
      }
      .SelectOption {
        min-height: 35px;
        .react-select__control {
          min-height: 32px !important;
          .react-select__indicator.react-select__dropdown-indicator {
            padding: 0 8px;
          }
        }
      }
    }
  }
}

.FormGroupWrapper {
  max-width: 600px;
  margin-bottom: 30px;
  .FormGroup {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}

.order-data {
  min-height: 610px;
  @include respond-below(xxl) {
    min-height: 560px;
  }
  @include respond-below(md) {
    min-height: 530px;
  }
}

.RemovablePermissionGroup {
  // background: lighten($light, 1%);
  // padding: 20px;
  // padding-right: 0;
  // border: 1.5px solid $border-color;
  // border-radius: 3px;
  // max-height: 370px;
  // overflow-y: auto;
  // &::-webkit-scrollbar {
  //   width: 5px;
  // }
  // &::-webkit-scrollbar-track {
  //   -webkit-box-shadow: inset 0 0 6px rgba($secondary, 0.1);
  //   background-image: linear-gradient(
  //     to right bottom,
  //     rgba($primary, 0.05),
  //     rgba($info, 0.05)
  //   );
  // }
  // &::-webkit-scrollbar-thumb {
  //   border-radius: 3px;
  //   background: darken($primary, 10%);
  // }
  .RemovablePermission {
    // margin-right: 20px;
    display: flex;
    align-items: center;
    background: $white;
    padding: 10px;
    border: 1.5px solid $border-color;
    border-radius: $b-radius-3;
    padding-right: 20px;
    .RemovablePermissionInner {
      flex-grow: 1;
      @include respond-below(lg) {
        .row {
          *[class*='col-']:not(:last-child) {
            margin-bottom: 5px;
          }
        }
      }
    }
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}
