// body header
.ContentAreaHeader {
  position: sticky;
  top: 0;
  z-index: 1005;
  background-color: $white;
  .HeaderWrapper {
    padding: 17px 50px;
    background-color: $white;
    border: 1.5px solid $border-color;
    border-right: none;
    border-top-left-radius: $b-radius-50;
    align-items: center;
    display: flex;
    align-items: center;
    @include respond-below(hl) {
      padding: 13px 50px;
    }
    @include respond-below(xxl) {
      padding: 10px 50px;
    }
    @include respond-below(xl) {
      padding: 10px 30px;
    }
    @include respond-below(xs) {
      padding: 5px 15px 5px 20px;
    }
    .ContentAreaHeaderRight {
      .ProfileControl {
        &Button {
          padding: 7px;
          border: 1.5px solid $border-color;
          border-radius: $b-radius-50;
          display: flex;
          align-items: center;
          position: relative;
          background: $white;
          padding-right: 55px !important;
          @include respond-below(sm) {
            padding-right: 35px !important;
          }
          @include respond-below(hl) {
            padding: 5px;
          }
          @include respond-below(xs) {
            padding: 2px;
          }
          &:hover {
            box-shadow: 0 0 10px 5px $border-color;
          }
          .ProfileImage {
            height: 50px;
            width: 50px;
            min-width: 50px;
            border-radius: $b-radius-100;
            display: flex;
            img {
              border-radius: $b-radius-100;
            }
            @include respond-below(hl) {
              width: 46px;
              height: 46px;
              min-width: 46px;
            }
            @include respond-below(xxl) {
              width: 40px;
              height: 40px;
              min-width: 40px;
            }
            @include respond-below(sm) {
              width: 30px;
              height: 30px;
              min-width: 30px;
            }
            @include respond-below(xs) {
              width: 25px;
              height: 25px;
              min-width: 25px;
            }
            img {
              width: 100%;
              height: 100%;
            }
          }
          .ProfileDetails {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-left: 15px;
            @include respond-below(sm) {
              display: none;
            }
            .UserName {
              font-size: 1.125rem;
              font-weight: 500;
              color: $dark;
            }
            .CompanyName {
              font-size: 0.8275rem;
              font-weight: 500;
              color: $secondary;
              margin-top: 4px;
            }
          }
          &:after {
            font-family: 'WP-Insight';
            content: '\e918';
            position: absolute;
            border: none !important;
            top: 50%;
            right: 25px;
            transform: translateY(-50%);
            font-size: 0.75rem;
            color: $secondary;
            @include respond-below(sm) {
              right: 15px;
            }
          }
        }
      }
    }
  }
}
