// google font
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
// selection
::selection {
	color: $light;
	background: rgba($primary, 0.8);
}

// basic
* {
	margin: 0;
	padding: 0;
	outline: none;
	border: none;
	box-sizing: border-box;
}
html {
	font-size: 16px;
	@include respond-below(hl){
		font-size: 14px;
	}
	@include respond-below(xxl){
		font-size: 13px;
	}
}
body {
	font-family: 'DM Sans', sans-serif;
	line-height: 1.3;
	font-weight: 500;
	letter-spacing: 0;
	min-height: 100vh;
	background: $white;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	&::-webkit-scrollbar {
		width: 8px;
	}
	&::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba($secondary, 0.1);
		background-image: linear-gradient(to right bottom, rgba($primary,0.05), rgba($info,0.05));
	}
	&::-webkit-scrollbar-thumb {
		border-radius: 3px;
		background: darken($primary, 10%);
	}
}

// component
ul,
li {
	list-style: none;
}
a {
	color: initial;
	transition: all 0.3s linear;
	&:hover,
	&:focus {
		text-decoration: none;
	}
}
label {
	margin-bottom: 0;
}
button {
	vertical-align: middle;
	background: unset;
	&:hover,
	&:focus {
		outline: none;
	}
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin-bottom: 0;
}
h1 {
	font-size: 2.25rem;
	font-weight: 600;
	line-height: 1.5;
	margin-bottom: 10px;
}
ul,
ol {
	margin-bottom: 0;
}
