.LoginBody{
    background: $primary;
    min-height: 100vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .LoginHeader{
        position: absolute;
        top: 40px;
        left: 35px;
        right: 35px;
        @include respond-below(xxl){
            top: 20px;
            left: 25px;
            right: 25px;
        }
        .SiteLogo{
            width: 170px;
            @include respond-below(xxl){
                svg{
                    height: 30px;
                }
            }
        }
    }
    .LoginWrapper{
        max-width: 1400px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .LoginContent,
        .LoginImage{
            flex: 0 0 50%;
            max-width: 50%;
            padding: 110px 35px 20px;
            @include respond-below(xxl){
                padding: 70px 30px 15px;
            }
        }
        @include respond-below(xl){
            .LoginContent{
                flex: 0 0 55%;
                max-width: 55%;
            }
            .LoginImage{
                flex: 0 0 45%;
                max-width: 45%;
                padding: 110px 35px 50px 0;
            }
        }
        @include respond-below(lg){
            .LoginContent{
                flex: 0 0 60%;
                max-width: 60%;
            }
            .LoginImage{
                flex: 0 0 40%;
                max-width: 40%;
            }
        }
        @include respond-below(md){
            .LoginContent{
                flex: 0 0 100%;
                max-width: 100%;
            }
            .LoginImage{
                display: none!important;
            }
        }
        .LoginContent{
            @include transition;
            display: flex;
            align-items: center;
            .LoginForm{
                background: $white;
                padding: 100px;
                border-radius: $b-radius-4;
                width: 100%;
                @include respond-below(hl){
                    padding: 70px;
                }
                @include respond-below(xxl){
                    padding: 40px;
                }
                @include respond-below(xs){
                    padding: 25px;
                }
                h2{
                    font-size: 2.25rem;
                    font-weight: 700;
                    margin-bottom: 30px;
                }
            }
        }
        .LoginImage{
            display: flex;
            align-items: center;
        }
    }
}