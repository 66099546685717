// opacity
$alert-opacity: 0.1;
$shadow-opacity: 0.3;

// border-radius
$b-radius-1: 3px;
$b-radius-2: 5px;
$b-radius-3: 10px;
$b-radius-4: 20px;
$b-radius-50: 50px;
$b-radius-100: 50%;

// color
$primary: #623CEA !default;
$secondary: #A9ADB9 !default;
$success: #43BC9C !default;
$danger: #E5142E !default;
$info: #00D0FF !default;
$warning: #FF7D00 !default;
$love: #D783EB !default;
$yellow: #FCC865 !default;
$dark: #1c2238 !default;
$light: #F9F9FB !default;
$white: #fff !default;
$black: #000 !default;
$border-color: #f1f1f1;
// theme-color
$theme-colors: () !default;
$theme-colors: map-merge(
	(
		'Primary': $primary,
		'Secondary': $secondary,
		'Success': $success,
		'Danger': $danger,
		'Info': $info,
		'Warning': $warning,
		'Love': $love,
		'Yellow': $yellow,
		'Dark': $dark,
		'Light': $light,
		'White': $white,
		'Black': $black,
		'BorderColor': $border-color,
	),
	$theme-colors
);

// A map of breakpoints.
$breakpoints: (
	xs: 500px,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1400px,
	hl: 1601px
);
