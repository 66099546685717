// import files
@import '../mixins/button';

.Button {
  padding: 5px 30px;
  font-size: 1.125rem;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $b-radius-50;
  min-height: 50px;
  min-width: 120px;
  border: 1.5px solid;
  @include transition;
  @include respond-below(hl) {
    min-height: 46px;
    padding: 5px 25px;
  }
  @include respond-below(xxl) {
    min-height: 40px;
    padding: 4px 20px;
  }
  @include respond-below(xs) {
    min-width: 80px;
    padding: 4px 15px;
  }
  .Icon {
    margin-right: 5px;
    transform: translateX(-5px);
  }
}

// small button
.LabelButton {
  padding: 3px 15px;
  font-size: 0.75rem;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $b-radius-50;
  min-height: 24px;
  min-width: 80px;
  border: 1.5px solid;
  @include transition;
  @include respond-below(hl) {
    min-height: 22px;
  }
  @include respond-below(xxl) {
    min-height: 20px;
  }
}

// small button
.SmallButton {
  padding: 2px 15px;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  line-height: 1.2;
  justify-content: center;
  border-radius: $b-radius-50;
  min-width: 80px;
  min-height: 30px;
  border: 1px solid;
  @include transition;
  @include respond-below(hl) {
    min-height: 26px;
  }
  @include respond-below(xxl) {
    min-height: 22px;
  }
}

.ActionButton {
  border-radius: $b-radius-50;
  border: 1px solid;
  display: flex;
  align-items: center;
  @include transition;
  .Icon {
    height: 28px;
    font-size: 0.875rem;
    width: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $b-radius-50;
    font-size: 0.875rem;
    @include respond-below(hl) {
      height: 26px;
      width: 26px;
    }
  }
}

.Alert {
  padding: 5px 20px;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1;
  display: flex;
  align-items: center;
  border-radius: $b-radius-2;
  min-height: 40px;
  border: 1.5px solid;
  @include transition;
  word-break: break-word;
  @include respond-below(hl) {
    min-height: 36px;
  }
  small {
    margin-left: auto;
    font-weight: 500;
    display: flex;
    align-items: center;
    &:before {
      content: '';
      height: 5px;
      width: 5px;
      border-radius: $b-radius-100;
      margin-right: 5px;
    }
  }
}

.CloseButton {
  height: 20px;
  width: 20px;
  min-width: 20px;
  font-size: 8px;
  border-radius: 50px;
  cursor: pointer;
}

// button color control
@each $parent, $color in $theme-colors {
  @include button-emphasis-variant('.#{$parent}Button', $color);
}

// semi button color control
@each $parent, $color in $theme-colors {
  @include semi-button-emphasis-variant('.SemiButton#{$parent}', $color);
}
// button color control
@each $parent, $color in $theme-colors {
  @include alert-emphasis-variant('.#{$parent}Alert', $color);
}
