// content color
@mixin color-emphasis-variant($parent, $color) {
	#{$parent} {
		color: $color !important;
	}
	a#{$parent} {
		@include hover-focus {
			@if $color != #000000 {
				color: darken($color, 10%) !important;
				box-shadow: none !important;
			}
		}
	}
}

// content background
@mixin background-emphasis-variant($parent, $color) {
	#{$parent} {
		background-color: $color !important;
	}
}

// gradient background
@mixin gradient-emphasis-variant($parent, $color) {
	#{$parent} {
		background-image: linear-gradient(to bottom right, $color, lighten($color, 15%));
		color: set-text-color($color);
		box-shadow: 0 10px 10px rgba($color, $shadow-opacity);
	}
}

// conversation modifier
@mixin counter-emphasis-variant($parent, $color) {
	#{$parent} {
		color: $color;
		background: $white;
		border: 2px solid $border-color;
	}
	.CounterCard{
		@include hover-focus{
			#{$parent} {
				background: $color;
				color: $white;
				border: 2px solid $color;
			}
		}
	}
}

// conversation modifier
@mixin list-view-content-title($parent, $color) {
	#{$parent} {
		&:before{
			background: $color;
		}
	}
}
