.custom-loader .modal-content {
  border: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.custom-loader .modal-body {
  align-self: center !important;
  z-index: 2000;
}
