// Button design
@mixin button-emphasis-variant($parent, $color) {
  #{$parent} {
    background: $color !important;
    color: set-button-color($color);
    border-color: $color;
    box-shadow: none !important;
    @include transition;
  }
  a#{$parent},
  button#{$parent} {
    &.Active,
    &:hover {
      @if $color != #000000 {
        background: darken($color, 5%) !important;
        color: set-text-color($color);
        border-color: darken($color, 5%) !important;
        box-shadow: 0 10px 10px rgba($color, $shadow-opacity) !important;
      }
    }
  }
  a#{$parent},
  button#{$parent} {
    &.Disabled {
      background: lighten($secondary, 20%) !important;
      color: darken($secondary, 25%);
      border: none !important;
      box-shadow: none !important;
      cursor: not-allowed;
      &:hover {
        box-shadow: none !important;
      }
    }
  }
}

// semi Button design
@mixin semi-button-emphasis-variant($parent, $color) {
  #{$parent} {
    background: transparent;
    color: set-semi-button-color($color);
    border-color: $color;
    box-shadow: none !important;
  }
  a#{$parent},
  button#{$parent} {
    color: set-semi-button-color($color);
    &.Active,
    &:hover {
      background: $color !important;
      color: set-text-color($color) !important;
      box-shadow: 0 10px 10px rgba($color, $shadow-opacity) !important;
    }
  }
}

// alert design
@mixin alert-emphasis-variant($parent, $color) {
  #{$parent} {
    background: rgba($color, $alert-opacity) !important;
    color: $color;
    border-color: transparent !important;
    box-shadow: none !important;
    @include transition;
    small {
      &:before {
        background: $color;
      }
    }
  }
  a#{$parent},
  button#{$parent} {
    color: $color;
    &.Active,
    &:hover {
      @if $color != #000000 {
        background: $color !important;
        color: set-text-color($color);
        border-color: transparent !important;
        box-shadow: 0 10px 10px rgba($color, $shadow-opacity) !important;
        small {
          &:before {
            background: set-text-color($color);
          }
        }
      }
    }
  }
}
