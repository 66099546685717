.DropdownToggler {
  padding: 2px 25px;
  min-height: 50px;
  min-width: 200px;
  line-height: 1.45;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: $b-radius-3;
  border: 1.5px solid $border-color;
  background: $white;
  color: set-text-color($white);
  @include respond-below(hl) {
    min-height: 46px;
    padding: 2px 20px;
  }
  @include respond-below(xxl) {
    min-height: 40px;
    padding: 2px 20px;
  }
  @include respond-below(md) {
    min-width: 150px;
  }
  &:after {
    font-family: 'WP-Insight' !important;
    content: '\e918';
    vertical-align: middle;
    border: 0;
    margin: unset;
    font-size: 0.6125rem;
    cursor: pointer;
    @include transition;
    margin-left: 20px;
    display: inline-block;
    color: $secondary;
  }
}
.wp-dropdown {
  background: $white !important;
}

.dropdown-menu {
  padding: 10px;
  border: none;
  min-width: 250px;
  border-radius: $b-radius-3;
  box-shadow: 0 5px 10px rgba($secondary, $shadow-opacity);
  border: 1.5px solid $border-color;
  top: 10px !important;
  max-height: 520px;
  overflow-y: auto;
  .DropdownHeading {
    padding: 5px 10px 10px;
    display: flex;
    flex-direction: column;
    border-bottom: 1.5px solid $border-color;
    small {
      font-size: 0.75rem;
      font-weight: 500;
      line-height: 1.25;
      color: $secondary;
      margin-bottom: 3px;
    }
    p {
      font-size: 0.9275rem;
      font-weight: 500;
      line-height: 1.25;
      color: $dark;
    }
  }
  .DropdownItem {
    padding: 0;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25;
    color: $dark;
    .Checkbox,
    a {
      padding: 10px;
      color: $dark;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .Checkbox {
      input[type='checkbox'] {
        + span:before {
          font-size: 0.75rem !important;
        }
        &:checked + span:before {
          content: '\e908' !important;
          color: $success !important;
        }
      }
    }
    &.CustomItem {
      border-top: 1.5px solid $border-color;
    }
    &:active,
    &.Active,
    &:hover {
      background: unset;
      .Checkbox,
      a {
        background: $light;
        color: $dark;
      }
    }
    &.Active {
      a {
        &:after {
          color: $success;
          font-family: 'WP-Insight' !important;
          content: '\e906';
        }
      }
    }
  }
}

// profile control dropdown
.ProfileControl .dropdown-menu {
  padding: 0;
  border: none;
  min-width: 220px !important;
  max-width: 250px !important;
  border-radius: $b-radius-2;
  box-shadow: 0 0 20px rgba($dark, $shadow-opacity);
  ul {
    padding: 7px;
    li {
      button,
      .DropdownItemLink {
        display: flex;
        line-height: 1;
        padding: 7px;
        align-items: center;
        cursor: pointer;
        .navigation-icon {
          width: 25px;
          min-width: 25px;
          display: flex;
          color: $primary;
          font-size: 0.75rem;
          border-radius: $b-radius-2;
          height: 25px;
          align-items: center;
          justify-content: center;
          background: rgba($primary, $shadow-opacity);
        }
        .navigation-image {
          min-width: 25px;
          width: 25px;
          display: inline-block;
          overflow: hidden;
          color: $primary;
          font-size: 0.75rem;
          border-radius: $b-radius-2;
          height: 25px;
          line-height: 25px;
          text-align: center;
          img {
            width: 100%;
            display: block;
            border-radius: $b-radius-2;
          }
        }
        .navigation-text {
          margin-left: 10px;
          color: $dark;
          font-size: 0.875rem;
          font-weight: 500;
          line-height: 1.15;
        }
      }
      &.active {
        button,
        .DropdownItemLink {
          .navigation-icon {
            color: $white;
            background: $primary;
          }
          .navigation-text {
            color: $dark;
          }
        }
      }
      &:hover {
        background: $light;
      }
    }
  }
  @include respond-below(sm) {
    min-width: 180px !important;
    max-width: 200px !important;
    top: 15px !important;
    ul {
      padding: 4px;
      li {
        button,
        .DropdownItemLink {
          padding: 4px;
        }
      }
    }
  }
}

// dropdown control dropdown
.DropdownControl {
  .Button.dropdown-toggle {
    &:after {
      margin-left: 5px;
      transform: translateX(5px);
      display: flex;
    }
  }
  .dropdown-menu {
    padding: 0;
    border: none;
    min-width: 220px !important;
    max-width: 250px !important;
    border-radius: $b-radius-2;
    box-shadow: 0 0 20px rgba($dark, $shadow-opacity);
    ul {
      padding: 7px;
      li {
        button,
        .DropdownItemLink {
          display: flex;
          line-height: 1;
          padding: 7px;
          align-items: center;
          cursor: pointer;
          .navigation-icon {
            width: 25px;
            min-width: 25px;
            display: flex;
            color: $primary;
            font-size: 0.75rem;
            border-radius: $b-radius-2;
            height: 25px;
            align-items: center;
            justify-content: center;
            background: rgba($primary, $shadow-opacity);
          }
          .navigation-image {
            min-width: 25px;
            width: 25px;
            display: inline-block;
            overflow: hidden;
            color: $primary;
            font-size: 0.75rem;
            border-radius: $b-radius-2;
            height: 25px;
            line-height: 25px;
            text-align: center;
            img {
              width: 100%;
              display: block;
              border-radius: $b-radius-2;
            }
          }
          .navigation-text {
            margin-left: 10px;
            color: $dark;
            font-size: 0.875rem;
            font-weight: 500;
            line-height: 1.15;
          }
        }
        &.active {
          button,
          .DropdownItemLink {
            .navigation-icon {
              color: $white;
              background: $primary;
            }
            .navigation-text {
              color: $dark;
            }
          }
        }
        &:hover {
          background: $light;
        }
      }
    }
    @include respond-below(sm) {
      min-width: 180px !important;
      max-width: 200px !important;
      top: 15px !important;
      ul {
        padding: 4px;
        li {
          button,
          .DropdownItemLink {
            padding: 4px;
          }
        }
      }
    }
  }
}
