.modal {
  &-diagram {
    border: none;
  }
  &-content {
    border: 1px solid $border-color !important;
    border-radius: $b-radius-4 !important;
  }
  &-backdrop {
    background-color: $dark !important;
    opacity: 0.7 !important;
  }
  .ModalHeader {
    padding: 30px;
    background: $white;
    border-radius: $b-radius-4 $b-radius-4 0 0 !important;
    border-bottom: 1px solid $border-color;
    display: flex;
    align-items: center;
    @include respond-below(lg) {
      padding: 15px;
    }
    .modal-title {
      @extend .SectionTitle;
    }
    .close {
      height: 30px;
      width: 30px;
      padding: 0;
      margin: 0;
      background: darken($light, 5%);
      border: 1px solid $border-color;
      border-radius: $b-radius-2;
      color: darken($secondary, 35%);
      text-shadow: none;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      font-size: 0.75rem;
      @include respond-below(hl) {
        height: 26px;
        width: 26px;
      }
      &:hover {
        background: lighten($secondary, 17%);
        color: $dark;
      }
    }
  }

  .ModalBody {
    padding: 30px;
    background: $white;
    @include respond-below(lg) {
      padding: 15px;
    }
    .ModalControlGroup {
      margin-bottom: 30px;
      @include respond-below(lg) {
        margin-bottom: 20px;
      }
      .ModalTabControllers {
        display: flex;
        background: $light;
        color: $secondary;
        border: 1px solid $border-color;
        border-radius: $b-radius-3;
        .ModalTabControl {
          flex: 1;
          font-size: 1rem;
          padding: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: $b-radius-3;
          text-transform: uppercase;
          font-weight: 500;
          color: $secondary;
          @include respond-below(xxl) {
            padding: 15px;
          }
          @include respond-below(lg) {
            padding: 10px;
          }
          &.Active {
            background: $primary;
            color: $white;
            &:hover {
              background: $primary;
              color: $white;
            }
          }
          &.Passed {
            color: $primary;
            &:hover {
              color: $primary;
            }
          }
          &:hover {
            background: darken($light, 2%);
            color: darken($secondary, 25%);
          }
        }
      }
      .progress {
        margin-top: 30px;
        border-radius: $b-radius-3 !important;
        height: 10px !important;
        @include respond-below(xxl) {
          margin-top: 15px;
        }
        @include respond-below(lg) {
          margin-top: 10px;
        }
        .progress-bar {
          background: $primary !important;
        }
      }
    }
    .ActiveSubscriptionPlan {
      background: $light;
      border-radius: $b-radius-3;
      border: 1.5px solid $border-color;
      padding: 30px;
      p.SmallButton {
        position: absolute;
        right: 30px;
        top: 30px;
      }
    }
    .ChangePlanBox {
      background: $light;
      border-radius: $b-radius-3;
      border: 1.5px solid $border-color;
      &Title {
        padding: 30px;
        border-bottom: 1.5px solid $border-color;
        border-radius: $b-radius-3 $b-radius-3 0 0;
      }
      &Body {
        padding: 30px;
        border-radius: 0 0 $b-radius-3 $b-radius-3;
        .TabContentRow {
          label {
            min-width: 20px;
            margin-bottom: 20px;
            @include respond-below(lg) {
              margin-bottom: 5px;
            }
          }
          &:not(:first-child) {
            margin-top: 20px;
            @include respond-below(lg) {
              margin-top: 10px !important;
            }
          }
        }
      }
    }
    .ModalTabContent {
      background: $light;
      border-radius: $b-radius-3;
      border: 1.5px solid $border-color;
      padding: 30px;
      @include respond-below(lg) {
        padding: 15px;
      }
      .TabContentRow {
        display: flex;
        align-items: center;
        @include respond-below(lg) {
          flex-direction: column;
          align-items: flex-start;
        }
        label {
          min-width: 220px;
          margin-right: 20px;
          @include respond-below(lg) {
            min-width: 100px;
            margin-bottom: 5px;
          }
        }
        &:not(:first-child) {
          margin-top: 20px;
          @include respond-below(lg) {
            margin-top: 10px !important;
          }
        }
      }
      &.PackageModalContent {
        .HasColGap {
          margin-bottom: -20px;
          > .col-md-1,
          > .col-md-2,
          > .col-md-3,
          > .col-md-4,
          > .col-md-5,
          > .col-md-6,
          > .col-md-7,
          > .col-md-8,
          > .col-md-9,
          > .col-md-10,
          > .col-md-11,
          > .col-md-12,
          > .col {
            margin-bottom: 20px !important;
          }
        }
        .SectionTitleWrap {
          margin-bottom: 20px;
        }
        .TabContentRow {
          label {
            min-width: 120px;
            margin-right: 20px;
            @include respond-below(lg) {
              min-width: 100px;
              margin-bottom: 3px;
            }
          }
          &:not(:first-child) {
            margin-top: 20px;
            @include respond-below(lg) {
              margin-top: 10px !important;
            }
          }
        }
        .RemovablePermissionGroup {
          background: $white;
          padding: 20px;
          padding-right: 0;
          border: 1.5px solid $border-color;
          border-radius: 3px;
          max-height: 285px;
          overflow-y: auto;
          &::-webkit-scrollbar {
            width: 5px;
          }
          &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba($secondary, 0.1);
            background-image: linear-gradient(
              to right bottom,
              rgba($primary, 0.05),
              rgba($info, 0.05)
            );
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 3px;
            background: darken($primary, 10%);
          }
          .RemovablePermission {
            margin-right: 20px;
            display: flex;
            align-items: center;
            background: $light;
            padding: 10px;
            border: 1.5px solid $border-color;
            border-radius: $b-radius-3;
            padding-right: 20px;
            .RemovablePermissionInner {
              flex-grow: 1;
              @include respond-below(lg) {
                .row {
                  *[class*='col-']:not(:last-child) {
                    margin-bottom: 5px;
                  }
                }
              }
            }
            &:not(:last-child) {
              margin-bottom: 10px;
            }
          }
        }
      }
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
    &:last-child {
      border-radius: 0 0 $b-radius-4 $b-radius-4 !important;
    }
  }
}
