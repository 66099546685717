.DateRangePicker
  .CalendarMonthGrid_month__horizontal:nth-child(2)
  .monthSelector {
  padding-left: 50px;
}
.DateRangePicker
  .CalendarMonthGrid_month__horizontal:nth-child(3)
  .monthSelector {
  padding-right: 50px;
}

.StressPanel .WpiTooltip {
  position: absolute;
  top: 25px;
  left: 135px;
}

.StressPanel .WpiTooltip .WpiTooltipButton {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #a9adb9;
  font-size: 16px;
}

.StressPanel .WpiTooltip .WpiTooltipText {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  background: #1c2238;
  min-width: 130px;
  text-align: center;
  bottom: calc(100% + 10px);
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  color: #fff;
  z-index: 1001;
  padding: 10px;
  border-radius: 5px;
  line-height: 1.1;
  font-weight: 400;
  font-size: 14px;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.StressPanel .WpiTooltip .WpiTooltipText:before {
  position: absolute;
  top: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  content: '';
  border-top: 5px solid #1c2238;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  padding-bottom: 5px;
}

.StressPanel .WpiTooltip:hover .WpiTooltipText {
  visibility: visible;
  opacity: 1;
}
