// sidebar design
.Sidebar {
  width: 300px;
  min-width: 300px;
  top: 0;
  left: 0;
  bottom: 0;
  transition: all 0.5s cubic-bezier(1, 0.2, 0.2, 1);
  @include respond-above(lg) {
    &:not(.Collapsed) {
      .SidebarContent {
        overflow-y: auto;
        // overflow-x: hidden;
        &::-webkit-scrollbar {
          width: 6px;
        }
        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba($secondary, 0.1);
          background-image: linear-gradient(
            to right bottom,
            rgba($primary, 0.05),
            rgba($info, 0.05)
          );
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 3px;
          background-image: linear-gradient(
            to left top,
            rgba($primary, 0.5),
            rgba($info, 0.5)
          );
        }
      }
    }
  }
  .SidebarContent {
    width: 300px;
    min-width: 300px;
    position: fixed;
    top: 0;
    background: $white;
    left: 0;
    bottom: 0;
    padding: 40px 0px 35px 0px;
    transition: all 0.5s cubic-bezier(1, 0.2, 0.2, 1);
    // displaying
    display: flex;
    flex-direction: column;
    -webkit-box-orient: vertical;
    z-index: 1010;
    @include respond-below(hl) {
      padding-top: 30px;
      padding-bottom: 20px;
    }
    @include respond-below(xxl) {
      padding-top: 24px;
      padding-bottom: 10px;
    }
    @include respond-below(xs) {
      padding-top: 15px;
    }

    &Top {
      margin-bottom: 100px;
      position: relative;
      @include respond-below(hl) {
        margin-bottom: 50px;
      }
      @include respond-below(sm) {
        margin-bottom: 30px;
      }
      .SiteLogo {
        max-width: 170px;
        display: block;
        visibility: visible;
        opacity: 1;
        margin-left: 30px;
        transition: all 0.7s cubic-bezier(1, 0.2, 0.2, 1) 0.1s;
        img,
        svg {
          display: block;
          height: 35px;
        }
        @include respond-below(xxl) {
          margin-left: 15px;
          img,
          svg {
            height: 30px;
          }
        }
      }
      .SiteCollapsedLogo {
        max-width: 45px;
        display: block;
        visibility: hidden;
        opacity: 0;
        margin-left: 15px;
        transition: all 0.7s cubic-bezier(1, 0.2, 0.2, 1);
        @include respond-below(md) {
          max-width: 30px;
        }
        img,
        svg {
          display: none;
          height: 35px;
          @include respond-below(xxl) {
            height: 30px;
          }
        }
      }
      .SidebarToggler {
        position: absolute;
        @include transition;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        color: $dark;
        padding: 2px;
        cursor: pointer;
        font-size: 1rem;
      }
    }
    &Middle {
      flex: 1;
    }
    .SidebarNav {
      display: flex;
      flex-direction: column;
      height: 100%;
      margin-left: 30px;
      @include respond-below(xxl) {
        margin-left: 15px;
      }
      &Item {
        &:last-child {
          margin-top: auto;
        }
        &Link {
          padding: 15px 0;
          text-decoration: none;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-size: 1rem;
          line-height: 1.875rem;
          color: $secondary;
          &Icon {
            width: 25px;
            min-width: 25px;
            height: 30px;
            margin: 0 10px;
            display: flex;
            font-size: 1.25rem;
            align-items: center;
            justify-content: center;
            text-align: center;
            border-radius: $b-radius-2;
          }
          &Text {
            margin-left: 15px;
            text-transform: uppercase;
            min-width: 190px;
            transition: all 0.7s ease-in-out;
            visibility: visible;
            @include respond-below(hl) {
              min-width: 180px;
            }
            @include respond-below(xxl) {
              min-width: 160px;
            }
          }
          &:hover,
          &.Active {
            .SidebarNavItemLinkIcon {
              color: $primary;
            }
            .SidebarNavItemLinkText {
              color: $dark;
            }
          }
          &.Active {
            position: relative;
            &:after {
              content: '';
              @include transition(all, 0.1s, ease-in-out);
              position: absolute;
              right: 0;
              top: 15px;
              bottom: 15px;
              width: 4px;
              background: $primary;
              border-radius: $b-radius-2;
            }
          }
        }
        &HasDropdown {
          .SidebarNavItemLink {
            &Text {
              position: relative;
              width: 100%;
              &:after {
                font-family: 'WP-Insight';
                content: '\e918';
                font-size: 0.6125rem;
                border-radius: 3px;
                cursor: pointer;
                position: absolute;
                right: 30px;
                top: 50%;
                transform: translateY(-50%) rotate(-90deg) !important;
                transition: all 0.1s ease-in-out !important;
              }
            }
            &:not(.collapsed) {
              .SidebarNavItemLinkText {
                &:after {
                  transform: translateY(-50%) rotate(0deg) !important;
                }
              }
            }
          }
        }
        .SidebarNavDropdownMenu {
          margin-left: 60px;
          min-width: 190px;
          transition: all 0.5s ease-in-out;
          visibility: visible;
          @include respond-below(hl) {
            min-width: 180px;
          }
          @include respond-below(xxl) {
            min-width: 160px;
          }
          &Item {
            &Link {
              padding: 10px 0;
              text-transform: uppercase;
              color: $secondary;
              display: block;
              line-height: 1.875rem;
              &:hover,
              &.Active {
                color: $dark;
              }
            }
          }
        }
      }
    }
  }
  &.Collapsed {
    @include respond-above(lg) {
      width: 95px;
      min-width: 95px;
      .SidebarContent {
        width: 95px;
        min-width: 95px;
        &Top {
          .SiteLogo {
            visibility: hidden;
            opacity: 0;

            img,
            svg {
              display: none;
            }
          }
          .SiteCollapsedLogo {
            visibility: visible;
            opacity: 1;

            img,
            svg {
              display: block;
            }
          }
          .SidebarToggler {
            right: 10px;
            color: darken($primary, 30%);
          }
        }
        .SidebarNav {
          margin-left: 25px;
          .SidebarNavItem {
            position: relative;
            .SidebarNavItemLink {
              .SidebarNavItemLinkText {
                padding-left: 5px;
                position: absolute;
                left: 100%;
                top: 0;
                bottom: 0;
                width: 200px;
                display: flex;
                align-items: center;
                background: $white;
                margin-left: 0;
                box-shadow: 5px 5px 10px darken($border-color, 5%);
                transform: rotateY(90deg);
                @include transition(all, 0.1s, ease-in-out);
                visibility: hidden;
                opacity: 0;
                transform-origin: left;
              }
            }
            &HasDropdown {
              .SidebarNavItemLink {
                &Text {
                  &:after {
                    right: 20px;
                  }
                }
                &:not(.collapsed) {
                  .SidebarNavItemLinkText {
                    &:after {
                      transform: translateY(-50%) rotate(0deg) !important;
                    }
                  }
                }
              }
            }
            .SidebarNavDropdownMenu {
              position: absolute;
              left: 100%;
              top: 100%;
              width: 200px;
              background: $white;
              box-shadow: 5px 5px 10px darken($border-color, 5%);
              margin-left: 0;
              transform: rotateY(90deg);
              @include transition(all, 0.1s, ease-in-out);
              transform-origin: left;
              visibility: hidden;
              opacity: 0;
              &Item {
                &Link {
                  padding-left: 10px;
                }
              }
            }
            &:hover {
              .SidebarNavItemLink {
                .SidebarNavItemLinkText {
                  transform: rotateY(0deg);
                  visibility: visible;
                  opacity: 1;
                }
                &.Active {
                  position: relative;
                  &:after {
                    right: -200px;
                  }
                }
              }
              .SidebarNavDropdownMenu {
                transform: rotateY(0deg);
                visibility: visible;
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}
@include respond-below(hl) {
  .Sidebar {
    width: 280px;
    min-width: 280px;
    .SidebarContent {
      width: 280px;
      min-width: 280px;
    }
  }
}

@include respond-below(xxl) {
  .Sidebar {
    width: 260px;
    min-width: 260px;
    .SidebarContent {
      width: 260px;
      min-width: 260px;
    }
  }
}
@include respond-below(lg) {
  .Sidebar {
    width: 95px;
    min-width: 95px;
    &:not(.Collapsed) {
      .SidebarContent {
        width: 95px;
        min-width: 95px;
        &Top {
          .SiteLogo {
            visibility: hidden;
            opacity: 0;

            img,
            svg {
              display: none;
            }
          }
          .SiteCollapsedLogo {
            visibility: visible;
            opacity: 1;

            img,
            svg {
              display: block;
            }
          }
          .SidebarToggler {
            right: 10px;
            color: darken($primary, 30%);
          }
        }
        .SidebarNav {
          margin-left: 25px;
          .SidebarNavItem {
            position: relative;
            .SidebarNavItemLink {
              .SidebarNavItemLinkText {
                padding-left: 5px;
                position: absolute;
                left: 100%;
                top: 0;
                bottom: 0;
                width: 200px;
                display: flex;
                align-items: center;
                background: $white;
                margin-left: 0;
                box-shadow: 5px 5px 10px darken($border-color, 5%);
                transform: rotateY(90deg);
                @include transition(all, 0.1s, ease-in-out);
                visibility: hidden;
                opacity: 0;
                transform-origin: left;
              }
            }
            &HasDropdown {
              .SidebarNavItemLink {
                &Text {
                  &:after {
                    right: 20px;
                  }
                }
                &:not(.collapsed) {
                  .SidebarNavItemLinkText {
                    &:after {
                      transform: translateY(-50%) rotate(0deg) !important;
                    }
                  }
                }
              }
            }
            .SidebarNavDropdownMenu {
              position: absolute;
              left: 100%;
              top: 100%;
              width: 200px;
              background: $white;
              box-shadow: 5px 5px 10px darken($border-color, 5%);
              margin-left: 0;
              transform: rotateY(90deg);
              @include transition(all, 0.1s, ease-in-out);
              transform-origin: left;
              visibility: hidden;
              opacity: 0;
              &Item {
                &Link {
                  padding-left: 10px;
                }
              }
            }
            &:hover {
              .SidebarNavItemLink {
                .SidebarNavItemLinkText {
                  transform: rotateY(0deg);
                  visibility: visible;
                  opacity: 1;
                }
                &.Active {
                  position: relative;
                  &:after {
                    right: -200px;
                  }
                }
              }
              .SidebarNavDropdownMenu {
                transform: rotateY(0deg);
                visibility: visible;
                opacity: 1;
              }
            }
          }
        }
      }
    }
    &.Collapsed {
      .SidebarContent {
        overflow-y: auto;
        // overflow-x: hidden;
        box-shadow: 0 0 50px $secondary;
        &::-webkit-scrollbar {
          width: 6px;
        }
        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba($secondary, 0.1);
          background-image: linear-gradient(
            to right bottom,
            rgba($primary, 0.05),
            rgba($info, 0.05)
          );
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 3px;
          background-image: linear-gradient(
            to left top,
            rgba($primary, 0.5),
            rgba($info, 0.5)
          );
        }
      }
    }
  }
}

@include respond-below(sm) {
  .Sidebar {
    width: 50px;
    min-width: 50px;
    &:not(.Collapsed) {
      .SidebarContent {
        width: 50px;
        min-width: 50px;
        &Top {
          .SiteCollapsedLogo {
            margin-left: 0px;
            img,
            svg {
              height: 18px;
              width: 35px;
            }
          }
          .SidebarToggler {
            right: 2px;
          }
        }
        .SidebarNav {
          margin-left: 3px;
          .SidebarNavItem {
            position: relative;
            .SidebarNavItemLink {
              .SidebarNavItemLinkText {
                padding-left: 5px;
                position: absolute;
                left: 100%;
                top: 0;
                bottom: 0;
                width: 200px;
                display: flex;
                align-items: center;
                background: $white;
                margin-left: 0;
                box-shadow: 5px 5px 10px darken($border-color, 5%);
                transform: rotateY(90deg);
                @include transition(all, 0.1s, ease-in-out);
                visibility: hidden;
                opacity: 0;
                transform-origin: left;
              }
            }
            &HasDropdown {
              .SidebarNavItemLink {
                &Text {
                  &:after {
                    right: 20px;
                  }
                }
                &:not(.collapsed) {
                  .SidebarNavItemLinkText {
                    &:after {
                      transform: translateY(-50%) rotate(0deg) !important;
                    }
                  }
                }
              }
            }
            .SidebarNavDropdownMenu {
              position: absolute;
              left: 100%;
              top: 100%;
              width: 200px;
              background: $white;
              box-shadow: 5px 5px 10px darken($border-color, 5%);
              margin-left: 0;
              transform: rotateY(90deg);
              @include transition(all, 0.1s, ease-in-out);
              transform-origin: left;
              visibility: hidden;
              opacity: 0;
              &Item {
                &Link {
                  padding-left: 10px;
                }
              }
            }
            &:hover {
              .SidebarNavItemLink {
                .SidebarNavItemLinkText {
                  transform: rotateY(0deg);
                  visibility: visible;
                  opacity: 1;
                }
                &.Active {
                  position: relative;
                  &:after {
                    right: -200px;
                  }
                }
              }
              .SidebarNavDropdownMenu {
                transform: rotateY(0deg);
                visibility: visible;
                opacity: 1;
              }
            }
          }
        }
      }
    }
    &.Collapsed {
      .SidebarContent {
        &Top {
          .SiteLogo {
            margin-left: 0px;
            img,
            svg {
              height: 18px;
              width: 145px;
            }
          }
          .SidebarToggler {
            right: 15px;
          }
        }
      }
    }
  }
}
// hamburger toggler
.HamburgerToggler {
  height: 30px;
  width: 36px;
  border-radius: $b-radius-2;
  background: $primary;
  margin-right: 20px;
  position: relative;
  display: none;
  @include respond-below(xl) {
    display: block;
  }
  span {
    height: 2px;
    width: 20px;
    background: $white;
    display: block;
    position: absolute;
    left: 8px;
    border-radius: 1px;
    @include transition;
    &:nth-child(1) {
      top: 8px;
    }
    &:nth-child(2) {
      top: 14px;
      opacity: 1;
      visibility: visible;
    }
    &:nth-child(3) {
      bottom: 8px;
    }
  }
  &.Active {
    box-shadow: 0 0 10px rgba($primary, $shadow-opacity + 0.5);
    span {
      &:nth-child(1) {
        transform: rotate(35deg) translate(3.25px, 4.95px);
      }
      &:nth-child(2) {
        // transform: translate(-40px);
        opacity: 0;
        visibility: hidden;
      }
      &:nth-child(3) {
        transform: rotate(-35deg) translate(3.25px, -4.95px);
      }
    }
  }
}

// secondary sidebar
.SecondarySidebar {
  width: 300px;
  min-width: 300px;
  height: 100%;
  background: $white;
  padding: 30px 0 30px 25px;
  z-index: 1000;
  border: 1.5px solid $border-color;
  border-radius: $b-radius-4;
  margin-right: 30px;
  @include respond-below(hl) {
    width: 240px;
    min-width: 240px;
  }
  @include respond-below(xxl) {
    width: 210px;
    min-width: 210px;
  }
  @include respond-below(xl) {
    padding: 15px 0 15px 25px;
  }
  .SidebarNav {
    display: flex;
    flex-direction: column;
    height: 100%;
    &Item {
      &Link {
        padding: 10px 0;
        text-decoration: none;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 1rem;
        line-height: 1.875rem;
        color: $secondary;
        width: 100% !important;
        &Text {
          text-transform: uppercase;
        }
        &:hover,
        &.Active {
          .SidebarNavItemLinkIcon {
            color: $primary;
          }
          .SidebarNavItemLinkText {
            color: $dark;
          }
        }
        &.Active {
          position: relative;
          &:after {
            content: '';
            position: absolute;
            right: 0;
            top: 10px;
            bottom: 10px;
            width: 4px;
            background: $primary;
            border-radius: $b-radius-2;
          }
        }
      }
    }
  }
  @include respond-below(xl) {
    position: absolute;
    top: -15px;
    left: 0;
    height: unset;
    visibility: hidden;
    opacity: 0;
    @include transition;
    box-shadow: 0 0 20px rgba($dark, $shadow-opacity);
    border-radius: $b-radius-3;
    &.Show {
      visibility: visible;
      opacity: 1;
    }
  }
  @include respond-below(lg) {
    top: -10px;
  }
  @include respond-below(md) {
    top: -10px;
  }
  @include respond-below(sm) {
    top: -10px;
  }
  @include respond-below(xs) {
    top: -5px;
  }
}
