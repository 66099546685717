

$pagination-prefix-cls: rc-pagination;
// $pagination-item-disabled-color-active: #fff;
// $pagination-item-input-bg: #fff;

.#{$pagination-prefix-cls} {
	display: flex;
	>*:not(:last-child){
		margin-right: 5px;
	}
	&:after {
		display: block;
		clear: both;
		height: 0;
		overflow: hidden;
		visibility: hidden;
		content: ' ';
	}
	&-total-text {
		display: flex;
		height: 40px;
		@include respond-below(hl){
			height: 36px;
		}
		@include respond-below(xxl){
			height: 30px;
		}
		align-items: center;
		justify-content: center;
	}
	&-item {
		display: flex;
		min-width: 40px;
		height: 40px;
		@include respond-below(hl){
			height: 36px;
			min-width: 36px;
		}
		@include respond-below(xxl){
			height: 30px;
			min-width: 30px;
		}
		background-color: rgba($primary, $alert-opacity);
		border-radius: $b-radius-3;
		cursor: pointer;
		user-select: none;
		a {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			color: $primary;
			transition: none;
		}
		&:focus,
		&:hover {
			background: $primary;
		a {
			color: $white;
		}
		}
		&-active {
			background: $primary;
			a {
				color: $white;
			}
			&:focus a,
			&:hover a {
				color: $white;
			}
		}
	}

	&-jump-prev,
	&-jump-next {
		background: rgba($primary, $alert-opacity);
		border-radius: $b-radius-3;
		cursor: pointer;
		user-select: none;
		.#{$pagination-prefix-cls}-item-link {
			&:after {
				display: block;
				content: '•••';
				color: $primary;
				font-size: 0.875rem;
			}
		}
		&:focus,
		&:hover {
			background: $primary;
			.#{$pagination-prefix-cls}-item-link {
				&:after {
					color: $white;
				}
			}
		}
	}
	&-prev,
	&-next,
	&-jump-prev,
	&-jump-next {
		display: flex;
		justify-content: center;
		align-items: center;
		min-width: 40px;
		height: 40px;
		@include respond-below(hl){
			height: 36px;
			min-width: 36px;
		}
		@include respond-below(xxl){
			height: 30px;
			min-width: 30px;
		}
		color: $primary;
		border-radius: $b-radius-3;
		font-size: 0.75rem;
		cursor: pointer;
	}

	&-prev,
	&-next {
		.#{$pagination-prefix-cls}-item-link {
			color: $primary;
			cursor: pointer;
			user-select: none;
			height: 100%;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: rgba($primary, $alert-opacity);
			border-radius: $b-radius-3;
			color: $primary;
		}
		&:focus .#{$pagination-prefix-cls}-item-link,
		&:hover .#{$pagination-prefix-cls}-item-link {
			background: $primary;
			color: $white;
		}
	}
	&-prev .#{$pagination-prefix-cls}-item-link:after {
		content: '\e919';
		font-family: 'WP-Insight' !important;
		display: block;
	}
	
	&-next .#{$pagination-prefix-cls}-item-link:after {
		content: '\e91a';
		font-family: 'WP-Insight' !important;
		display: block;
	}

	&-disabled {
		.#{$pagination-prefix-cls}-item-link {
			background: rgba($secondary, $alert-opacity);
		}
		&:hover,
		&:focus {
			cursor: not-allowed;
			.#{$pagination-prefix-cls}-item-link {
				cursor: not-allowed;
				color: $primary;
				background: rgba($secondary, $alert-opacity);
			}
		}
	}
	
	// &-slash {
	// 	margin: 0 10px 0 5px;
	// }

	// &-options {
	// 	display: inline-block;
	// 	margin-left: 16px;
	// 	vertical-align: middle;

	// 	// IE11 css hack. `*::-ms-backdrop,` is a must have
	// 	@media all and (-ms-high-contrast: none) {
	// 	*::-ms-backdrop,
	// 	& {
	// 		vertical-align: top;
	// 	}
	// 	}

	// 	&-size-changer.rc-select {
	// 	display: inline-block;
	// 	width: auto;
	// 	margin-right: 8px;
	// 	}

	// 	&-quick-jumper {
	// 	display: inline-block;
	// 	height: 28px;
	// 	line-height: 28px;
	// 	vertical-align: top;

	// 	input {
	// 		width: 50px;
	// 		margin: 0 8px;
	// 	}
	// 	}
	// }

	&-simple &-prev,
	&-simple &-next {
		height: 40px;
		@include respond-below(hl){
			height: 36px;
		}
		@include respond-below(xxl){
			height: 30px;
		}
		display: flex;
		align-items: center;
		justify-content: center;
		.#{$pagination-prefix-cls}-item-link {
			height: 40px;
			@include respond-below(hl){
				height: 36px;
			}
			@include respond-below(xxl){
				height: 30px;
			}
			&::after {
				height: 40px;
				@include respond-below(hl){
					height: 36px;
				}
				@include respond-below(xxl){
					height: 30px;
				}
			}
		}
	}

	// &-simple &-simple-pager {
	// 	display: inline-block;
	// 	height: 40px;
		@include respond-below(hl){
			height: 36px;
		}
		@include respond-below(xxl){
			height: 30px;
		}
	// 	margin-right: 8px;
	// 	input {
	// 		box-sizing: border-box;
	// 		height: 100%;
	// 		margin-right: 8px;
	// 		padding: 0 6px;
	// 		text-align: center;
	// 		background-color: $pagination-item-input-bg;
	// 		border: 1px solid #d9d9d9;
	// 		border-radius: 2px;
	// 		outline: none;
	// 		transition: border-color 0.3s;
	// 		&:hover {
	// 			border-color: $primary;
	// 		}
	// 	}
	// }

	// ============================ Disabled ============================
	// &-disabled {
	// 	cursor: not-allowed;
	// 	.#{$pagination-prefix-cls}-item {
	// 		background: hsv(0, 0, 96%);
	// 		border-color: #d9d9d9;
	// 		cursor: not-allowed;
	// 		a {
	// 			color: fade(#000, 25%);
	// 			background: transparent;
	// 			border: none;
	// 			cursor: not-allowed;
	// 		}
	// 		&-active {
	// 			background: $info;
	// 			border-color: transparent;
	// 			a {
	// 			color: $pagination-item-disabled-color-active;
	// 			}
	// 		}
	// 	}
	// 	.#{$pagination-prefix-cls}-item-link {
	// 		color: fade(#000, 25%);
	// 		background: hsv(0, 0, 96%);
	// 		border-color: #d9d9d9;
	// 		cursor: not-allowed;
	// 	}
	// 	.#{$pagination-prefix-cls}-item-link-icon {
	// 		opacity: 0;
	// 	}
	// 	.#{$pagination-prefix-cls}-item-ellipsis {
	// 		opacity: 1;
	// 	}
	// }
}

// @media only screen and (max-width: 992px) {
// 	.#{$pagination-prefix-cls}-item {
// 		&-after-jump-prev,
// 		&-before-jump-next {
// 		display: none;
// 		}
// 	}
// }

// @media only screen and (max-width: 576px) {
// 	.#{$pagination-prefix-cls}-options {
// 		display: none;
// 	}
// }
