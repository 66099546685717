@charset "UTF-8";
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
::-moz-selection {
  color: #f9f9fb;
  background: rgba(98, 60, 234, 0.8);
}
::selection {
  color: #f9f9fb;
  background: rgba(98, 60, 234, 0.8);
}

* {
  margin: 0;
  padding: 0;
  outline: none;
  border: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

@media (max-width: 1600.98px) {
  html {
    font-size: 14px;
  }
}

@media (max-width: 1399.98px) {
  html {
    font-size: 13px;
  }
}

body {
  font-family: 'DM Sans', sans-serif;
  line-height: 1.3;
  font-weight: 500;
  letter-spacing: 0;
  min-height: 100vh;
  background: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

body::-webkit-scrollbar {
  width: 8px;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(169, 173, 185, 0.1);
  background-image: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(rgba(98, 60, 234, 0.05)),
    to(rgba(0, 208, 255, 0.05))
  );
  background-image: linear-gradient(
    to right bottom,
    rgba(98, 60, 234, 0.05),
    rgba(0, 208, 255, 0.05)
  );
}

body::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: #4218db;
}

ul,
li {
  list-style: none;
}

a {
  color: initial;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

a:hover,
a:focus {
  text-decoration: none;
}

label {
  margin-bottom: 0;
}

button {
  vertical-align: middle;
  background: unset;
}

button:hover,
button:focus {
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-bottom: 0;
}

h1 {
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 10px;
}

ul,
ol {
  margin-bottom: 0;
}

.ColorPrimary {
  color: #623cea !important;
}

a.ColorPrimary:hover,
a.ColorPrimary:focus {
  color: #4218db !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.ColorSecondary {
  color: #a9adb9 !important;
}

a.ColorSecondary:hover,
a.ColorSecondary:focus {
  color: #8d92a2 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.ColorSuccess {
  color: #43bc9c !important;
}

a.ColorSuccess:hover,
a.ColorSuccess:focus {
  color: #36967d !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.ColorDanger {
  color: #e5142e !important;
}

a.ColorDanger:hover,
a.ColorDanger:focus {
  color: #b61025 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.ColorInfo {
  color: #00d0ff !important;
}

a.ColorInfo:hover,
a.ColorInfo:focus {
  color: #00a6cc !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.ColorWarning {
  color: #ff7d00 !important;
}

a.ColorWarning:hover,
a.ColorWarning:focus {
  color: #cc6400 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.ColorLove {
  color: #d783eb !important;
}

a.ColorLove:hover,
a.ColorLove:focus {
  color: #c957e4 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.ColorYellow {
  color: #fcc865 !important;
}

a.ColorYellow:hover,
a.ColorYellow:focus {
  color: #fbb633 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.ColorDark {
  color: #1c2238 !important;
}

a.ColorDark:hover,
a.ColorDark:focus {
  color: #0b0d16 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.ColorLight {
  color: #f9f9fb !important;
}

a.ColorLight:hover,
a.ColorLight:focus {
  color: #dadae7 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.ColorWhite {
  color: #fff !important;
}

a.ColorWhite:hover,
a.ColorWhite:focus {
  color: #e6e6e6 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.ColorBlack {
  color: #000 !important;
}

.ColorBorderColor {
  color: #f1f1f1 !important;
}

a.ColorBorderColor:hover,
a.ColorBorderColor:focus {
  color: #d8d8d8 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.BackgroundPrimary {
  background-color: #623cea !important;
}

.BackgroundSecondary {
  background-color: #a9adb9 !important;
}

.BackgroundSuccess {
  background-color: #43bc9c !important;
}

.BackgroundDanger {
  background-color: #e5142e !important;
}

.BackgroundInfo {
  background-color: #00d0ff !important;
}

.BackgroundWarning {
  background-color: #ff7d00 !important;
}

.BackgroundLove {
  background-color: #d783eb !important;
}

.BackgroundYellow {
  background-color: #fcc865 !important;
}

.BackgroundDark {
  background-color: #1c2238 !important;
}

.BackgroundLight {
  background-color: #f9f9fb !important;
}

.BackgroundWhite {
  background-color: #fff !important;
}

.BackgroundBlack {
  background-color: #000 !important;
}

.BackgroundBorderColor {
  background-color: #f1f1f1 !important;
}

.GradientPrimary {
  background-image: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(#623cea),
    to(#9a81f1)
  );
  background-image: linear-gradient(to bottom right, #623cea, #9a81f1);
  color: white;
  -webkit-box-shadow: 0 10px 10px rgba(98, 60, 234, 0.3);
  box-shadow: 0 10px 10px rgba(98, 60, 234, 0.3);
}

.GradientSecondary {
  background-image: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(#a9adb9),
    to(#d3d5db)
  );
  background-image: linear-gradient(to bottom right, #a9adb9, #d3d5db);
  color: white;
  -webkit-box-shadow: 0 10px 10px rgba(169, 173, 185, 0.3);
  box-shadow: 0 10px 10px rgba(169, 173, 185, 0.3);
}

.GradientSuccess {
  background-image: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(#43bc9c),
    to(#7bd0ba)
  );
  background-image: linear-gradient(to bottom right, #43bc9c, #7bd0ba);
  color: white;
  -webkit-box-shadow: 0 10px 10px rgba(67, 188, 156, 0.3);
  box-shadow: 0 10px 10px rgba(67, 188, 156, 0.3);
}

.GradientDanger {
  background-image: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(#e5142e),
    to(#f05569)
  );
  background-image: linear-gradient(to bottom right, #e5142e, #f05569);
  color: white;
  -webkit-box-shadow: 0 10px 10px rgba(229, 20, 46, 0.3);
  box-shadow: 0 10px 10px rgba(229, 20, 46, 0.3);
}

.GradientInfo {
  background-image: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(#00d0ff),
    to(#4ddeff)
  );
  background-image: linear-gradient(to bottom right, #00d0ff, #4ddeff);
  color: white;
  -webkit-box-shadow: 0 10px 10px rgba(0, 208, 255, 0.3);
  box-shadow: 0 10px 10px rgba(0, 208, 255, 0.3);
}

.GradientWarning {
  background-image: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(#ff7d00),
    to(#ffa44d)
  );
  background-image: linear-gradient(to bottom right, #ff7d00, #ffa44d);
  color: white;
  -webkit-box-shadow: 0 10px 10px rgba(255, 125, 0, 0.3);
  box-shadow: 0 10px 10px rgba(255, 125, 0, 0.3);
}

.GradientLove {
  background-image: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(#d783eb),
    to(#ecc5f6)
  );
  background-image: linear-gradient(to bottom right, #d783eb, #ecc5f6);
  color: #1c2238;
  -webkit-box-shadow: 0 10px 10px rgba(215, 131, 235, 0.3);
  box-shadow: 0 10px 10px rgba(215, 131, 235, 0.3);
}

.GradientYellow {
  background-image: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(#fcc865),
    to(#fde3b0)
  );
  background-image: linear-gradient(to bottom right, #fcc865, #fde3b0);
  color: white;
  -webkit-box-shadow: 0 10px 10px rgba(252, 200, 101, 0.3);
  box-shadow: 0 10px 10px rgba(252, 200, 101, 0.3);
}

.GradientDark {
  background-image: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(#1c2238),
    to(#36416b)
  );
  background-image: linear-gradient(to bottom right, #1c2238, #36416b);
  color: white;
  -webkit-box-shadow: 0 10px 10px rgba(28, 34, 56, 0.3);
  box-shadow: 0 10px 10px rgba(28, 34, 56, 0.3);
}

.GradientLight {
  background-image: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(#f9f9fb),
    to(white)
  );
  background-image: linear-gradient(to bottom right, #f9f9fb, white);
  color: #1c2238;
  -webkit-box-shadow: 0 10px 10px rgba(249, 249, 251, 0.3);
  box-shadow: 0 10px 10px rgba(249, 249, 251, 0.3);
}

.GradientWhite {
  background-image: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(#fff),
    to(white)
  );
  background-image: linear-gradient(to bottom right, #fff, white);
  color: #1c2238;
  -webkit-box-shadow: 0 10px 10px rgba(255, 255, 255, 0.3);
  box-shadow: 0 10px 10px rgba(255, 255, 255, 0.3);
}

.GradientBlack {
  background-image: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(#000),
    to(#262626)
  );
  background-image: linear-gradient(to bottom right, #000, #262626);
  color: white;
  -webkit-box-shadow: 0 10px 10px rgba(0, 0, 0, 0.3);
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.3);
}

.GradientBorderColor {
  background-image: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(#f1f1f1),
    to(white)
  );
  background-image: linear-gradient(to bottom right, #f1f1f1, white);
  color: #1c2238;
  -webkit-box-shadow: 0 10px 10px rgba(241, 241, 241, 0.3);
  box-shadow: 0 10px 10px rgba(241, 241, 241, 0.3);
}

.PrimaryCount {
  color: #623cea;
  background: #fff;
  border: 2px solid #f1f1f1;
}

.CounterCard:hover .PrimaryCount,
.CounterCard:focus .PrimaryCount {
  background: #623cea;
  color: #fff;
  border: 2px solid #623cea;
}

.SecondaryCount {
  color: #a9adb9;
  background: #fff;
  border: 2px solid #f1f1f1;
}

.CounterCard:hover .SecondaryCount,
.CounterCard:focus .SecondaryCount {
  background: #a9adb9;
  color: #fff;
  border: 2px solid #a9adb9;
}

.SuccessCount {
  color: #43bc9c;
  background: #fff;
  border: 2px solid #f1f1f1;
}

.CounterCard:hover .SuccessCount,
.CounterCard:focus .SuccessCount {
  background: #43bc9c;
  color: #fff;
  border: 2px solid #43bc9c;
}

.DangerCount {
  color: #e5142e;
  background: #fff;
  border: 2px solid #f1f1f1;
}

.CounterCard:hover .DangerCount,
.CounterCard:focus .DangerCount {
  background: #e5142e;
  color: #fff;
  border: 2px solid #e5142e;
}

.InfoCount {
  color: #00d0ff;
  background: #fff;
  border: 2px solid #f1f1f1;
}

.CounterCard:hover .InfoCount,
.CounterCard:focus .InfoCount {
  background: #00d0ff;
  color: #fff;
  border: 2px solid #00d0ff;
}

.WarningCount {
  color: #ff7d00;
  background: #fff;
  border: 2px solid #f1f1f1;
}

.CounterCard:hover .WarningCount,
.CounterCard:focus .WarningCount {
  background: #ff7d00;
  color: #fff;
  border: 2px solid #ff7d00;
}

.LoveCount {
  color: #d783eb;
  background: #fff;
  border: 2px solid #f1f1f1;
}

.CounterCard:hover .LoveCount,
.CounterCard:focus .LoveCount {
  background: #d783eb;
  color: #fff;
  border: 2px solid #d783eb;
}

.YellowCount {
  color: #fcc865;
  background: #fff;
  border: 2px solid #f1f1f1;
}

.CounterCard:hover .YellowCount,
.CounterCard:focus .YellowCount {
  background: #fcc865;
  color: #fff;
  border: 2px solid #fcc865;
}

.DarkCount {
  color: #1c2238;
  background: #fff;
  border: 2px solid #f1f1f1;
}

.CounterCard:hover .DarkCount,
.CounterCard:focus .DarkCount {
  background: #1c2238;
  color: #fff;
  border: 2px solid #1c2238;
}

.LightCount {
  color: #f9f9fb;
  background: #fff;
  border: 2px solid #f1f1f1;
}

.CounterCard:hover .LightCount,
.CounterCard:focus .LightCount {
  background: #f9f9fb;
  color: #fff;
  border: 2px solid #f9f9fb;
}

.WhiteCount {
  color: #fff;
  background: #fff;
  border: 2px solid #f1f1f1;
}

.CounterCard:hover .WhiteCount,
.CounterCard:focus .WhiteCount {
  background: #fff;
  color: #fff;
  border: 2px solid #fff;
}

.BlackCount {
  color: #000;
  background: #fff;
  border: 2px solid #f1f1f1;
}

.CounterCard:hover .BlackCount,
.CounterCard:focus .BlackCount {
  background: #000;
  color: #fff;
  border: 2px solid #000;
}

.BorderColorCount {
  color: #f1f1f1;
  background: #fff;
  border: 2px solid #f1f1f1;
}

.CounterCard:hover .BorderColorCount,
.CounterCard:focus .BorderColorCount {
  background: #f1f1f1;
  color: #fff;
  border: 2px solid #f1f1f1;
}

.ListViewContentTitlePrimary:before {
  background: #623cea;
}

.ListViewContentTitleSecondary:before {
  background: #a9adb9;
}

.ListViewContentTitleSuccess:before {
  background: #43bc9c;
}

.ListViewContentTitleDanger:before {
  background: #e5142e;
}

.ListViewContentTitleInfo:before {
  background: #00d0ff;
}

.ListViewContentTitleWarning:before {
  background: #ff7d00;
}

.ListViewContentTitleLove:before {
  background: #d783eb;
}

.ListViewContentTitleYellow:before {
  background: #fcc865;
}

.ListViewContentTitleDark:before {
  background: #1c2238;
}

.ListViewContentTitleLight:before {
  background: #f9f9fb;
}

.ListViewContentTitleWhite:before {
  background: #fff;
}

.ListViewContentTitleBlack:before {
  background: #000;
}

.ListViewContentTitleBorderColor:before {
  background: #f1f1f1;
}

.Button,
.TabControllerWrapper .nav-item .TabButton {
  padding: 5px 30px;
  font-size: 1.125rem;
  line-height: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50px;
  min-height: 50px;
  min-width: 120px;
  border: 1.5px solid;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

@media (max-width: 1600.98px) {
  .Button,
  .TabControllerWrapper .nav-item .TabButton {
    min-height: 46px;
    padding: 5px 25px;
  }
}

@media (max-width: 1399.98px) {
  .Button,
  .TabControllerWrapper .nav-item .TabButton {
    min-height: 40px;
    padding: 4px 20px;
  }
}

@media (max-width: 499.98px) {
  .Button,
  .TabControllerWrapper .nav-item .TabButton {
    min-width: 80px;
    padding: 4px 15px;
  }
}

.Button .Icon,
.TabControllerWrapper .nav-item .TabButton .Icon {
  margin-right: 5px;
  -webkit-transform: translateX(-5px);
  transform: translateX(-5px);
}

.LabelButton {
  padding: 3px 15px;
  font-size: 0.75rem;
  line-height: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50px;
  min-height: 24px;
  min-width: 80px;
  border: 1.5px solid;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

@media (max-width: 1600.98px) {
  .LabelButton {
    min-height: 22px;
  }
}

@media (max-width: 1399.98px) {
  .LabelButton {
    min-height: 20px;
  }
}

.SmallButton {
  padding: 2px 15px;
  font-size: 0.75rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 1.2;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50px;
  min-width: 80px;
  min-height: 30px;
  border: 1px solid;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

@media (max-width: 1600.98px) {
  .SmallButton {
    min-height: 26px;
  }
}

@media (max-width: 1399.98px) {
  .SmallButton {
    min-height: 22px;
  }
}

.ActionButton {
  border-radius: 50px;
  border: 1px solid;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.ActionButton .Icon {
  height: 28px;
  font-size: 0.875rem;
  width: 28px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50px;
  font-size: 0.875rem;
}

@media (max-width: 1600.98px) {
  .ActionButton .Icon {
    height: 26px;
    width: 26px;
  }
}

.Alert {
  padding: 5px 20px;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  min-height: 40px;
  border: 1.5px solid;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  word-break: break-word;
}

@media (max-width: 1600.98px) {
  .Alert {
    min-height: 36px;
  }
}

.Alert small {
  margin-left: auto;
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.Alert small:before {
  content: '';
  height: 5px;
  width: 5px;
  border-radius: 50%;
  margin-right: 5px;
}

.CloseButton {
  height: 20px;
  width: 20px;
  min-width: 20px;
  font-size: 8px;
  border-radius: 50px;
  cursor: pointer;
}

.PrimaryButton {
  background: #623cea !important;
  color: white;
  border-color: #623cea;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

a.PrimaryButton.Active,
a.PrimaryButton:hover,
button.PrimaryButton.Active,
button.PrimaryButton:hover {
  background: #4f25e8 !important;
  color: white;
  border-color: #4f25e8 !important;
  -webkit-box-shadow: 0 10px 10px rgba(98, 60, 234, 0.3) !important;
  box-shadow: 0 10px 10px rgba(98, 60, 234, 0.3) !important;
}

a.PrimaryButton.Disabled,
button.PrimaryButton.Disabled {
  background: #e1e3e7 !important;
  color: #666b7d;
  border: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  cursor: not-allowed;
}

a.PrimaryButton.Disabled:hover,
button.PrimaryButton.Disabled:hover {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.SecondaryButton {
  background: #a9adb9 !important;
  color: white;
  border-color: #a9adb9;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

a.SecondaryButton.Active,
a.SecondaryButton:hover,
button.SecondaryButton.Active,
button.SecondaryButton:hover {
  background: #9ba0ae !important;
  color: white;
  border-color: #9ba0ae !important;
  -webkit-box-shadow: 0 10px 10px rgba(169, 173, 185, 0.3) !important;
  box-shadow: 0 10px 10px rgba(169, 173, 185, 0.3) !important;
}

a.SecondaryButton.Disabled,
button.SecondaryButton.Disabled {
  background: #e1e3e7 !important;
  color: #666b7d;
  border: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  cursor: not-allowed;
}

a.SecondaryButton.Disabled:hover,
button.SecondaryButton.Disabled:hover {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.SuccessButton {
  background: #43bc9c !important;
  color: white;
  border-color: #43bc9c;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

a.SuccessButton.Active,
a.SuccessButton:hover,
button.SuccessButton.Active,
button.SuccessButton:hover {
  background: #3ca98c !important;
  color: white;
  border-color: #3ca98c !important;
  -webkit-box-shadow: 0 10px 10px rgba(67, 188, 156, 0.3) !important;
  box-shadow: 0 10px 10px rgba(67, 188, 156, 0.3) !important;
}

a.SuccessButton.Disabled,
button.SuccessButton.Disabled {
  background: #e1e3e7 !important;
  color: #666b7d;
  border: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  cursor: not-allowed;
}

a.SuccessButton.Disabled:hover,
button.SuccessButton.Disabled:hover {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.DangerButton {
  background: #e5142e !important;
  color: white;
  border-color: #e5142e;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

a.DangerButton.Active,
a.DangerButton:hover,
button.DangerButton.Active,
button.DangerButton:hover {
  background: #ce1229 !important;
  color: white;
  border-color: #ce1229 !important;
  -webkit-box-shadow: 0 10px 10px rgba(229, 20, 46, 0.3) !important;
  box-shadow: 0 10px 10px rgba(229, 20, 46, 0.3) !important;
}

a.DangerButton.Disabled,
button.DangerButton.Disabled {
  background: #e1e3e7 !important;
  color: #666b7d;
  border: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  cursor: not-allowed;
}

a.DangerButton.Disabled:hover,
button.DangerButton.Disabled:hover {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.InfoButton {
  background: #00d0ff !important;
  color: white;
  border-color: #00d0ff;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

a.InfoButton.Active,
a.InfoButton:hover,
button.InfoButton.Active,
button.InfoButton:hover {
  background: #00bbe6 !important;
  color: white;
  border-color: #00bbe6 !important;
  -webkit-box-shadow: 0 10px 10px rgba(0, 208, 255, 0.3) !important;
  box-shadow: 0 10px 10px rgba(0, 208, 255, 0.3) !important;
}

a.InfoButton.Disabled,
button.InfoButton.Disabled {
  background: #e1e3e7 !important;
  color: #666b7d;
  border: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  cursor: not-allowed;
}

a.InfoButton.Disabled:hover,
button.InfoButton.Disabled:hover {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.WarningButton {
  background: #ff7d00 !important;
  color: white;
  border-color: #ff7d00;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

a.WarningButton.Active,
a.WarningButton:hover,
button.WarningButton.Active,
button.WarningButton:hover {
  background: #e67100 !important;
  color: white;
  border-color: #e67100 !important;
  -webkit-box-shadow: 0 10px 10px rgba(255, 125, 0, 0.3) !important;
  box-shadow: 0 10px 10px rgba(255, 125, 0, 0.3) !important;
}

a.WarningButton.Disabled,
button.WarningButton.Disabled {
  background: #e1e3e7 !important;
  color: #666b7d;
  border: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  cursor: not-allowed;
}

a.WarningButton.Disabled:hover,
button.WarningButton.Disabled:hover {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.LoveButton {
  background: #d783eb !important;
  color: #1c2238;
  border-color: #d783eb;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

a.LoveButton.Active,
a.LoveButton:hover,
button.LoveButton.Active,
button.LoveButton:hover {
  background: #d06de7 !important;
  color: #1c2238;
  border-color: #d06de7 !important;
  -webkit-box-shadow: 0 10px 10px rgba(215, 131, 235, 0.3) !important;
  box-shadow: 0 10px 10px rgba(215, 131, 235, 0.3) !important;
}

a.LoveButton.Disabled,
button.LoveButton.Disabled {
  background: #e1e3e7 !important;
  color: #666b7d;
  border: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  cursor: not-allowed;
}

a.LoveButton.Disabled:hover,
button.LoveButton.Disabled:hover {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.YellowButton {
  background: #fcc865 !important;
  color: white;
  border-color: #fcc865;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

a.YellowButton.Active,
a.YellowButton:hover,
button.YellowButton.Active,
button.YellowButton:hover {
  background: #fcbf4c !important;
  color: white;
  border-color: #fcbf4c !important;
  -webkit-box-shadow: 0 10px 10px rgba(252, 200, 101, 0.3) !important;
  box-shadow: 0 10px 10px rgba(252, 200, 101, 0.3) !important;
}

a.YellowButton.Disabled,
button.YellowButton.Disabled {
  background: #e1e3e7 !important;
  color: #666b7d;
  border: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  cursor: not-allowed;
}

a.YellowButton.Disabled:hover,
button.YellowButton.Disabled:hover {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.DarkButton {
  background: #1c2238 !important;
  color: white;
  border-color: #1c2238;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

a.DarkButton.Active,
a.DarkButton:hover,
button.DarkButton.Active,
button.DarkButton:hover {
  background: #141827 !important;
  color: white;
  border-color: #141827 !important;
  -webkit-box-shadow: 0 10px 10px rgba(28, 34, 56, 0.3) !important;
  box-shadow: 0 10px 10px rgba(28, 34, 56, 0.3) !important;
}

a.DarkButton.Disabled,
button.DarkButton.Disabled {
  background: #e1e3e7 !important;
  color: #666b7d;
  border: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  cursor: not-allowed;
}

a.DarkButton.Disabled:hover,
button.DarkButton.Disabled:hover {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.LightButton {
  background: #f9f9fb !important;
  color: #1c2238;
  border-color: #f9f9fb;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

a.LightButton.Active,
a.LightButton:hover,
button.LightButton.Active,
button.LightButton:hover {
  background: #eaeaf1 !important;
  color: #1c2238;
  border-color: #eaeaf1 !important;
  -webkit-box-shadow: 0 10px 10px rgba(249, 249, 251, 0.3) !important;
  box-shadow: 0 10px 10px rgba(249, 249, 251, 0.3) !important;
}

a.LightButton.Disabled,
button.LightButton.Disabled {
  background: #e1e3e7 !important;
  color: #666b7d;
  border: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  cursor: not-allowed;
}

a.LightButton.Disabled:hover,
button.LightButton.Disabled:hover {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.WhiteButton {
  background: #fff !important;
  color: #1c2238;
  border-color: #fff;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

a.WhiteButton.Active,
a.WhiteButton:hover,
button.WhiteButton.Active,
button.WhiteButton:hover {
  background: #f2f2f2 !important;
  color: #1c2238;
  border-color: #f2f2f2 !important;
  -webkit-box-shadow: 0 10px 10px rgba(255, 255, 255, 0.3) !important;
  box-shadow: 0 10px 10px rgba(255, 255, 255, 0.3) !important;
}

a.WhiteButton.Disabled,
button.WhiteButton.Disabled {
  background: #e1e3e7 !important;
  color: #666b7d;
  border: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  cursor: not-allowed;
}

a.WhiteButton.Disabled:hover,
button.WhiteButton.Disabled:hover {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.BlackButton {
  background: #000 !important;
  color: white;
  border-color: #000;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

a.BlackButton.Disabled,
button.BlackButton.Disabled {
  background: #e1e3e7 !important;
  color: #666b7d;
  border: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  cursor: not-allowed;
}

a.BlackButton.Disabled:hover,
button.BlackButton.Disabled:hover {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.BorderColorButton {
  background: #f1f1f1 !important;
  color: #1c2238;
  border-color: #f1f1f1;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

a.BorderColorButton.Active,
a.BorderColorButton:hover,
button.BorderColorButton.Active,
button.BorderColorButton:hover {
  background: #e4e4e4 !important;
  color: #1c2238;
  border-color: #e4e4e4 !important;
  -webkit-box-shadow: 0 10px 10px rgba(241, 241, 241, 0.3) !important;
  box-shadow: 0 10px 10px rgba(241, 241, 241, 0.3) !important;
}

a.BorderColorButton.Disabled,
button.BorderColorButton.Disabled {
  background: #e1e3e7 !important;
  color: #666b7d;
  border: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  cursor: not-allowed;
}

a.BorderColorButton.Disabled:hover,
button.BorderColorButton.Disabled:hover {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.SemiButtonPrimary {
  background: transparent;
  color: #623cea;
  border-color: #623cea;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

a.SemiButtonPrimary,
button.SemiButtonPrimary {
  color: #623cea;
}

a.SemiButtonPrimary.Active,
a.SemiButtonPrimary:hover,
button.SemiButtonPrimary.Active,
button.SemiButtonPrimary:hover {
  background: #623cea !important;
  color: white !important;
  -webkit-box-shadow: 0 10px 10px rgba(98, 60, 234, 0.3) !important;
  box-shadow: 0 10px 10px rgba(98, 60, 234, 0.3) !important;
}

.SemiButtonSecondary {
  background: transparent;
  color: #1c2238;
  border-color: #a9adb9;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

a.SemiButtonSecondary,
button.SemiButtonSecondary {
  color: #1c2238;
}

a.SemiButtonSecondary.Active,
a.SemiButtonSecondary:hover,
button.SemiButtonSecondary.Active,
button.SemiButtonSecondary:hover {
  background: #a9adb9 !important;
  color: white !important;
  -webkit-box-shadow: 0 10px 10px rgba(169, 173, 185, 0.3) !important;
  box-shadow: 0 10px 10px rgba(169, 173, 185, 0.3) !important;
}

.SemiButtonSuccess {
  background: transparent;
  color: #43bc9c;
  border-color: #43bc9c;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

a.SemiButtonSuccess,
button.SemiButtonSuccess {
  color: #43bc9c;
}

a.SemiButtonSuccess.Active,
a.SemiButtonSuccess:hover,
button.SemiButtonSuccess.Active,
button.SemiButtonSuccess:hover {
  background: #43bc9c !important;
  color: white !important;
  -webkit-box-shadow: 0 10px 10px rgba(67, 188, 156, 0.3) !important;
  box-shadow: 0 10px 10px rgba(67, 188, 156, 0.3) !important;
}

.SemiButtonDanger {
  background: transparent;
  color: #e5142e;
  border-color: #e5142e;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

a.SemiButtonDanger,
button.SemiButtonDanger {
  color: #e5142e;
}

a.SemiButtonDanger.Active,
a.SemiButtonDanger:hover,
button.SemiButtonDanger.Active,
button.SemiButtonDanger:hover {
  background: #e5142e !important;
  color: white !important;
  -webkit-box-shadow: 0 10px 10px rgba(229, 20, 46, 0.3) !important;
  box-shadow: 0 10px 10px rgba(229, 20, 46, 0.3) !important;
}

.SemiButtonInfo {
  background: transparent;
  color: #00d0ff;
  border-color: #00d0ff;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

a.SemiButtonInfo,
button.SemiButtonInfo {
  color: #00d0ff;
}

a.SemiButtonInfo.Active,
a.SemiButtonInfo:hover,
button.SemiButtonInfo.Active,
button.SemiButtonInfo:hover {
  background: #00d0ff !important;
  color: white !important;
  -webkit-box-shadow: 0 10px 10px rgba(0, 208, 255, 0.3) !important;
  box-shadow: 0 10px 10px rgba(0, 208, 255, 0.3) !important;
}

.SemiButtonWarning {
  background: transparent;
  color: #ff7d00;
  border-color: #ff7d00;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

a.SemiButtonWarning,
button.SemiButtonWarning {
  color: #ff7d00;
}

a.SemiButtonWarning.Active,
a.SemiButtonWarning:hover,
button.SemiButtonWarning.Active,
button.SemiButtonWarning:hover {
  background: #ff7d00 !important;
  color: white !important;
  -webkit-box-shadow: 0 10px 10px rgba(255, 125, 0, 0.3) !important;
  box-shadow: 0 10px 10px rgba(255, 125, 0, 0.3) !important;
}

.SemiButtonLove {
  background: transparent;
  color: #1c2238;
  border-color: #d783eb;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

a.SemiButtonLove,
button.SemiButtonLove {
  color: #1c2238;
}

a.SemiButtonLove.Active,
a.SemiButtonLove:hover,
button.SemiButtonLove.Active,
button.SemiButtonLove:hover {
  background: #d783eb !important;
  color: #1c2238 !important;
  -webkit-box-shadow: 0 10px 10px rgba(215, 131, 235, 0.3) !important;
  box-shadow: 0 10px 10px rgba(215, 131, 235, 0.3) !important;
}

.SemiButtonYellow {
  background: transparent;
  color: #1c2238;
  border-color: #fcc865;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

a.SemiButtonYellow,
button.SemiButtonYellow {
  color: #1c2238;
}

a.SemiButtonYellow.Active,
a.SemiButtonYellow:hover,
button.SemiButtonYellow.Active,
button.SemiButtonYellow:hover {
  background: #fcc865 !important;
  color: white !important;
  -webkit-box-shadow: 0 10px 10px rgba(252, 200, 101, 0.3) !important;
  box-shadow: 0 10px 10px rgba(252, 200, 101, 0.3) !important;
}

.SemiButtonDark {
  background: transparent;
  color: #1c2238;
  border-color: #1c2238;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

a.SemiButtonDark,
button.SemiButtonDark {
  color: #1c2238;
}

a.SemiButtonDark.Active,
a.SemiButtonDark:hover,
button.SemiButtonDark.Active,
button.SemiButtonDark:hover {
  background: #1c2238 !important;
  color: white !important;
  -webkit-box-shadow: 0 10px 10px rgba(28, 34, 56, 0.3) !important;
  box-shadow: 0 10px 10px rgba(28, 34, 56, 0.3) !important;
}

.SemiButtonLight {
  background: transparent;
  color: #1c2238;
  border-color: #f9f9fb;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

a.SemiButtonLight,
button.SemiButtonLight {
  color: #1c2238;
}

a.SemiButtonLight.Active,
a.SemiButtonLight:hover,
button.SemiButtonLight.Active,
button.SemiButtonLight:hover {
  background: #f9f9fb !important;
  color: #1c2238 !important;
  -webkit-box-shadow: 0 10px 10px rgba(249, 249, 251, 0.3) !important;
  box-shadow: 0 10px 10px rgba(249, 249, 251, 0.3) !important;
}

.SemiButtonWhite {
  background: transparent;
  color: #1c2238;
  border-color: #fff;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

a.SemiButtonWhite,
button.SemiButtonWhite {
  color: #1c2238;
}

a.SemiButtonWhite.Active,
a.SemiButtonWhite:hover,
button.SemiButtonWhite.Active,
button.SemiButtonWhite:hover {
  background: #fff !important;
  color: #1c2238 !important;
  -webkit-box-shadow: 0 10px 10px rgba(255, 255, 255, 0.3) !important;
  box-shadow: 0 10px 10px rgba(255, 255, 255, 0.3) !important;
}

.SemiButtonBlack {
  background: transparent;
  color: black;
  border-color: #000;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

a.SemiButtonBlack,
button.SemiButtonBlack {
  color: black;
}

a.SemiButtonBlack.Active,
a.SemiButtonBlack:hover,
button.SemiButtonBlack.Active,
button.SemiButtonBlack:hover {
  background: #000 !important;
  color: white !important;
  -webkit-box-shadow: 0 10px 10px rgba(0, 0, 0, 0.3) !important;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.3) !important;
}

.SemiButtonBorderColor {
  background: transparent;
  color: #1c2238;
  border-color: #f1f1f1;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

a.SemiButtonBorderColor,
button.SemiButtonBorderColor {
  color: #1c2238;
}

a.SemiButtonBorderColor.Active,
a.SemiButtonBorderColor:hover,
button.SemiButtonBorderColor.Active,
button.SemiButtonBorderColor:hover {
  background: #f1f1f1 !important;
  color: #1c2238 !important;
  -webkit-box-shadow: 0 10px 10px rgba(241, 241, 241, 0.3) !important;
  box-shadow: 0 10px 10px rgba(241, 241, 241, 0.3) !important;
}

.PrimaryAlert {
  background: rgba(98, 60, 234, 0.1) !important;
  color: #623cea;
  border-color: transparent !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.PrimaryAlert small:before {
  background: #623cea;
}

a.PrimaryAlert,
button.PrimaryAlert {
  color: #623cea;
}

a.PrimaryAlert.Active,
a.PrimaryAlert:hover,
button.PrimaryAlert.Active,
button.PrimaryAlert:hover {
  background: #623cea !important;
  color: white;
  border-color: transparent !important;
  -webkit-box-shadow: 0 10px 10px rgba(98, 60, 234, 0.3) !important;
  box-shadow: 0 10px 10px rgba(98, 60, 234, 0.3) !important;
}

a.PrimaryAlert.Active small:before,
a.PrimaryAlert:hover small:before,
button.PrimaryAlert.Active small:before,
button.PrimaryAlert:hover small:before {
  background: white;
}

.SecondaryAlert {
  background: rgba(169, 173, 185, 0.1) !important;
  color: #a9adb9;
  border-color: transparent !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.SecondaryAlert small:before {
  background: #a9adb9;
}

a.SecondaryAlert,
button.SecondaryAlert {
  color: #a9adb9;
}

a.SecondaryAlert.Active,
a.SecondaryAlert:hover,
button.SecondaryAlert.Active,
button.SecondaryAlert:hover {
  background: #a9adb9 !important;
  color: white;
  border-color: transparent !important;
  -webkit-box-shadow: 0 10px 10px rgba(169, 173, 185, 0.3) !important;
  box-shadow: 0 10px 10px rgba(169, 173, 185, 0.3) !important;
}

a.SecondaryAlert.Active small:before,
a.SecondaryAlert:hover small:before,
button.SecondaryAlert.Active small:before,
button.SecondaryAlert:hover small:before {
  background: white;
}

.SuccessAlert {
  background: rgba(67, 188, 156, 0.1) !important;
  color: #43bc9c;
  border-color: transparent !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.SuccessAlert small:before {
  background: #43bc9c;
}

a.SuccessAlert,
button.SuccessAlert {
  color: #43bc9c;
}

a.SuccessAlert.Active,
a.SuccessAlert:hover,
button.SuccessAlert.Active,
button.SuccessAlert:hover {
  background: #43bc9c !important;
  color: white;
  border-color: transparent !important;
  -webkit-box-shadow: 0 10px 10px rgba(67, 188, 156, 0.3) !important;
  box-shadow: 0 10px 10px rgba(67, 188, 156, 0.3) !important;
}

a.SuccessAlert.Active small:before,
a.SuccessAlert:hover small:before,
button.SuccessAlert.Active small:before,
button.SuccessAlert:hover small:before {
  background: white;
}

.DangerAlert {
  background: rgba(229, 20, 46, 0.1) !important;
  color: #e5142e;
  border-color: transparent !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.DangerAlert small:before {
  background: #e5142e;
}

a.DangerAlert,
button.DangerAlert {
  color: #e5142e;
}

a.DangerAlert.Active,
a.DangerAlert:hover,
button.DangerAlert.Active,
button.DangerAlert:hover {
  background: #e5142e !important;
  color: white;
  border-color: transparent !important;
  -webkit-box-shadow: 0 10px 10px rgba(229, 20, 46, 0.3) !important;
  box-shadow: 0 10px 10px rgba(229, 20, 46, 0.3) !important;
}

a.DangerAlert.Active small:before,
a.DangerAlert:hover small:before,
button.DangerAlert.Active small:before,
button.DangerAlert:hover small:before {
  background: white;
}

.InfoAlert {
  background: rgba(0, 208, 255, 0.1) !important;
  color: #00d0ff;
  border-color: transparent !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.InfoAlert small:before {
  background: #00d0ff;
}

a.InfoAlert,
button.InfoAlert {
  color: #00d0ff;
}

a.InfoAlert.Active,
a.InfoAlert:hover,
button.InfoAlert.Active,
button.InfoAlert:hover {
  background: #00d0ff !important;
  color: white;
  border-color: transparent !important;
  -webkit-box-shadow: 0 10px 10px rgba(0, 208, 255, 0.3) !important;
  box-shadow: 0 10px 10px rgba(0, 208, 255, 0.3) !important;
}

a.InfoAlert.Active small:before,
a.InfoAlert:hover small:before,
button.InfoAlert.Active small:before,
button.InfoAlert:hover small:before {
  background: white;
}

.WarningAlert {
  background: rgba(255, 125, 0, 0.1) !important;
  color: #ff7d00;
  border-color: transparent !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.WarningAlert small:before {
  background: #ff7d00;
}

a.WarningAlert,
button.WarningAlert {
  color: #ff7d00;
}

a.WarningAlert.Active,
a.WarningAlert:hover,
button.WarningAlert.Active,
button.WarningAlert:hover {
  background: #ff7d00 !important;
  color: white;
  border-color: transparent !important;
  -webkit-box-shadow: 0 10px 10px rgba(255, 125, 0, 0.3) !important;
  box-shadow: 0 10px 10px rgba(255, 125, 0, 0.3) !important;
}

a.WarningAlert.Active small:before,
a.WarningAlert:hover small:before,
button.WarningAlert.Active small:before,
button.WarningAlert:hover small:before {
  background: white;
}

.LoveAlert {
  background: rgba(215, 131, 235, 0.1) !important;
  color: #d783eb;
  border-color: transparent !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.LoveAlert small:before {
  background: #d783eb;
}

a.LoveAlert,
button.LoveAlert {
  color: #d783eb;
}

a.LoveAlert.Active,
a.LoveAlert:hover,
button.LoveAlert.Active,
button.LoveAlert:hover {
  background: #d783eb !important;
  color: #1c2238;
  border-color: transparent !important;
  -webkit-box-shadow: 0 10px 10px rgba(215, 131, 235, 0.3) !important;
  box-shadow: 0 10px 10px rgba(215, 131, 235, 0.3) !important;
}

a.LoveAlert.Active small:before,
a.LoveAlert:hover small:before,
button.LoveAlert.Active small:before,
button.LoveAlert:hover small:before {
  background: #1c2238;
}

.YellowAlert {
  background: rgba(252, 200, 101, 0.1) !important;
  color: #fcc865;
  border-color: transparent !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.YellowAlert small:before {
  background: #fcc865;
}

a.YellowAlert,
button.YellowAlert {
  color: #fcc865;
}

a.YellowAlert.Active,
a.YellowAlert:hover,
button.YellowAlert.Active,
button.YellowAlert:hover {
  background: #fcc865 !important;
  color: white;
  border-color: transparent !important;
  -webkit-box-shadow: 0 10px 10px rgba(252, 200, 101, 0.3) !important;
  box-shadow: 0 10px 10px rgba(252, 200, 101, 0.3) !important;
}

a.YellowAlert.Active small:before,
a.YellowAlert:hover small:before,
button.YellowAlert.Active small:before,
button.YellowAlert:hover small:before {
  background: white;
}

.DarkAlert {
  background: rgba(28, 34, 56, 0.1) !important;
  color: #1c2238;
  border-color: transparent !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.DarkAlert small:before {
  background: #1c2238;
}

a.DarkAlert,
button.DarkAlert {
  color: #1c2238;
}

a.DarkAlert.Active,
a.DarkAlert:hover,
button.DarkAlert.Active,
button.DarkAlert:hover {
  background: #1c2238 !important;
  color: white;
  border-color: transparent !important;
  -webkit-box-shadow: 0 10px 10px rgba(28, 34, 56, 0.3) !important;
  box-shadow: 0 10px 10px rgba(28, 34, 56, 0.3) !important;
}

a.DarkAlert.Active small:before,
a.DarkAlert:hover small:before,
button.DarkAlert.Active small:before,
button.DarkAlert:hover small:before {
  background: white;
}

.LightAlert {
  background: rgba(249, 249, 251, 0.1) !important;
  color: #f9f9fb;
  border-color: transparent !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.LightAlert small:before {
  background: #f9f9fb;
}

a.LightAlert,
button.LightAlert {
  color: #f9f9fb;
}

a.LightAlert.Active,
a.LightAlert:hover,
button.LightAlert.Active,
button.LightAlert:hover {
  background: #f9f9fb !important;
  color: #1c2238;
  border-color: transparent !important;
  -webkit-box-shadow: 0 10px 10px rgba(249, 249, 251, 0.3) !important;
  box-shadow: 0 10px 10px rgba(249, 249, 251, 0.3) !important;
}

a.LightAlert.Active small:before,
a.LightAlert:hover small:before,
button.LightAlert.Active small:before,
button.LightAlert:hover small:before {
  background: #1c2238;
}

.WhiteAlert {
  background: rgba(255, 255, 255, 0.1) !important;
  color: #fff;
  border-color: transparent !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.WhiteAlert small:before {
  background: #fff;
}

a.WhiteAlert,
button.WhiteAlert {
  color: #fff;
}

a.WhiteAlert.Active,
a.WhiteAlert:hover,
button.WhiteAlert.Active,
button.WhiteAlert:hover {
  background: #fff !important;
  color: #1c2238;
  border-color: transparent !important;
  -webkit-box-shadow: 0 10px 10px rgba(255, 255, 255, 0.3) !important;
  box-shadow: 0 10px 10px rgba(255, 255, 255, 0.3) !important;
}

a.WhiteAlert.Active small:before,
a.WhiteAlert:hover small:before,
button.WhiteAlert.Active small:before,
button.WhiteAlert:hover small:before {
  background: #1c2238;
}

.BlackAlert {
  background: rgba(0, 0, 0, 0.1) !important;
  color: #000;
  border-color: transparent !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.BlackAlert small:before {
  background: #000;
}

a.BlackAlert,
button.BlackAlert {
  color: #000;
}

.BorderColorAlert {
  background: rgba(241, 241, 241, 0.1) !important;
  color: #f1f1f1;
  border-color: transparent !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.BorderColorAlert small:before {
  background: #f1f1f1;
}

a.BorderColorAlert,
button.BorderColorAlert {
  color: #f1f1f1;
}

a.BorderColorAlert.Active,
a.BorderColorAlert:hover,
button.BorderColorAlert.Active,
button.BorderColorAlert:hover {
  background: #f1f1f1 !important;
  color: #1c2238;
  border-color: transparent !important;
  -webkit-box-shadow: 0 10px 10px rgba(241, 241, 241, 0.3) !important;
  box-shadow: 0 10px 10px rgba(241, 241, 241, 0.3) !important;
}

a.BorderColorAlert.Active small:before,
a.BorderColorAlert:hover small:before,
button.BorderColorAlert.Active small:before,
button.BorderColorAlert:hover small:before {
  background: #1c2238;
}

.Sidebar {
  width: 300px;
  min-width: 300px;
  top: 0;
  left: 0;
  bottom: 0;
  -webkit-transition: all 0.5s cubic-bezier(1, 0.2, 0.2, 1);
  transition: all 0.5s cubic-bezier(1, 0.2, 0.2, 1);
}

@media (min-width: 992px) {
  .Sidebar:not(.Collapsed) .SidebarContent {
    overflow-y: auto;
  }
  .Sidebar:not(.Collapsed) .SidebarContent::-webkit-scrollbar {
    width: 6px;
  }
  .Sidebar:not(.Collapsed) .SidebarContent::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(169, 173, 185, 0.1);
    background-image: -webkit-gradient(
      linear,
      left top,
      right bottom,
      from(rgba(98, 60, 234, 0.05)),
      to(rgba(0, 208, 255, 0.05))
    );
    background-image: linear-gradient(
      to right bottom,
      rgba(98, 60, 234, 0.05),
      rgba(0, 208, 255, 0.05)
    );
  }
  .Sidebar:not(.Collapsed) .SidebarContent::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-image: -webkit-gradient(
      linear,
      right bottom,
      left top,
      from(rgba(98, 60, 234, 0.5)),
      to(rgba(0, 208, 255, 0.5))
    );
    background-image: linear-gradient(
      to left top,
      rgba(98, 60, 234, 0.5),
      rgba(0, 208, 255, 0.5)
    );
  }
}

.Sidebar .SidebarContent {
  width: 300px;
  min-width: 300px;
  position: fixed;
  top: 0;
  background: #fff;
  left: 0;
  bottom: 0;
  padding: 40px 0px 35px 0px;
  -webkit-transition: all 0.5s cubic-bezier(1, 0.2, 0.2, 1);
  transition: all 0.5s cubic-bezier(1, 0.2, 0.2, 1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-orient: vertical;
  z-index: 1010;
}

@media (max-width: 1600.98px) {
  .Sidebar .SidebarContent {
    padding-top: 30px;
    padding-bottom: 20px;
  }
}

@media (max-width: 1399.98px) {
  .Sidebar .SidebarContent {
    padding-top: 24px;
    padding-bottom: 10px;
  }
}

@media (max-width: 499.98px) {
  .Sidebar .SidebarContent {
    padding-top: 15px;
  }
}

.Sidebar .SidebarContentTop {
  margin-bottom: 100px;
  position: relative;
}

@media (max-width: 1600.98px) {
  .Sidebar .SidebarContentTop {
    margin-bottom: 50px;
  }
}

@media (max-width: 575.98px) {
  .Sidebar .SidebarContentTop {
    margin-bottom: 30px;
  }
}

.Sidebar .SidebarContentTop .SiteLogo {
  max-width: 170px;
  display: block;
  visibility: visible;
  opacity: 1;
  margin-left: 30px;
  -webkit-transition: all 0.7s cubic-bezier(1, 0.2, 0.2, 1) 0.1s;
  transition: all 0.7s cubic-bezier(1, 0.2, 0.2, 1) 0.1s;
}

.Sidebar .SidebarContentTop .SiteLogo img,
.Sidebar .SidebarContentTop .SiteLogo svg {
  display: block;
  height: 35px;
}

@media (max-width: 1399.98px) {
  .Sidebar .SidebarContentTop .SiteLogo {
    margin-left: 15px;
  }
  .Sidebar .SidebarContentTop .SiteLogo img,
  .Sidebar .SidebarContentTop .SiteLogo svg {
    height: 30px;
  }
}

.Sidebar .SidebarContentTop .SiteCollapsedLogo {
  max-width: 45px;
  display: block;
  visibility: hidden;
  opacity: 0;
  margin-left: 15px;
  -webkit-transition: all 0.7s cubic-bezier(1, 0.2, 0.2, 1);
  transition: all 0.7s cubic-bezier(1, 0.2, 0.2, 1);
}

@media (max-width: 767.98px) {
  .Sidebar .SidebarContentTop .SiteCollapsedLogo {
    max-width: 30px;
  }
}

.Sidebar .SidebarContentTop .SiteCollapsedLogo img,
.Sidebar .SidebarContentTop .SiteCollapsedLogo svg {
  display: none;
  height: 35px;
}

@media (max-width: 1399.98px) {
  .Sidebar .SidebarContentTop .SiteCollapsedLogo img,
  .Sidebar .SidebarContentTop .SiteCollapsedLogo svg {
    height: 30px;
  }
}

.Sidebar .SidebarContentTop .SidebarToggler {
  position: absolute;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  right: 30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #1c2238;
  padding: 2px;
  cursor: pointer;
  font-size: 1rem;
}

.Sidebar .SidebarContentMiddle {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.Sidebar .SidebarContent .SidebarNav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  margin-left: 30px;
}

@media (max-width: 1399.98px) {
  .Sidebar .SidebarContent .SidebarNav {
    margin-left: 15px;
  }
}

.Sidebar .SidebarContent .SidebarNavItem:last-child {
  margin-top: auto;
}

.Sidebar .SidebarContent .SidebarNavItemLink {
  padding: 15px 0;
  text-decoration: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1rem;
  line-height: 1.875rem;
  color: #a9adb9;
}

.Sidebar .SidebarContent .SidebarNavItemLinkIcon {
  width: 25px;
  min-width: 25px;
  height: 30px;
  margin: 0 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 1.25rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  border-radius: 5px;
}

.Sidebar .SidebarContent .SidebarNavItemLinkText {
  margin-left: 15px;
  text-transform: uppercase;
  min-width: 190px;
  -webkit-transition: all 0.7s ease-in-out;
  transition: all 0.7s ease-in-out;
  visibility: visible;
}

@media (max-width: 1600.98px) {
  .Sidebar .SidebarContent .SidebarNavItemLinkText {
    min-width: 180px;
  }
}

@media (max-width: 1399.98px) {
  .Sidebar .SidebarContent .SidebarNavItemLinkText {
    min-width: 160px;
  }
}

.Sidebar .SidebarContent .SidebarNavItemLink:hover .SidebarNavItemLinkIcon,
.Sidebar .SidebarContent .SidebarNavItemLink.Active .SidebarNavItemLinkIcon {
  color: #623cea;
}

.Sidebar .SidebarContent .SidebarNavItemLink:hover .SidebarNavItemLinkText,
.Sidebar .SidebarContent .SidebarNavItemLink.Active .SidebarNavItemLinkText {
  color: #1c2238;
}

.Sidebar .SidebarContent .SidebarNavItemLink.Active {
  position: relative;
}

.Sidebar .SidebarContent .SidebarNavItemLink.Active:after {
  content: '';
  -webkit-transition: all 0.1s ease-in-out 0s;
  transition: all 0.1s ease-in-out 0s;
  position: absolute;
  right: 0;
  top: 15px;
  bottom: 15px;
  width: 4px;
  background: #623cea;
  border-radius: 5px;
}

.Sidebar .SidebarContent .SidebarNavItemHasDropdown .SidebarNavItemLinkText {
  position: relative;
  width: 100%;
}

.Sidebar
  .SidebarContent
  .SidebarNavItemHasDropdown
  .SidebarNavItemLinkText:after {
  font-family: 'WP-Insight';
  content: '\e918';
  font-size: 0.6125rem;
  border-radius: 3px;
  cursor: pointer;
  position: absolute;
  right: 30px;
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(-90deg) !important;
  transform: translateY(-50%) rotate(-90deg) !important;
  -webkit-transition: all 0.1s ease-in-out !important;
  transition: all 0.1s ease-in-out !important;
}

.Sidebar
  .SidebarContent
  .SidebarNavItemHasDropdown
  .SidebarNavItemLink:not(.collapsed)
  .SidebarNavItemLinkText:after {
  -webkit-transform: translateY(-50%) rotate(0deg) !important;
  transform: translateY(-50%) rotate(0deg) !important;
}

.Sidebar .SidebarContent .SidebarNavItem .SidebarNavDropdownMenu {
  margin-left: 60px;
  min-width: 190px;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  visibility: visible;
}

@media (max-width: 1600.98px) {
  .Sidebar .SidebarContent .SidebarNavItem .SidebarNavDropdownMenu {
    min-width: 180px;
  }
}

@media (max-width: 1399.98px) {
  .Sidebar .SidebarContent .SidebarNavItem .SidebarNavDropdownMenu {
    min-width: 160px;
  }
}

.Sidebar .SidebarContent .SidebarNavItem .SidebarNavDropdownMenuItemLink {
  padding: 10px 0;
  text-transform: uppercase;
  color: #a9adb9;
  display: block;
  line-height: 1.875rem;
}

.Sidebar .SidebarContent .SidebarNavItem .SidebarNavDropdownMenuItemLink:hover,
.Sidebar
  .SidebarContent
  .SidebarNavItem
  .SidebarNavDropdownMenuItemLink.Active {
  color: #1c2238;
}

@media (min-width: 992px) {
  .Sidebar.Collapsed {
    width: 95px;
    min-width: 95px;
  }
  .Sidebar.Collapsed .SidebarContent {
    width: 95px;
    min-width: 95px;
  }
  .Sidebar.Collapsed .SidebarContentTop .SiteLogo {
    visibility: hidden;
    opacity: 0;
  }
  .Sidebar.Collapsed .SidebarContentTop .SiteLogo img,
  .Sidebar.Collapsed .SidebarContentTop .SiteLogo svg {
    display: none;
  }
  .Sidebar.Collapsed .SidebarContentTop .SiteCollapsedLogo {
    visibility: visible;
    opacity: 1;
  }
  .Sidebar.Collapsed .SidebarContentTop .SiteCollapsedLogo img,
  .Sidebar.Collapsed .SidebarContentTop .SiteCollapsedLogo svg {
    display: block;
  }
  .Sidebar.Collapsed .SidebarContentTop .SidebarToggler {
    right: 10px;
    color: #270e7f;
  }
  .Sidebar.Collapsed .SidebarContent .SidebarNav {
    margin-left: 25px;
  }
  .Sidebar.Collapsed .SidebarContent .SidebarNav .SidebarNavItem {
    position: relative;
  }
  .Sidebar.Collapsed
    .SidebarContent
    .SidebarNav
    .SidebarNavItem
    .SidebarNavItemLink
    .SidebarNavItemLinkText {
    padding-left: 5px;
    position: absolute;
    left: 100%;
    top: 0;
    bottom: 0;
    width: 200px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #fff;
    margin-left: 0;
    -webkit-box-shadow: 5px 5px 10px #e4e4e4;
    box-shadow: 5px 5px 10px #e4e4e4;
    -webkit-transform: rotateY(90deg);
    transform: rotateY(90deg);
    -webkit-transition: all 0.1s ease-in-out 0s;
    transition: all 0.1s ease-in-out 0s;
    visibility: hidden;
    opacity: 0;
    -webkit-transform-origin: left;
    transform-origin: left;
  }
  .Sidebar.Collapsed
    .SidebarContent
    .SidebarNav
    .SidebarNavItemHasDropdown
    .SidebarNavItemLinkText:after {
    right: 20px;
  }
  .Sidebar.Collapsed
    .SidebarContent
    .SidebarNav
    .SidebarNavItemHasDropdown
    .SidebarNavItemLink:not(.collapsed)
    .SidebarNavItemLinkText:after {
    -webkit-transform: translateY(-50%) rotate(0deg) !important;
    transform: translateY(-50%) rotate(0deg) !important;
  }
  .Sidebar.Collapsed
    .SidebarContent
    .SidebarNav
    .SidebarNavItem
    .SidebarNavDropdownMenu {
    position: absolute;
    left: 100%;
    top: 100%;
    width: 200px;
    background: #fff;
    -webkit-box-shadow: 5px 5px 10px #e4e4e4;
    box-shadow: 5px 5px 10px #e4e4e4;
    margin-left: 0;
    -webkit-transform: rotateY(90deg);
    transform: rotateY(90deg);
    -webkit-transition: all 0.1s ease-in-out 0s;
    transition: all 0.1s ease-in-out 0s;
    -webkit-transform-origin: left;
    transform-origin: left;
    visibility: hidden;
    opacity: 0;
  }
  .Sidebar.Collapsed
    .SidebarContent
    .SidebarNav
    .SidebarNavItem
    .SidebarNavDropdownMenuItemLink {
    padding-left: 10px;
  }
  .Sidebar.Collapsed
    .SidebarContent
    .SidebarNav
    .SidebarNavItem:hover
    .SidebarNavItemLink
    .SidebarNavItemLinkText {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
    visibility: visible;
    opacity: 1;
  }
  .Sidebar.Collapsed
    .SidebarContent
    .SidebarNav
    .SidebarNavItem:hover
    .SidebarNavItemLink.Active {
    position: relative;
  }
  .Sidebar.Collapsed
    .SidebarContent
    .SidebarNav
    .SidebarNavItem:hover
    .SidebarNavItemLink.Active:after {
    right: -200px;
  }
  .Sidebar.Collapsed
    .SidebarContent
    .SidebarNav
    .SidebarNavItem:hover
    .SidebarNavDropdownMenu {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
    visibility: visible;
    opacity: 1;
  }
}

@media (max-width: 1600.98px) {
  .Sidebar {
    width: 280px;
    min-width: 280px;
  }
  .Sidebar .SidebarContent {
    width: 280px;
    min-width: 280px;
  }
}

@media (max-width: 1399.98px) {
  .Sidebar {
    width: 260px;
    min-width: 260px;
  }
  .Sidebar .SidebarContent {
    width: 260px;
    min-width: 260px;
  }
}

@media (max-width: 991.98px) {
  .Sidebar {
    width: 95px;
    min-width: 95px;
  }
  .Sidebar:not(.Collapsed) .SidebarContent {
    width: 95px;
    min-width: 95px;
  }
  .Sidebar:not(.Collapsed) .SidebarContentTop .SiteLogo {
    visibility: hidden;
    opacity: 0;
  }
  .Sidebar:not(.Collapsed) .SidebarContentTop .SiteLogo img,
  .Sidebar:not(.Collapsed) .SidebarContentTop .SiteLogo svg {
    display: none;
  }
  .Sidebar:not(.Collapsed) .SidebarContentTop .SiteCollapsedLogo {
    visibility: visible;
    opacity: 1;
  }
  .Sidebar:not(.Collapsed) .SidebarContentTop .SiteCollapsedLogo img,
  .Sidebar:not(.Collapsed) .SidebarContentTop .SiteCollapsedLogo svg {
    display: block;
  }
  .Sidebar:not(.Collapsed) .SidebarContentTop .SidebarToggler {
    right: 10px;
    color: #270e7f;
  }
  .Sidebar:not(.Collapsed) .SidebarContent .SidebarNav {
    margin-left: 25px;
  }
  .Sidebar:not(.Collapsed) .SidebarContent .SidebarNav .SidebarNavItem {
    position: relative;
  }
  .Sidebar:not(.Collapsed)
    .SidebarContent
    .SidebarNav
    .SidebarNavItem
    .SidebarNavItemLink
    .SidebarNavItemLinkText {
    padding-left: 5px;
    position: absolute;
    left: 100%;
    top: 0;
    bottom: 0;
    width: 200px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #fff;
    margin-left: 0;
    -webkit-box-shadow: 5px 5px 10px #e4e4e4;
    box-shadow: 5px 5px 10px #e4e4e4;
    -webkit-transform: rotateY(90deg);
    transform: rotateY(90deg);
    -webkit-transition: all 0.1s ease-in-out 0s;
    transition: all 0.1s ease-in-out 0s;
    visibility: hidden;
    opacity: 0;
    -webkit-transform-origin: left;
    transform-origin: left;
  }
  .Sidebar:not(.Collapsed)
    .SidebarContent
    .SidebarNav
    .SidebarNavItemHasDropdown
    .SidebarNavItemLinkText:after {
    right: 20px;
  }
  .Sidebar:not(.Collapsed)
    .SidebarContent
    .SidebarNav
    .SidebarNavItemHasDropdown
    .SidebarNavItemLink:not(.collapsed)
    .SidebarNavItemLinkText:after {
    -webkit-transform: translateY(-50%) rotate(0deg) !important;
    transform: translateY(-50%) rotate(0deg) !important;
  }
  .Sidebar:not(.Collapsed)
    .SidebarContent
    .SidebarNav
    .SidebarNavItem
    .SidebarNavDropdownMenu {
    position: absolute;
    left: 100%;
    top: 100%;
    width: 200px;
    background: #fff;
    -webkit-box-shadow: 5px 5px 10px #e4e4e4;
    box-shadow: 5px 5px 10px #e4e4e4;
    margin-left: 0;
    -webkit-transform: rotateY(90deg);
    transform: rotateY(90deg);
    -webkit-transition: all 0.1s ease-in-out 0s;
    transition: all 0.1s ease-in-out 0s;
    -webkit-transform-origin: left;
    transform-origin: left;
    visibility: hidden;
    opacity: 0;
  }
  .Sidebar:not(.Collapsed)
    .SidebarContent
    .SidebarNav
    .SidebarNavItem
    .SidebarNavDropdownMenuItemLink {
    padding-left: 10px;
  }
  .Sidebar:not(.Collapsed)
    .SidebarContent
    .SidebarNav
    .SidebarNavItem:hover
    .SidebarNavItemLink
    .SidebarNavItemLinkText {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
    visibility: visible;
    opacity: 1;
  }
  .Sidebar:not(.Collapsed)
    .SidebarContent
    .SidebarNav
    .SidebarNavItem:hover
    .SidebarNavItemLink.Active {
    position: relative;
  }
  .Sidebar:not(.Collapsed)
    .SidebarContent
    .SidebarNav
    .SidebarNavItem:hover
    .SidebarNavItemLink.Active:after {
    right: -200px;
  }
  .Sidebar:not(.Collapsed)
    .SidebarContent
    .SidebarNav
    .SidebarNavItem:hover
    .SidebarNavDropdownMenu {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
    visibility: visible;
    opacity: 1;
  }
  .Sidebar.Collapsed .SidebarContent {
    overflow-y: auto;
    -webkit-box-shadow: 0 0 50px #a9adb9;
    box-shadow: 0 0 50px #a9adb9;
  }
  .Sidebar.Collapsed .SidebarContent::-webkit-scrollbar {
    width: 6px;
  }
  .Sidebar.Collapsed .SidebarContent::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(169, 173, 185, 0.1);
    background-image: -webkit-gradient(
      linear,
      left top,
      right bottom,
      from(rgba(98, 60, 234, 0.05)),
      to(rgba(0, 208, 255, 0.05))
    );
    background-image: linear-gradient(
      to right bottom,
      rgba(98, 60, 234, 0.05),
      rgba(0, 208, 255, 0.05)
    );
  }
  .Sidebar.Collapsed .SidebarContent::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-image: -webkit-gradient(
      linear,
      right bottom,
      left top,
      from(rgba(98, 60, 234, 0.5)),
      to(rgba(0, 208, 255, 0.5))
    );
    background-image: linear-gradient(
      to left top,
      rgba(98, 60, 234, 0.5),
      rgba(0, 208, 255, 0.5)
    );
  }
}

@media (max-width: 575.98px) {
  .Sidebar {
    width: 50px;
    min-width: 50px;
  }
  .Sidebar:not(.Collapsed) .SidebarContent {
    width: 50px;
    min-width: 50px;
  }
  .Sidebar:not(.Collapsed) .SidebarContentTop .SiteCollapsedLogo {
    margin-left: 0px;
  }
  .Sidebar:not(.Collapsed) .SidebarContentTop .SiteCollapsedLogo img,
  .Sidebar:not(.Collapsed) .SidebarContentTop .SiteCollapsedLogo svg {
    height: 18px;
    width: 35px;
  }
  .Sidebar:not(.Collapsed) .SidebarContentTop .SidebarToggler {
    right: 2px;
  }
  .Sidebar:not(.Collapsed) .SidebarContent .SidebarNav {
    margin-left: 3px;
  }
  .Sidebar:not(.Collapsed) .SidebarContent .SidebarNav .SidebarNavItem {
    position: relative;
  }
  .Sidebar:not(.Collapsed)
    .SidebarContent
    .SidebarNav
    .SidebarNavItem
    .SidebarNavItemLink
    .SidebarNavItemLinkText {
    padding-left: 5px;
    position: absolute;
    left: 100%;
    top: 0;
    bottom: 0;
    width: 200px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #fff;
    margin-left: 0;
    -webkit-box-shadow: 5px 5px 10px #e4e4e4;
    box-shadow: 5px 5px 10px #e4e4e4;
    -webkit-transform: rotateY(90deg);
    transform: rotateY(90deg);
    -webkit-transition: all 0.1s ease-in-out 0s;
    transition: all 0.1s ease-in-out 0s;
    visibility: hidden;
    opacity: 0;
    -webkit-transform-origin: left;
    transform-origin: left;
  }
  .Sidebar:not(.Collapsed)
    .SidebarContent
    .SidebarNav
    .SidebarNavItemHasDropdown
    .SidebarNavItemLinkText:after {
    right: 20px;
  }
  .Sidebar:not(.Collapsed)
    .SidebarContent
    .SidebarNav
    .SidebarNavItemHasDropdown
    .SidebarNavItemLink:not(.collapsed)
    .SidebarNavItemLinkText:after {
    -webkit-transform: translateY(-50%) rotate(0deg) !important;
    transform: translateY(-50%) rotate(0deg) !important;
  }
  .Sidebar:not(.Collapsed)
    .SidebarContent
    .SidebarNav
    .SidebarNavItem
    .SidebarNavDropdownMenu {
    position: absolute;
    left: 100%;
    top: 100%;
    width: 200px;
    background: #fff;
    -webkit-box-shadow: 5px 5px 10px #e4e4e4;
    box-shadow: 5px 5px 10px #e4e4e4;
    margin-left: 0;
    -webkit-transform: rotateY(90deg);
    transform: rotateY(90deg);
    -webkit-transition: all 0.1s ease-in-out 0s;
    transition: all 0.1s ease-in-out 0s;
    -webkit-transform-origin: left;
    transform-origin: left;
    visibility: hidden;
    opacity: 0;
  }
  .Sidebar:not(.Collapsed)
    .SidebarContent
    .SidebarNav
    .SidebarNavItem
    .SidebarNavDropdownMenuItemLink {
    padding-left: 10px;
  }
  .Sidebar:not(.Collapsed)
    .SidebarContent
    .SidebarNav
    .SidebarNavItem:hover
    .SidebarNavItemLink
    .SidebarNavItemLinkText {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
    visibility: visible;
    opacity: 1;
  }
  .Sidebar:not(.Collapsed)
    .SidebarContent
    .SidebarNav
    .SidebarNavItem:hover
    .SidebarNavItemLink.Active {
    position: relative;
  }
  .Sidebar:not(.Collapsed)
    .SidebarContent
    .SidebarNav
    .SidebarNavItem:hover
    .SidebarNavItemLink.Active:after {
    right: -200px;
  }
  .Sidebar:not(.Collapsed)
    .SidebarContent
    .SidebarNav
    .SidebarNavItem:hover
    .SidebarNavDropdownMenu {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
    visibility: visible;
    opacity: 1;
  }
  .Sidebar.Collapsed .SidebarContentTop .SiteLogo {
    margin-left: 0px;
  }
  .Sidebar.Collapsed .SidebarContentTop .SiteLogo img,
  .Sidebar.Collapsed .SidebarContentTop .SiteLogo svg {
    height: 18px;
    width: 145px;
  }
  .Sidebar.Collapsed .SidebarContentTop .SidebarToggler {
    right: 15px;
  }
}

.HamburgerToggler {
  height: 30px;
  width: 36px;
  border-radius: 5px;
  background: #623cea;
  margin-right: 20px;
  position: relative;
  display: none;
}

@media (max-width: 1199.98px) {
  .HamburgerToggler {
    display: block;
  }
}

.HamburgerToggler span {
  height: 2px;
  width: 20px;
  background: #fff;
  display: block;
  position: absolute;
  left: 8px;
  border-radius: 1px;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.HamburgerToggler span:nth-child(1) {
  top: 8px;
}

.HamburgerToggler span:nth-child(2) {
  top: 14px;
  opacity: 1;
  visibility: visible;
}

.HamburgerToggler span:nth-child(3) {
  bottom: 8px;
}

.HamburgerToggler.Active {
  -webkit-box-shadow: 0 0 10px rgba(98, 60, 234, 0.8);
  box-shadow: 0 0 10px rgba(98, 60, 234, 0.8);
}

.HamburgerToggler.Active span:nth-child(1) {
  -webkit-transform: rotate(35deg) translate(3.25px, 4.95px);
  transform: rotate(35deg) translate(3.25px, 4.95px);
}

.HamburgerToggler.Active span:nth-child(2) {
  opacity: 0;
  visibility: hidden;
}

.HamburgerToggler.Active span:nth-child(3) {
  -webkit-transform: rotate(-35deg) translate(3.25px, -4.95px);
  transform: rotate(-35deg) translate(3.25px, -4.95px);
}

.SecondarySidebar {
  width: 300px;
  min-width: 300px;
  height: 100%;
  background: #fff;
  padding: 30px 0 30px 25px;
  z-index: 1000;
  border: 1.5px solid #f1f1f1;
  border-radius: 20px;
  margin-right: 30px;
}

@media (max-width: 1600.98px) {
  .SecondarySidebar {
    width: 240px;
    min-width: 240px;
  }
}

@media (max-width: 1399.98px) {
  .SecondarySidebar {
    width: 210px;
    min-width: 210px;
  }
}

@media (max-width: 1199.98px) {
  .SecondarySidebar {
    padding: 15px 0 15px 25px;
  }
}

.SecondarySidebar .SidebarNav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
}

.SecondarySidebar .SidebarNavItemLink {
  padding: 10px 0;
  text-decoration: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1rem;
  line-height: 1.875rem;
  color: #a9adb9;
  width: 100% !important;
}

.SecondarySidebar .SidebarNavItemLinkText {
  text-transform: uppercase;
}

.SecondarySidebar .SidebarNavItemLink:hover .SidebarNavItemLinkIcon,
.SecondarySidebar .SidebarNavItemLink.Active .SidebarNavItemLinkIcon {
  color: #623cea;
}

.SecondarySidebar .SidebarNavItemLink:hover .SidebarNavItemLinkText,
.SecondarySidebar .SidebarNavItemLink.Active .SidebarNavItemLinkText {
  color: #1c2238;
}

.SecondarySidebar .SidebarNavItemLink.Active {
  position: relative;
}

.SecondarySidebar .SidebarNavItemLink.Active:after {
  content: '';
  position: absolute;
  right: 0;
  top: 10px;
  bottom: 10px;
  width: 4px;
  background: #623cea;
  border-radius: 5px;
}

@media (max-width: 1199.98px) {
  .SecondarySidebar {
    position: absolute;
    top: -15px;
    left: 0;
    height: unset;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
    -webkit-box-shadow: 0 0 20px rgba(28, 34, 56, 0.3);
    box-shadow: 0 0 20px rgba(28, 34, 56, 0.3);
    border-radius: 10px;
  }
  .SecondarySidebar.Show {
    visibility: visible;
    opacity: 1;
  }
}

@media (max-width: 991.98px) {
  .SecondarySidebar {
    top: -10px;
  }
}

@media (max-width: 767.98px) {
  .SecondarySidebar {
    top: -10px;
  }
}

@media (max-width: 575.98px) {
  .SecondarySidebar {
    top: -10px;
  }
}

@media (max-width: 499.98px) {
  .SecondarySidebar {
    top: -5px;
  }
}

.ContentAreaHeader {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1005;
  background-color: #fff;
}

.ContentAreaHeader .HeaderWrapper {
  padding: 17px 50px;
  background-color: #fff;
  border: 1.5px solid #f1f1f1;
  border-right: none;
  border-top-left-radius: 50px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
}

@media (max-width: 1600.98px) {
  .ContentAreaHeader .HeaderWrapper {
    padding: 13px 50px;
  }
}

@media (max-width: 1399.98px) {
  .ContentAreaHeader .HeaderWrapper {
    padding: 10px 50px;
  }
}

@media (max-width: 1199.98px) {
  .ContentAreaHeader .HeaderWrapper {
    padding: 10px 30px;
  }
}

@media (max-width: 499.98px) {
  .ContentAreaHeader .HeaderWrapper {
    padding: 5px 15px 5px 20px;
  }
}

.ContentAreaHeader
  .HeaderWrapper
  .ContentAreaHeaderRight
  .ProfileControlButton {
  padding: 7px;
  border: 1.5px solid #f1f1f1;
  border-radius: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  background: #fff;
  padding-right: 55px !important;
}

@media (max-width: 575.98px) {
  .ContentAreaHeader
    .HeaderWrapper
    .ContentAreaHeaderRight
    .ProfileControlButton {
    padding-right: 35px !important;
  }
}

@media (max-width: 1600.98px) {
  .ContentAreaHeader
    .HeaderWrapper
    .ContentAreaHeaderRight
    .ProfileControlButton {
    padding: 5px;
  }
}

@media (max-width: 499.98px) {
  .ContentAreaHeader
    .HeaderWrapper
    .ContentAreaHeaderRight
    .ProfileControlButton {
    padding: 2px;
  }
}

.ContentAreaHeader
  .HeaderWrapper
  .ContentAreaHeaderRight
  .ProfileControlButton:hover {
  -webkit-box-shadow: 0 0 10px 5px #f1f1f1;
  box-shadow: 0 0 10px 5px #f1f1f1;
}

.ContentAreaHeader
  .HeaderWrapper
  .ContentAreaHeaderRight
  .ProfileControlButton
  .ProfileImage {
  height: 50px;
  width: 50px;
  min-width: 50px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.ContentAreaHeader
  .HeaderWrapper
  .ContentAreaHeaderRight
  .ProfileControlButton
  .ProfileImage
  img {
  border-radius: 50%;
}

@media (max-width: 1600.98px) {
  .ContentAreaHeader
    .HeaderWrapper
    .ContentAreaHeaderRight
    .ProfileControlButton
    .ProfileImage {
    width: 46px;
    height: 46px;
    min-width: 46px;
  }
}

@media (max-width: 1399.98px) {
  .ContentAreaHeader
    .HeaderWrapper
    .ContentAreaHeaderRight
    .ProfileControlButton
    .ProfileImage {
    width: 40px;
    height: 40px;
    min-width: 40px;
  }
}

@media (max-width: 575.98px) {
  .ContentAreaHeader
    .HeaderWrapper
    .ContentAreaHeaderRight
    .ProfileControlButton
    .ProfileImage {
    width: 30px;
    height: 30px;
    min-width: 30px;
  }
}

@media (max-width: 499.98px) {
  .ContentAreaHeader
    .HeaderWrapper
    .ContentAreaHeaderRight
    .ProfileControlButton
    .ProfileImage {
    width: 25px;
    height: 25px;
    min-width: 25px;
  }
}

.ContentAreaHeader
  .HeaderWrapper
  .ContentAreaHeaderRight
  .ProfileControlButton
  .ProfileImage
  img {
  width: 100%;
  height: 100%;
}

.ContentAreaHeader
  .HeaderWrapper
  .ContentAreaHeaderRight
  .ProfileControlButton
  .ProfileDetails {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-left: 15px;
}

@media (max-width: 575.98px) {
  .ContentAreaHeader
    .HeaderWrapper
    .ContentAreaHeaderRight
    .ProfileControlButton
    .ProfileDetails {
    display: none;
  }
}

.ContentAreaHeader
  .HeaderWrapper
  .ContentAreaHeaderRight
  .ProfileControlButton
  .ProfileDetails
  .UserName {
  font-size: 1.125rem;
  font-weight: 500;
  color: #1c2238;
}

.ContentAreaHeader
  .HeaderWrapper
  .ContentAreaHeaderRight
  .ProfileControlButton
  .ProfileDetails
  .CompanyName {
  font-size: 0.8275rem;
  font-weight: 500;
  color: #a9adb9;
  margin-top: 4px;
}

.ContentAreaHeader
  .HeaderWrapper
  .ContentAreaHeaderRight
  .ProfileControlButton:after {
  font-family: 'WP-Insight';
  content: '\e918';
  position: absolute;
  border: none !important;
  top: 50%;
  right: 25px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 0.75rem;
  color: #a9adb9;
}

@media (max-width: 575.98px) {
  .ContentAreaHeader
    .HeaderWrapper
    .ContentAreaHeaderRight
    .ProfileControlButton:after {
    right: 15px;
  }
}

.DropdownToggler {
  padding: 2px 25px;
  min-height: 50px;
  min-width: 200px;
  line-height: 1.45;
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-radius: 10px;
  border: 1.5px solid #f1f1f1;
  background: #fff;
  color: #1c2238;
}

@media (max-width: 1600.98px) {
  .DropdownToggler {
    min-height: 46px;
    padding: 2px 20px;
  }
}

@media (max-width: 1399.98px) {
  .DropdownToggler {
    min-height: 40px;
    padding: 2px 20px;
  }
}

@media (max-width: 767.98px) {
  .DropdownToggler {
    min-width: 150px;
  }
}

.DropdownToggler:after {
  font-family: 'WP-Insight' !important;
  content: '\e918';
  vertical-align: middle;
  border: 0;
  margin: unset;
  font-size: 0.6125rem;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  margin-left: 20px;
  display: inline-block;
  color: #a9adb9;
}

.wp-dropdown {
  background: #fff !important;
}

.dropdown-menu {
  padding: 10px;
  border: none;
  min-width: 250px;
  border-radius: 10px;
  -webkit-box-shadow: 0 5px 10px rgba(169, 173, 185, 0.3);
  box-shadow: 0 5px 10px rgba(169, 173, 185, 0.3);
  border: 1.5px solid #f1f1f1;
  top: 10px !important;
  max-height: 520px;
  overflow-y: auto;
}

.dropdown-menu .DropdownHeading {
  padding: 5px 10px 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  border-bottom: 1.5px solid #f1f1f1;
}

.dropdown-menu .DropdownHeading small {
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.25;
  color: #a9adb9;
  margin-bottom: 3px;
}

.dropdown-menu .DropdownHeading p {
  font-size: 0.9275rem;
  font-weight: 500;
  line-height: 1.25;
  color: #1c2238;
}

.dropdown-menu .DropdownItem {
  padding: 0;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25;
  color: #1c2238;
}

.dropdown-menu .DropdownItem .Checkbox,
.dropdown-menu .DropdownItem a {
  padding: 10px;
  color: #1c2238;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.dropdown-menu .DropdownItem .Checkbox input[type='checkbox'] + span:before {
  font-size: 0.75rem !important;
}

.dropdown-menu
  .DropdownItem
  .Checkbox
  input[type='checkbox']:checked
  + span:before {
  content: '\e908' !important;
  color: #43bc9c !important;
}

.dropdown-menu .DropdownItem.CustomItem {
  border-top: 1.5px solid #f1f1f1;
}

.dropdown-menu .DropdownItem:active,
.dropdown-menu .DropdownItem.Active,
.dropdown-menu .DropdownItem:hover {
  background: unset;
}

.dropdown-menu .DropdownItem:active .Checkbox,
.dropdown-menu .DropdownItem:active a,
.dropdown-menu .DropdownItem.Active .Checkbox,
.dropdown-menu .DropdownItem.Active a,
.dropdown-menu .DropdownItem:hover .Checkbox,
.dropdown-menu .DropdownItem:hover a {
  background: #f9f9fb;
  color: #1c2238;
}

.dropdown-menu .DropdownItem.Active a:after {
  color: #43bc9c;
  font-family: 'WP-Insight' !important;
  content: '\e906';
}

.ProfileControl .dropdown-menu {
  padding: 0;
  border: none;
  min-width: 220px !important;
  max-width: 250px !important;
  border-radius: 5px;
  -webkit-box-shadow: 0 0 20px rgba(28, 34, 56, 0.3);
  box-shadow: 0 0 20px rgba(28, 34, 56, 0.3);
}

.ProfileControl .dropdown-menu ul {
  padding: 7px;
}

.ProfileControl .dropdown-menu ul li button,
.ProfileControl .dropdown-menu ul li .DropdownItemLink {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  line-height: 1;
  padding: 7px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.ProfileControl .dropdown-menu ul li button .navigation-icon,
.ProfileControl .dropdown-menu ul li .DropdownItemLink .navigation-icon {
  width: 25px;
  min-width: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #623cea;
  font-size: 0.75rem;
  border-radius: 5px;
  height: 25px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: rgba(98, 60, 234, 0.3);
}

.ProfileControl .dropdown-menu ul li button .navigation-image,
.ProfileControl .dropdown-menu ul li .DropdownItemLink .navigation-image {
  min-width: 25px;
  width: 25px;
  display: inline-block;
  overflow: hidden;
  color: #623cea;
  font-size: 0.75rem;
  border-radius: 5px;
  height: 25px;
  line-height: 25px;
  text-align: center;
}

.ProfileControl .dropdown-menu ul li button .navigation-image img,
.ProfileControl .dropdown-menu ul li .DropdownItemLink .navigation-image img {
  width: 100%;
  display: block;
  border-radius: 5px;
}

.ProfileControl .dropdown-menu ul li button .navigation-text,
.ProfileControl .dropdown-menu ul li .DropdownItemLink .navigation-text {
  margin-left: 10px;
  color: #1c2238;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.15;
}

.ProfileControl .dropdown-menu ul li.active button .navigation-icon,
.ProfileControl .dropdown-menu ul li.active .DropdownItemLink .navigation-icon {
  color: #fff;
  background: #623cea;
}

.ProfileControl .dropdown-menu ul li.active button .navigation-text,
.ProfileControl .dropdown-menu ul li.active .DropdownItemLink .navigation-text {
  color: #1c2238;
}

.ProfileControl .dropdown-menu ul li:hover {
  background: #f9f9fb;
}

@media (max-width: 575.98px) {
  .ProfileControl .dropdown-menu {
    min-width: 180px !important;
    max-width: 200px !important;
    top: 15px !important;
  }
  .ProfileControl .dropdown-menu ul {
    padding: 4px;
  }
  .ProfileControl .dropdown-menu ul li button,
  .ProfileControl .dropdown-menu ul li .DropdownItemLink {
    padding: 4px;
  }
}

.DropdownControl .Button.dropdown-toggle:after,
.DropdownControl
  .TabControllerWrapper
  .nav-item
  .dropdown-toggle.TabButton:after,
.TabControllerWrapper
  .nav-item
  .DropdownControl
  .dropdown-toggle.TabButton:after {
  margin-left: 5px;
  -webkit-transform: translateX(5px);
  transform: translateX(5px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.DropdownControl .dropdown-menu {
  padding: 0;
  border: none;
  min-width: 220px !important;
  max-width: 250px !important;
  border-radius: 5px;
  -webkit-box-shadow: 0 0 20px rgba(28, 34, 56, 0.3);
  box-shadow: 0 0 20px rgba(28, 34, 56, 0.3);
}

.DropdownControl .dropdown-menu ul {
  padding: 7px;
}

.DropdownControl .dropdown-menu ul li button,
.DropdownControl .dropdown-menu ul li .DropdownItemLink {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  line-height: 1;
  padding: 7px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.DropdownControl .dropdown-menu ul li button .navigation-icon,
.DropdownControl .dropdown-menu ul li .DropdownItemLink .navigation-icon {
  width: 25px;
  min-width: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #623cea;
  font-size: 0.75rem;
  border-radius: 5px;
  height: 25px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: rgba(98, 60, 234, 0.3);
}

.DropdownControl .dropdown-menu ul li button .navigation-image,
.DropdownControl .dropdown-menu ul li .DropdownItemLink .navigation-image {
  min-width: 25px;
  width: 25px;
  display: inline-block;
  overflow: hidden;
  color: #623cea;
  font-size: 0.75rem;
  border-radius: 5px;
  height: 25px;
  line-height: 25px;
  text-align: center;
}

.DropdownControl .dropdown-menu ul li button .navigation-image img,
.DropdownControl .dropdown-menu ul li .DropdownItemLink .navigation-image img {
  width: 100%;
  display: block;
  border-radius: 5px;
}

.DropdownControl .dropdown-menu ul li button .navigation-text,
.DropdownControl .dropdown-menu ul li .DropdownItemLink .navigation-text {
  margin-left: 10px;
  color: #1c2238;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.15;
}

.DropdownControl .dropdown-menu ul li.active button .navigation-icon,
.DropdownControl
  .dropdown-menu
  ul
  li.active
  .DropdownItemLink
  .navigation-icon {
  color: #fff;
  background: #623cea;
}

.DropdownControl .dropdown-menu ul li.active button .navigation-text,
.DropdownControl
  .dropdown-menu
  ul
  li.active
  .DropdownItemLink
  .navigation-text {
  color: #1c2238;
}

.DropdownControl .dropdown-menu ul li:hover {
  background: #f9f9fb;
}

@media (max-width: 575.98px) {
  .DropdownControl .dropdown-menu {
    min-width: 180px !important;
    max-width: 200px !important;
    top: 15px !important;
  }
  .DropdownControl .dropdown-menu ul {
    padding: 4px;
  }
  .DropdownControl .dropdown-menu ul li button,
  .DropdownControl .dropdown-menu ul li .DropdownItemLink {
    padding: 4px;
  }
}

.SectionGap {
  margin-top: 50px;
}

@media (max-width: 1199.98px) {
  .SectionGap {
    margin-top: 40px;
  }
}

@media (max-width: 991.98px) {
  .SectionGap {
    margin-top: 30px;
  }
}

@media (max-width: 767.98px) {
  .SectionGap {
    margin-top: 25px;
  }
}

@media (max-width: 575.98px) {
  .SectionGap {
    margin-top: 20px;
  }
}

@media (max-width: 499.98px) {
  .SectionGap {
    margin-top: 15px;
  }
}

.HasColGap {
  margin-bottom: -30px;
}

.HasColGap > .col-md-1,
.HasColGap > .col-md-2,
.HasColGap > .col-md-3,
.HasColGap > .col-md-4,
.HasColGap > .col-md-5,
.HasColGap > .col-md-6,
.HasColGap > .col-md-7,
.HasColGap > .col-md-8,
.HasColGap > .col-md-9,
.HasColGap > .col-md-10,
.HasColGap > .col-md-11,
.HasColGap > .col-md-12,
.HasColGap > .col {
  margin-bottom: 30px !important;
}

.WpPageBody {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.SectionTitleWrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 50px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media (max-width: 1199.98px) {
  .SectionTitleWrap {
    margin-bottom: 40px;
  }
}

@media (max-width: 991.98px) {
  .SectionTitleWrap {
    margin-bottom: 30px;
  }
}

@media (max-width: 767.98px) {
  .SectionTitleWrap {
    margin-bottom: 25px;
  }
}

@media (max-width: 575.98px) {
  .SectionTitleWrap {
    margin-bottom: 20px;
  }
}

@media (max-width: 499.98px) {
  .SectionTitleWrap {
    margin-bottom: 15px;
  }
}

.SectionTitle,
.modal .ModalHeader .modal-title,
.DataViewSection .DataViewHeading .TabCard .TabControl {
  font-weight: 700;
  line-height: 1, 2;
  font-size: 1.375rem;
}

.BackButton {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.BackButton .BackButtonIcon {
  font-size: 0.875rem;
  color: #a9adb9;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.BackButton .BackButtonText {
  font-weight: 700;
  line-height: 1;
  font-size: 1.375rem;
  margin-left: 20px;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

@media (max-width: 499.98px) {
  .BackButton .BackButtonText {
    display: none;
  }
}

.BackButton:hover .BackButtonIcon,
.BackButton:focus .BackButtonIcon {
  color: #200b68;
}

.BackButton:hover .BackButtonText,
.BackButton:focus .BackButtonText {
  color: #200b68;
}

.EmptyMessage {
  padding: 30px 26.5px;
  font-size: 1.125rem;
  line-height: 1.5;
  background: #fff;
  border-radius: 10px;
  color: #ff5f74;
  font-weight: 500;
}

.EmptyMessage:not(first-child) {
  margin-top: 30px;
}

.EmptyMessage:not(last-child) {
  margin-bottom: 30px;
}

.SectionPanel {
  padding: 30px 30px 35px;
  background: #fff;
  border-radius: 20px;
  border: 1.5px solid #f1f1f1;
  position: relative;
  display: inline-block;
  width: 100%;
}

@media (max-width: 767.98px) {
  .SectionPanel {
    padding: 20px 20px 25px;
  }
}

@media (max-width: 575.98px) {
  .SectionPanel {
    padding: 15px 15px 20px;
  }
}

.SectionPanel .PanelTitle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.SectionPanel .PanelItem {
  -webkit-transition: all 0.5s linear;
  transition: all 0.5s linear;
  margin-top: 40px;
}

@media (max-width: 767.98px) {
  .SectionPanel .PanelItem {
    margin-top: 20px;
  }
}

.SectionPanel .PanelToggler {
  background: #fff;
  position: absolute;
  top: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 100px;
  height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 0 0 5px 5px;
  color: #a9adb9;
  -webkit-box-shadow: 0 5px 10px rgba(28, 34, 56, 0.05);
  box-shadow: 0 5px 10px rgba(28, 34, 56, 0.05);
  font-size: 0.75rem;
  cursor: pointer;
}

.SectionPanel .PanelToggler .Icon {
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.SectionPanel.PanelCollapsible {
  margin-bottom: 30px;
}

.SectionPanel.Collapsed .PanelToggler .Icon {
  -webkit-transform: rotateX(0deg);
  transform: rotateX(0deg);
}

.SectionPanel.Disable .PanelToggler {
  cursor: no-drop;
  color: #f1f1f1;
}

.SecondarySidebarContent {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: calc(100% - (300px + 30px));
}

@media (max-width: 1600.98px) {
  .SecondarySidebarContent {
    min-width: calc(100% - (250px + 30px));
  }
}

.ActionButtonGroup {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.ActionButtonGroup *:not(:last-child) {
  margin-right: 10px;
}

.FormGroup {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.FormGroup label {
  font-size: 1rem;
  color: #1c2238;
  margin-bottom: 15px;
  height: 20px;
}

@media (max-width: 1600.98px) {
  .FormGroup label {
    margin-bottom: 10px;
    height: 18px;
  }
}

@media (max-width: 1199.98px) {
  .FormGroup label {
    margin-bottom: 5px;
    height: 15px;
  }
}

@media (max-width: 1600.98px) {
  .FormGroup .Button,
  .FormGroup .TabControllerWrapper .nav-item .TabButton,
  .TabControllerWrapper .nav-item .FormGroup .TabButton {
    width: 100%;
  }
}

input.form-control,
.DateRangePicker .DateRangePickerInput {
  height: 50px;
  padding: 0 15px !important;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 10px;
  color: #1c2238;
  background: #f9f9fb;
  border: 1.5px solid #f1f1f1;
}

@media (max-width: 1600.98px) {
  input.form-control,
  .DateRangePicker .DateRangePickerInput {
    height: 46px;
  }
}

@media (max-width: 1399.98px) {
  input.form-control,
  .DateRangePicker .DateRangePickerInput {
    height: 40px;
  }
}

input.form-control:focus,
.DateRangePicker .DateRangePickerInput:focus {
  -webkit-box-shadow: 0 0 5px rgba(28, 34, 56, 0.3);
  box-shadow: 0 0 5px rgba(28, 34, 56, 0.3);
  border: 1.5px solid #f1f1f1;
}

textarea.form-control {
  padding: 15px !important;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 10px;
  color: #1c2238;
  background: #f9f9fb;
  border: 1.5px solid #f1f1f1;
  resize: none;
}

textarea.form-control:focus {
  -webkit-box-shadow: 0 0 5px rgba(28, 34, 56, 0.3);
  box-shadow: 0 0 5px rgba(28, 34, 56, 0.3);
  border: 1.5px solid #f1f1f1;
}

.HasError {
  border: 1px solid #e5142e !important;
}

.HasError:focus {
  -webkit-box-shadow: 0 0 5px rgba(229, 20, 46, 0.3) !important;
  box-shadow: 0 0 5px rgba(229, 20, 46, 0.3) !important;
}

.SelectOption {
  position: relative;
  border-radius: 10px;
  border: 1.5px solid #f1f1f1;
  background: #f9f9fb;
  min-height: 50px;
}

@media (max-width: 1600.98px) {
  .SelectOption {
    min-height: 46px;
  }
}

@media (max-width: 1399.98px) {
  .SelectOption {
    min-height: 40px;
  }
}

.SelectOption .react-select__control {
  padding-left: 13px;
  min-height: 47px;
  width: 100%;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.4286;
  font-family: unset;
  border: 1.5px solid transparent;
  border-radius: 10px;
  background: transparent;
  color: #1c2238;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

@media (max-width: 1600.98px) {
  .SelectOption .react-select__control {
    min-height: 43px !important;
  }
}

@media (max-width: 1399.98px) {
  .SelectOption .react-select__control {
    min-height: 37px !important;
  }
}

.SelectOption .react-select__control .react-select__value-container {
  padding: 0 !important;
}

.SelectOption .react-select__control--is-focused {
  -webkit-box-shadow: 0 0 5px rgba(28, 34, 56, 0.3) !important;
  box-shadow: 0 0 5px rgba(28, 34, 56, 0.3) !important;
  border: 1.5px solid #f1f1f1 !important;
}

.SearchForm {
  position: relative;
  height: 50px;
  min-width: 300px;
  background: #f9f9fb !important;
  border-radius: 10px;
  border-radius: 50px !important;
  /* clears the 'X' from Chrome */
}

@media (max-width: 1600.98px) {
  .SearchForm {
    height: 46px;
  }
}

@media (max-width: 1399.98px) {
  .SearchForm {
    height: 40px;
    min-width: 200px;
  }
}

.SearchForm::-ms-clear,
.SearchForm::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

.SearchForm::-webkit-search-decoration,
.SearchForm::-webkit-search-cancel-button,
.SearchForm::-webkit-search-results-button,
.SearchForm::-webkit-search-results-decoration {
  display: none;
}

.SearchForm:after {
  font-family: 'WP-Insight';
  content: '\e911';
  position: absolute;
  pointer-events: none;
  font-size: 1.125rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 20px;
  color: #a9adb9;
  margin-left: 20px !important;
}

.DateRangePicker {
  width: 100% !important;
}

.DateRangePicker .DateRangePickerInput {
  width: 100% !important;
  font-family: unset !important;
  padding: 0 !important;
  background: #fff;
}

.DateRangePicker .DateRangePickerInput .DataRangePickerInput__arrow {
  width: 30px !important;
}

.DateRangePicker .DateRangePickerInput .DateInput {
  background: unset !important;
  height: 100% !important;
  width: calc(50% - 30px) !important;
}

.DateRangePicker .DateRangePickerInput .DateInput .DateInput_input__focused,
.DateRangePicker .DateRangePickerInput .DateInput .DateInput_input {
  background: unset !important;
  font-size: unset !important;
  font-weight: unset !important;
  line-height: unset !important;
  height: 100% !important;
  padding: 0 15px !important;
}

.DatePickerWrapper {
  position: relative;
}

.DatePickerWrapper .CloseButton {
  position: absolute;
  top: 50%;
  right: 5px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.Checkbox {
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 500;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.Checkbox input[type='checkbox'] {
  display: none;
}

.Checkbox input[type='checkbox'] + span:before {
  font-family: 'WP-Insight';
  content: '\e907';
  color: #a9adb9;
  font-size: 0.875rem;
  margin-right: 10px;
}

.Checkbox input[type='checkbox']:checked + span:before {
  content: '\e909';
  color: #623cea;
}

.Radio {
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 500;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.Radio input[type='radio'] {
  display: none;
}

.Radio input[type='radio'] + span:before {
  font-family: 'WP-Insight';
  content: '\e90a';
  font-size: 0.75rem;
  margin-right: 10px;
}

.Radio input[type='radio']:checked + span:before {
  content: '\e90b';
}

input:disabled {
  cursor: no-drop;
  background-color: #f3f3f7 !important;
  color: #afb2be !important;
}

.CustomSelectNumber {
  margin: 0 7px;
}

.CustomSelectNumber .custom-select {
  padding: 2px 25px 2px 14px !important;
  height: 30px !important;
  border-radius: 5px;
  border-color: #f1f1f1;
  color: #623cea;
  font-weight: 500;
}

@media (max-width: 575.98px) {
  .CustomSelectNumber .custom-select {
    height: 20px !important;
    padding: 0 20px 0 10px !important;
  }
}

.ChangePlanBox .form-control,
.ModalTabContent .form-control {
  background: #fff !important;
}

.ChangePlanBox .DatePicker,
.ChangePlanBox .SelectOption,
.ModalTabContent .DatePicker,
.ModalTabContent .SelectOption {
  background: #fff !important;
  width: 100%;
}

.ImageSelector {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.ImageSelector .ImgBox {
  border-radius: 5px;
  background: #f9f9fb;
  height: 120px;
  width: 120px;
  min-width: 120px;
  border: 1px solid #f1f1f1;
  overflow: hidden;
  position: relative;
}

.ImageSelector .ImgBox img {
  max-width: 100%;
  max-height: 100%;
}

.ImageSelector .ImgBox .RemoveButton {
  position: absolute;
  top: 5px;
  right: 5px;
  background: #e5142e;
  color: #fff;
  height: 16px;
  width: 16px;
  font-size: 8px;
  border-radius: 3px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.ImageSelector .ImgBox .RemoveButton.Hide {
  display: none;
}

.ImageSelector .FileBrowseButton {
  color: #623cea;
  font-style: italic;
  text-decoration: underline;
  font-weight: 500;
  display: inline-block;
}

.ImageSelector .FileBrowseButton :hover {
  color: #3413ad;
}

.FileBrowseButton {
  cursor: pointer;
}

.FileBrowseButton input {
  display: none;
}

.OrderableFormData {
  min-height: 40px;
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  padding: 3px 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #8d92a2;
  width: 100%;
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
  .OrderableFormData {
    min-height: 36px;
  }
}

@media (max-width: 1199.98px) {
  .OrderableFormData {
    min-height: 36px;
  }
}

@media (max-width: 767.98px) {
  .OrderableFormData {
    min-height: 32px;
  }
}

.OrderableFormData .OrderType {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 5px 0;
}

.OrderableFormData .OrderType .OrderActive {
  margin-right: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.OrderableFormData .OrderType .OrderActive input[type='radio'] + span {
  color: #a9adb9;
}

.OrderableFormData .OrderType .OrderActive input[type='radio'] + span:before {
  color: #a9adb9;
}

.OrderableFormData .OrderType .OrderActive input[type='radio']:checked + span {
  color: #623cea;
}

.OrderableFormData
  .OrderType
  .OrderActive
  input[type='radio']:checked
  + span:before {
  color: #623cea;
}

.OrderableFormData .OrderType .dropdown {
  min-width: 125px;
}

@media (max-width: 499.98px) {
  .OrderableFormData .OrderType .dropdown {
    min-width: 70px;
  }
  .OrderableFormData .OrderType .dropdown .react-select__indicators {
    display: none;
  }
}

.OrderableFormData .OrderType .dropdown .SelectOption {
  min-height: 35px;
}

.OrderableFormData .OrderType .dropdown .SelectOption .react-select__control {
  min-height: 32px !important;
}

.OrderableFormData
  .OrderType
  .dropdown
  .SelectOption
  .react-select__control
  .react-select__indicator.react-select__dropdown-indicator {
  padding: 0 8px;
}

.FormGroupWrapper {
  max-width: 600px;
  margin-bottom: 30px;
}

.FormGroupWrapper .FormGroup:not(:last-child) {
  margin-bottom: 10px;
}

.order-data {
  min-height: 610px;
}

@media (max-width: 1399.98px) {
  .order-data {
    min-height: 560px;
  }
}

@media (max-width: 767.98px) {
  .order-data {
    min-height: 530px;
  }
}

.RemovablePermissionGroup .RemovablePermission {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #fff;
  padding: 10px;
  border: 1.5px solid #f1f1f1;
  border-radius: 10px;
  padding-right: 20px;
}

.RemovablePermissionGroup .RemovablePermission .RemovablePermissionInner {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

@media (max-width: 991.98px) {
  .RemovablePermissionGroup
    .RemovablePermission
    .RemovablePermissionInner
    .row
    *[class*='col-']:not(:last-child) {
    margin-bottom: 5px;
  }
}

.RemovablePermissionGroup .RemovablePermission:not(:last-child) {
  margin-bottom: 10px;
}

.rc-pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.rc-pagination > *:not(:last-child) {
  margin-right: 5px;
}

.rc-pagination:after {
  display: block;
  clear: both;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  content: ' ';
}

.rc-pagination-total-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media (max-width: 1600.98px) {
  .rc-pagination-total-text {
    height: 36px;
  }
}

@media (max-width: 1399.98px) {
  .rc-pagination-total-text {
    height: 30px;
  }
}

.rc-pagination-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-width: 40px;
  height: 40px;
  background-color: rgba(98, 60, 234, 0.1);
  border-radius: 10px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media (max-width: 1600.98px) {
  .rc-pagination-item {
    height: 36px;
    min-width: 36px;
  }
}

@media (max-width: 1399.98px) {
  .rc-pagination-item {
    height: 30px;
    min-width: 30px;
  }
}

.rc-pagination-item a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  color: #623cea;
  -webkit-transition: none;
  transition: none;
}

.rc-pagination-item:focus,
.rc-pagination-item:hover {
  background: #623cea;
}

.rc-pagination-item:focus a,
.rc-pagination-item:hover a {
  color: #fff;
}

.rc-pagination-item-active {
  background: #623cea;
}

.rc-pagination-item-active a {
  color: #fff;
}

.rc-pagination-item-active:focus a,
.rc-pagination-item-active:hover a {
  color: #fff;
}

.rc-pagination-jump-prev,
.rc-pagination-jump-next {
  background: rgba(98, 60, 234, 0.1);
  border-radius: 10px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.rc-pagination-jump-prev .rc-pagination-item-link:after,
.rc-pagination-jump-next .rc-pagination-item-link:after {
  display: block;
  content: '•••';
  color: #623cea;
  font-size: 0.875rem;
}

.rc-pagination-jump-prev:focus,
.rc-pagination-jump-prev:hover,
.rc-pagination-jump-next:focus,
.rc-pagination-jump-next:hover {
  background: #623cea;
}

.rc-pagination-jump-prev:focus .rc-pagination-item-link:after,
.rc-pagination-jump-prev:hover .rc-pagination-item-link:after,
.rc-pagination-jump-next:focus .rc-pagination-item-link:after,
.rc-pagination-jump-next:hover .rc-pagination-item-link:after {
  color: #fff;
}

.rc-pagination-prev,
.rc-pagination-next,
.rc-pagination-jump-prev,
.rc-pagination-jump-next {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-width: 40px;
  height: 40px;
  color: #623cea;
  border-radius: 10px;
  font-size: 0.75rem;
  cursor: pointer;
}

@media (max-width: 1600.98px) {
  .rc-pagination-prev,
  .rc-pagination-next,
  .rc-pagination-jump-prev,
  .rc-pagination-jump-next {
    height: 36px;
    min-width: 36px;
  }
}

@media (max-width: 1399.98px) {
  .rc-pagination-prev,
  .rc-pagination-next,
  .rc-pagination-jump-prev,
  .rc-pagination-jump-next {
    height: 30px;
    min-width: 30px;
  }
}

.rc-pagination-prev .rc-pagination-item-link,
.rc-pagination-next .rc-pagination-item-link {
  color: #623cea;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: rgba(98, 60, 234, 0.1);
  border-radius: 10px;
  color: #623cea;
}

.rc-pagination-prev:focus .rc-pagination-item-link,
.rc-pagination-prev:hover .rc-pagination-item-link,
.rc-pagination-next:focus .rc-pagination-item-link,
.rc-pagination-next:hover .rc-pagination-item-link {
  background: #623cea;
  color: #fff;
}

.rc-pagination-prev .rc-pagination-item-link:after {
  content: '\e919';
  font-family: 'WP-Insight' !important;
  display: block;
}

.rc-pagination-next .rc-pagination-item-link:after {
  content: '\e91a';
  font-family: 'WP-Insight' !important;
  display: block;
}

.rc-pagination-disabled .rc-pagination-item-link {
  background: rgba(169, 173, 185, 0.1);
}

.rc-pagination-disabled:hover,
.rc-pagination-disabled:focus {
  cursor: not-allowed;
}

.rc-pagination-disabled:hover .rc-pagination-item-link,
.rc-pagination-disabled:focus .rc-pagination-item-link {
  cursor: not-allowed;
  color: #623cea;
  background: rgba(169, 173, 185, 0.1);
}

.rc-pagination-simple .rc-pagination-prev,
.rc-pagination-simple .rc-pagination-next {
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media (max-width: 1600.98px) {
  .rc-pagination-simple .rc-pagination-prev,
  .rc-pagination-simple .rc-pagination-next {
    height: 36px;
  }
}

@media (max-width: 1399.98px) {
  .rc-pagination-simple .rc-pagination-prev,
  .rc-pagination-simple .rc-pagination-next {
    height: 30px;
  }
}

.rc-pagination-simple .rc-pagination-prev .rc-pagination-item-link,
.rc-pagination-simple .rc-pagination-next .rc-pagination-item-link {
  height: 40px;
}

@media (max-width: 1600.98px) {
  .rc-pagination-simple .rc-pagination-prev .rc-pagination-item-link,
  .rc-pagination-simple .rc-pagination-next .rc-pagination-item-link {
    height: 36px;
  }
}

@media (max-width: 1399.98px) {
  .rc-pagination-simple .rc-pagination-prev .rc-pagination-item-link,
  .rc-pagination-simple .rc-pagination-next .rc-pagination-item-link {
    height: 30px;
  }
}

.rc-pagination-simple .rc-pagination-prev .rc-pagination-item-link::after,
.rc-pagination-simple .rc-pagination-next .rc-pagination-item-link::after {
  height: 40px;
}

@media (max-width: 1600.98px) {
  .rc-pagination-simple .rc-pagination-prev .rc-pagination-item-link::after,
  .rc-pagination-simple .rc-pagination-next .rc-pagination-item-link::after {
    height: 36px;
  }
}

@media (max-width: 1399.98px) {
  .rc-pagination-simple .rc-pagination-prev .rc-pagination-item-link::after,
  .rc-pagination-simple .rc-pagination-next .rc-pagination-item-link::after {
    height: 30px;
  }
}

@media (max-width: 1600.98px) {
  .rc-pagination {
    height: 36px;
  }
}

@media (max-width: 1399.98px) {
  .rc-pagination {
    height: 30px;
  }
}

.LoginBody {
  background: #623cea;
  min-height: 100vh;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.LoginBody .LoginHeader {
  position: absolute;
  top: 40px;
  left: 35px;
  right: 35px;
}

@media (max-width: 1399.98px) {
  .LoginBody .LoginHeader {
    top: 20px;
    left: 25px;
    right: 25px;
  }
}

.LoginBody .LoginHeader .SiteLogo {
  width: 170px;
}

@media (max-width: 1399.98px) {
  .LoginBody .LoginHeader .SiteLogo svg {
    height: 30px;
  }
}

.LoginBody .LoginWrapper {
  max-width: 1400px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.LoginBody .LoginWrapper .LoginContent,
.LoginBody .LoginWrapper .LoginImage {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  padding: 110px 35px 20px;
}

@media (max-width: 1399.98px) {
  .LoginBody .LoginWrapper .LoginContent,
  .LoginBody .LoginWrapper .LoginImage {
    padding: 70px 30px 15px;
  }
}

@media (max-width: 1199.98px) {
  .LoginBody .LoginWrapper .LoginContent {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 55%;
    flex: 0 0 55%;
    max-width: 55%;
  }
  .LoginBody .LoginWrapper .LoginImage {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 45%;
    flex: 0 0 45%;
    max-width: 45%;
    padding: 110px 35px 50px 0;
  }
}

@media (max-width: 991.98px) {
  .LoginBody .LoginWrapper .LoginContent {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 60%;
    flex: 0 0 60%;
    max-width: 60%;
  }
  .LoginBody .LoginWrapper .LoginImage {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 40%;
    flex: 0 0 40%;
    max-width: 40%;
  }
}

@media (max-width: 767.98px) {
  .LoginBody .LoginWrapper .LoginContent {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .LoginBody .LoginWrapper .LoginImage {
    display: none !important;
  }
}

.LoginBody .LoginWrapper .LoginContent {
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.LoginBody .LoginWrapper .LoginContent .LoginForm {
  background: #fff;
  padding: 100px;
  border-radius: 20px;
  width: 100%;
}

@media (max-width: 1600.98px) {
  .LoginBody .LoginWrapper .LoginContent .LoginForm {
    padding: 70px;
  }
}

@media (max-width: 1399.98px) {
  .LoginBody .LoginWrapper .LoginContent .LoginForm {
    padding: 40px;
  }
}

@media (max-width: 499.98px) {
  .LoginBody .LoginWrapper .LoginContent .LoginForm {
    padding: 25px;
  }
}

.LoginBody .LoginWrapper .LoginContent .LoginForm h2 {
  font-size: 2.25rem;
  font-weight: 700;
  margin-bottom: 30px;
}

.LoginBody .LoginWrapper .LoginImage {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.modal-diagram {
  border: none;
}

.modal-content {
  border: 1px solid #f1f1f1 !important;
  border-radius: 20px !important;
}

.modal-backdrop {
  background-color: #1c2238 !important;
  opacity: 0.7 !important;
}

.modal .ModalHeader {
  padding: 30px;
  background: #fff;
  border-radius: 20px 20px 0 0 !important;
  border-bottom: 1px solid #f1f1f1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 991.98px) {
  .modal .ModalHeader {
    padding: 15px;
  }
}

.modal .ModalHeader .close {
  height: 30px;
  width: 30px;
  padding: 0;
  margin: 0;
  background: #eaeaf1;
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  color: #4f5361;
  text-shadow: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 1;
  font-size: 0.75rem;
}

@media (max-width: 1600.98px) {
  .modal .ModalHeader .close {
    height: 26px;
    width: 26px;
  }
}

.modal .ModalHeader .close:hover {
  background: #d9dbe0;
  color: #1c2238;
}

.modal .ModalBody {
  padding: 30px;
  background: #fff;
}

@media (max-width: 991.98px) {
  .modal .ModalBody {
    padding: 15px;
  }
}

.modal .ModalBody .ModalControlGroup {
  margin-bottom: 30px;
}

@media (max-width: 991.98px) {
  .modal .ModalBody .ModalControlGroup {
    margin-bottom: 20px;
  }
}

.modal .ModalBody .ModalControlGroup .ModalTabControllers {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: #f9f9fb;
  color: #a9adb9;
  border: 1px solid #f1f1f1;
  border-radius: 10px;
}

.modal .ModalBody .ModalControlGroup .ModalTabControllers .ModalTabControl {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-size: 1rem;
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  text-transform: uppercase;
  font-weight: 500;
  color: #a9adb9;
}

@media (max-width: 1399.98px) {
  .modal .ModalBody .ModalControlGroup .ModalTabControllers .ModalTabControl {
    padding: 15px;
  }
}

@media (max-width: 991.98px) {
  .modal .ModalBody .ModalControlGroup .ModalTabControllers .ModalTabControl {
    padding: 10px;
  }
}

.modal
  .ModalBody
  .ModalControlGroup
  .ModalTabControllers
  .ModalTabControl.Active {
  background: #623cea;
  color: #fff;
}

.modal
  .ModalBody
  .ModalControlGroup
  .ModalTabControllers
  .ModalTabControl.Active:hover {
  background: #623cea;
  color: #fff;
}

.modal
  .ModalBody
  .ModalControlGroup
  .ModalTabControllers
  .ModalTabControl.Passed {
  color: #623cea;
}

.modal
  .ModalBody
  .ModalControlGroup
  .ModalTabControllers
  .ModalTabControl.Passed:hover {
  color: #623cea;
}

.modal
  .ModalBody
  .ModalControlGroup
  .ModalTabControllers
  .ModalTabControl:hover {
  background: #f3f3f7;
  color: #666b7d;
}

.modal .ModalBody .ModalControlGroup .progress {
  margin-top: 30px;
  border-radius: 10px !important;
  height: 10px !important;
}

@media (max-width: 1399.98px) {
  .modal .ModalBody .ModalControlGroup .progress {
    margin-top: 15px;
  }
}

@media (max-width: 991.98px) {
  .modal .ModalBody .ModalControlGroup .progress {
    margin-top: 10px;
  }
}

.modal .ModalBody .ModalControlGroup .progress .progress-bar {
  background: #623cea !important;
}

.modal .ModalBody .ActiveSubscriptionPlan {
  background: #f9f9fb;
  border-radius: 10px;
  border: 1.5px solid #f1f1f1;
  padding: 30px;
}

.modal .ModalBody .ActiveSubscriptionPlan p.SmallButton {
  position: absolute;
  right: 30px;
  top: 30px;
}

.modal .ModalBody .ChangePlanBox {
  background: #f9f9fb;
  border-radius: 10px;
  border: 1.5px solid #f1f1f1;
}

.modal .ModalBody .ChangePlanBoxTitle {
  padding: 30px;
  border-bottom: 1.5px solid #f1f1f1;
  border-radius: 10px 10px 0 0;
}

.modal .ModalBody .ChangePlanBoxBody {
  padding: 30px;
  border-radius: 0 0 10px 10px;
}

.modal .ModalBody .ChangePlanBoxBody .TabContentRow label {
  min-width: 20px;
  margin-bottom: 20px;
}

@media (max-width: 991.98px) {
  .modal .ModalBody .ChangePlanBoxBody .TabContentRow label {
    margin-bottom: 5px;
  }
}

.modal .ModalBody .ChangePlanBoxBody .TabContentRow:not(:first-child) {
  margin-top: 20px;
}

@media (max-width: 991.98px) {
  .modal .ModalBody .ChangePlanBoxBody .TabContentRow:not(:first-child) {
    margin-top: 10px !important;
  }
}

.modal .ModalBody .ModalTabContent {
  background: #f9f9fb;
  border-radius: 10px;
  border: 1.5px solid #f1f1f1;
  padding: 30px;
}

@media (max-width: 991.98px) {
  .modal .ModalBody .ModalTabContent {
    padding: 15px;
  }
}

.modal .ModalBody .ModalTabContent .TabContentRow {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 991.98px) {
  .modal .ModalBody .ModalTabContent .TabContentRow {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
}

.modal .ModalBody .ModalTabContent .TabContentRow label {
  min-width: 220px;
  margin-right: 20px;
}

@media (max-width: 991.98px) {
  .modal .ModalBody .ModalTabContent .TabContentRow label {
    min-width: 100px;
    margin-bottom: 5px;
  }
}

.modal .ModalBody .ModalTabContent .TabContentRow:not(:first-child) {
  margin-top: 20px;
}

@media (max-width: 991.98px) {
  .modal .ModalBody .ModalTabContent .TabContentRow:not(:first-child) {
    margin-top: 10px !important;
  }
}

.modal .ModalBody .ModalTabContent.PackageModalContent .HasColGap {
  margin-bottom: -20px;
}

.modal .ModalBody .ModalTabContent.PackageModalContent .HasColGap > .col-md-1,
.modal .ModalBody .ModalTabContent.PackageModalContent .HasColGap > .col-md-2,
.modal .ModalBody .ModalTabContent.PackageModalContent .HasColGap > .col-md-3,
.modal .ModalBody .ModalTabContent.PackageModalContent .HasColGap > .col-md-4,
.modal .ModalBody .ModalTabContent.PackageModalContent .HasColGap > .col-md-5,
.modal .ModalBody .ModalTabContent.PackageModalContent .HasColGap > .col-md-6,
.modal .ModalBody .ModalTabContent.PackageModalContent .HasColGap > .col-md-7,
.modal .ModalBody .ModalTabContent.PackageModalContent .HasColGap > .col-md-8,
.modal .ModalBody .ModalTabContent.PackageModalContent .HasColGap > .col-md-9,
.modal .ModalBody .ModalTabContent.PackageModalContent .HasColGap > .col-md-10,
.modal .ModalBody .ModalTabContent.PackageModalContent .HasColGap > .col-md-11,
.modal .ModalBody .ModalTabContent.PackageModalContent .HasColGap > .col-md-12,
.modal .ModalBody .ModalTabContent.PackageModalContent .HasColGap > .col {
  margin-bottom: 20px !important;
}

.modal .ModalBody .ModalTabContent.PackageModalContent .SectionTitleWrap {
  margin-bottom: 20px;
}

.modal .ModalBody .ModalTabContent.PackageModalContent .TabContentRow label {
  min-width: 120px;
  margin-right: 20px;
}

@media (max-width: 991.98px) {
  .modal .ModalBody .ModalTabContent.PackageModalContent .TabContentRow label {
    min-width: 100px;
    margin-bottom: 3px;
  }
}

.modal
  .ModalBody
  .ModalTabContent.PackageModalContent
  .TabContentRow:not(:first-child) {
  margin-top: 20px;
}

@media (max-width: 991.98px) {
  .modal
    .ModalBody
    .ModalTabContent.PackageModalContent
    .TabContentRow:not(:first-child) {
    margin-top: 10px !important;
  }
}

.modal
  .ModalBody
  .ModalTabContent.PackageModalContent
  .RemovablePermissionGroup {
  background: #fff;
  padding: 20px;
  padding-right: 0;
  border: 1.5px solid #f1f1f1;
  border-radius: 3px;
  max-height: 285px;
  overflow-y: auto;
}

.modal
  .ModalBody
  .ModalTabContent.PackageModalContent
  .RemovablePermissionGroup::-webkit-scrollbar {
  width: 5px;
}

.modal
  .ModalBody
  .ModalTabContent.PackageModalContent
  .RemovablePermissionGroup::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(169, 173, 185, 0.1);
  background-image: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(rgba(98, 60, 234, 0.05)),
    to(rgba(0, 208, 255, 0.05))
  );
  background-image: linear-gradient(
    to right bottom,
    rgba(98, 60, 234, 0.05),
    rgba(0, 208, 255, 0.05)
  );
}

.modal
  .ModalBody
  .ModalTabContent.PackageModalContent
  .RemovablePermissionGroup::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: #4218db;
}

.modal
  .ModalBody
  .ModalTabContent.PackageModalContent
  .RemovablePermissionGroup
  .RemovablePermission {
  margin-right: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #f9f9fb;
  padding: 10px;
  border: 1.5px solid #f1f1f1;
  border-radius: 10px;
  padding-right: 20px;
}

.modal
  .ModalBody
  .ModalTabContent.PackageModalContent
  .RemovablePermissionGroup
  .RemovablePermission
  .RemovablePermissionInner {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

@media (max-width: 991.98px) {
  .modal
    .ModalBody
    .ModalTabContent.PackageModalContent
    .RemovablePermissionGroup
    .RemovablePermission
    .RemovablePermissionInner
    .row
    *[class*='col-']:not(:last-child) {
    margin-bottom: 5px;
  }
}

.modal
  .ModalBody
  .ModalTabContent.PackageModalContent
  .RemovablePermissionGroup
  .RemovablePermission:not(:last-child) {
  margin-bottom: 10px;
}

.modal .ModalBody .ModalTabContent:not(:last-child) {
  margin-bottom: 20px;
}

.modal .ModalBody:last-child {
  border-radius: 0 0 20px 20px !important;
}

.WpPageBody .ContentArea {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 50px 0 0 50px;
  background: #f9f9fb;
  min-height: 100vh;
  min-width: calc(100% - 300px);
}

.WpPageBody .ContentAreaBody {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 0 50px 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

@media (max-width: 1199.98px) {
  .WpPageBody .ContentAreaBody {
    padding: 0 40px 40px;
  }
}

@media (max-width: 991.98px) {
  .WpPageBody .ContentAreaBody {
    padding: 0 30px 30px;
  }
}

@media (max-width: 767.98px) {
  .WpPageBody .ContentAreaBody {
    padding: 0 25px 25px;
  }
}

@media (max-width: 575.98px) {
  .WpPageBody .ContentAreaBody {
    padding: 0 20px 20px;
  }
}

@media (max-width: 499.98px) {
  .WpPageBody .ContentAreaBody {
    padding: 0 15px 15px;
  }
}

@media (max-width: 767.98px) {
  .OverviewSection .row-cols-xl-4 {
    margin-left: -5px;
    margin-right: -5px;
  }
  .OverviewSection .row-cols-xl-4.HasColGap {
    margin-bottom: -10px !important;
  }
  .OverviewSection .row-cols-xl-4.HasColGap .col {
    margin-bottom: 10px !important;
  }
}

@media (max-width: 1399.98px) {
  .OverviewSection .row-cols-xl-4 .col {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}

@media (max-width: 1199.98px) {
  .OverviewSection .row-cols-xl-4 .col {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 767.98px) {
  .OverviewSection .row-cols-xl-4 .col {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (max-width: 575.98px) {
  .OverviewSection .row-cols-xl-4 .col {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.OverviewSection .OverviewCard {
  padding: 20px 25px;
  border-radius: 20px;
  border: 1.5px solid #f1f1f1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

@media (max-width: 1600.98px) {
  .OverviewSection .OverviewCard {
    padding: 25px 30px;
  }
}

@media (max-width: 767.98px) {
  .OverviewSection .OverviewCard {
    padding: 15px;
  }
}

.OverviewSection .OverviewCard .ProductTitle {
  font-size: 1.6875rem;
  color: #1c2238;
  margin-bottom: 10px;
  line-height: 1.1;
  font-weight: 700;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

@media (max-width: 1600.98px) {
  .OverviewSection .OverviewCard .ProductTitle {
    font-size: 1.375rem;
    margin-bottom: 10px;
  }
}

.OverviewSection .OverviewCard .WpiTooltip {
  position: absolute;
  top: 10px;
  right: 10px;
}

.OverviewSection .OverviewCard .WpiTooltip .WpiTooltipButton {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #a9adb9;
  font-size: 16px;
}

.OverviewSection .OverviewCard .WpiTooltip .WpiTooltipText {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  background: #1c2238;
  min-width: 130px;
  text-align: center;
  bottom: calc(100% + 10px);
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  color: #fff;
  z-index: 1001;
  padding: 10px;
  border-radius: 5px;
  line-height: 1.1;
  font-weight: 400;
  font-size: 14px;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.OverviewSection .OverviewCard .WpiTooltip .WpiTooltipText:before {
  position: absolute;
  top: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  content: '';
  border-top: 5px solid #1c2238;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  padding-bottom: 5px;
}

.OverviewSection .OverviewCard .WpiTooltip:hover .WpiTooltipText {
  visibility: visible;
  opacity: 1;
}

.OverviewSection .OverviewCard p {
  font-size: 1rem;
  color: #a9adb9;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.OverviewSection .OverviewCard p:not(:last-of-type) {
  margin-bottom: 2px;
}

@media (max-width: 1600.98px) {
  .OverviewSection .OverviewCard p {
    font-size: 0.9375rem;
  }
}

.OverviewSection .OverviewCard:hover {
  background: #623cea;
}

.OverviewSection .OverviewCard:hover .ProductTitle {
  color: #fff;
}

.OverviewSection .OverviewCard:hover p {
  color: #fff;
}

.OverviewSection .OverviewCard:hover .WpiTooltip .WpiTooltipButton {
  color: #f1f1f1;
}

@media (max-width: 1600.98px) {
  .CounterSection .row-cols-md-4 {
    margin-left: -10px;
    margin-right: -10px;
  }
  .CounterSection .row-cols-md-4.HasColGap {
    margin-bottom: -20px;
  }
  .CounterSection .row-cols-md-4.HasColGap .col {
    margin-bottom: 20px !important;
  }
  .CounterSection .row-cols-md-4 .col {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 1399.98px) {
  .CounterSection .row-cols-md-4 {
    margin-left: -5px;
    margin-right: -5px;
  }
  .CounterSection .row-cols-md-4.HasColGap {
    margin-bottom: -10px;
  }
  .CounterSection .row-cols-md-4.HasColGap .col {
    margin-bottom: 10px !important;
  }
  .CounterSection .row-cols-md-4 .col {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (max-width: 767.98px) {
  .CounterSection .row-cols-md-4 .col {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 499.98px) {
  .CounterSection .row-cols-md-4 .col {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.CounterSection .CounterCard {
  background: #fff;
  padding: 25px;
  border-radius: 20px;
  border: 1.5px solid #f1f1f1;
  height: 100%;
  position: relative;
}

@media (max-width: 1600.98px) {
  .CounterSection .CounterCard {
    padding: 20px;
  }
}

@media (max-width: 575.98px) {
  .CounterSection .CounterCard {
    padding: 10px;
  }
}

.CounterSection .CounterCard.HasSelector {
  position: relative;
}

@media (max-width: 1600.98px) {
  .CounterSection .CounterCard.HasSelector {
    padding-top: 30px;
  }
}

@media (max-width: 575.98px) {
  .CounterSection .CounterCard.HasSelector {
    padding-top: 20px;
  }
}

.CounterSection .CounterCard.HasSelector .dropdown {
  position: absolute;
  right: 10px;
  top: 10px;
}

@media (max-width: 575.98px) {
  .CounterSection .CounterCard.HasSelector .dropdown {
    top: 5px;
    right: 5px;
  }
}

.CounterSection .CounterCard.HasSelector .dropdown .DropdownToggler {
  min-width: 150px !important;
}

@media (max-width: 1199.98px) {
  .CounterSection .CounterCard.HasSelector .dropdown .DropdownToggler {
    min-width: 130px !important;
    min-height: 30px;
    font-size: 0.875rem;
  }
}

@media (max-width: 575.98px) {
  .CounterSection .CounterCard.HasSelector .dropdown .DropdownToggler {
    min-width: 130px !important;
    min-height: 25px;
    font-size: 0.875rem;
  }
}

.CounterSection .CounterCard.HasSelector .CounterCardText {
  margin-top: 10px;
}

.CounterSection .CounterCardContent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.CounterSection .CounterCardContent .CounterCardIcon {
  width: 100px;
  height: 100px;
  min-width: 100px;
  border-radius: 20px;
  font-size: 2.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media (min-width: 1200px) and (max-width: 1600.98px) {
  .CounterSection .CounterCardContent .CounterCardIcon {
    width: 80px;
    height: 80px;
    min-width: 80px;
    font-size: 2rem;
  }
}

@media (max-width: 1199.98px) {
  .CounterSection .CounterCardContent .CounterCardIcon {
    width: 60px;
    height: 60px;
    min-width: 60px;
    font-size: 1.75rem;
  }
}

@media (max-width: 575.98px) {
  .CounterSection .CounterCardContent .CounterCardIcon {
    width: 40px;
    height: 40px;
    min-width: 40px;
    font-size: 1rem;
  }
}

.CounterSection .CounterCardContent .CounterCardText {
  margin-left: 20px;
}

@media (max-width: 575.98px) {
  .CounterSection .CounterCardContent .CounterCardText {
    margin-left: 10px;
  }
}

.CounterSection .CounterCardContent .CounterCardText h4 {
  font-size: 2rem;
  color: #1c2238;
  margin-bottom: 10px;
  font-weight: 700;
}

@media (max-width: 1199.98px) {
  .CounterSection .CounterCardContent .CounterCardText h4 {
    font-size: 1.5rem;
    margin-bottom: 5px;
  }
}

@media (max-width: 575.98px) {
  .CounterSection .CounterCardContent .CounterCardText h4 {
    font-size: 1.5rem;
    margin-bottom: 2px;
  }
}

.CounterSection .CounterCardContent .CounterCardText p {
  font-size: 1.125rem;
  color: #a9adb9;
}

@media (max-width: 1199.98px) {
  .CounterSection .CounterCardContent .CounterCardText p {
    font-size: 1rem;
  }
}

@media (max-width: 575.98px) {
  .CounterSection .CounterCardContent .CounterCardText p {
    font-size: 0.75rem;
  }
}

.CounterSection .CounterCard .IncDec {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #a9adb9;
}

@media (max-width: 1199.98px) {
  .CounterSection .CounterCard .IncDec {
    font-size: 0.9375rem;
  }
}

.CounterSection .CounterCard .IncDec .Icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: 10px;
}

.CounterSection .CounterCard .IncDec.Inc .Icon {
  color: #43bc9c;
}

.CounterSection .CounterCard .IncDec.Dec .Icon {
  color: #e5142e;
}

.CounterSection .CounterCard .WpiTooltip {
  position: absolute;
  top: 10px;
  right: 10px;
}

.CounterSection .CounterCard .WpiTooltip .WpiTooltipButton {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #a9adb9;
  font-size: 16px;
}

.CounterSection .CounterCard .WpiTooltip .WpiTooltipText {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  background: #1c2238;
  min-width: 130px;
  text-align: center;
  bottom: calc(100% + 10px);
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  color: #fff;
  z-index: 1001;
  padding: 10px;
  border-radius: 5px;
  line-height: 1.1;
  font-weight: 400;
  font-size: 14px;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.CounterSection .CounterCard .WpiTooltip .WpiTooltipText:before {
  position: absolute;
  top: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  content: '';
  border-top: 5px solid #1c2238;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  padding-bottom: 5px;
}

.CounterSection .CounterCard .WpiTooltip:hover .WpiTooltipText {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 767.98px) {
  .GraphSection .row {
    margin-left: -5px;
    margin-right: -5px;
  }
  .GraphSection .row.HasColGap {
    margin-bottom: -10px !important;
  }
  .GraphSection .row.HasColGap .col-md-3 {
    margin-bottom: 10px !important;
  }
}

@media (max-width: 1399.98px) {
  .GraphSection .row .col-md-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}

@media (max-width: 767.98px) {
  .GraphSection .row .col-md-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (max-width: 499.98px) {
  .GraphSection .row .col-md-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.GraphSection .GraphCard {
  background: #fff;
  border: 1.5px solid #f1f1f1;
  border-radius: 20px;
  padding: 30px;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.GraphSection .GraphCard .GraphBody {
  margin-top: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.GraphSection .GraphCard .GraphBody img {
  max-width: 100%;
}

.DataViewSection .DataViewFilter {
  background: #fff;
  border: 1.5px solid #f1f1f1;
  border-radius: 20px 20px 0 0;
  padding: 30px;
}

@media (max-width: 767.98px) {
  .DataViewSection .DataViewFilter {
    padding: 25px;
  }
}

@media (max-width: 499.98px) {
  .DataViewSection .DataViewFilter {
    padding: 10px;
  }
}

.DataViewSection .DataViewFilter .row {
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: -20px;
}

@media (max-width: 1199.98px) {
  .DataViewSection .DataViewFilter .row {
    margin-left: -5px;
    margin-right: -5px;
    margin-bottom: -7px;
  }
}

.DataViewSection .DataViewFilter .row .Col-30,
.DataViewSection .DataViewFilter .row .Col-20,
.DataViewSection .DataViewFilter .row .Col-15 {
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
}

@media (max-width: 1199.98px) {
  .DataViewSection .DataViewFilter .row .Col-30,
  .DataViewSection .DataViewFilter .row .Col-20,
  .DataViewSection .DataViewFilter .row .Col-15 {
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 7px;
  }
}

.DataViewSection .DataViewFilter .row .Col-30 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 30%;
  flex: 0 0 30%;
  max-width: 30%;
}

@media (max-width: 1600.98px) {
  .DataViewSection .DataViewFilter .row .Col-30 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 40%;
    flex: 0 0 40%;
    max-width: 40%;
  }
}

@media (max-width: 1399.98px) {
  .DataViewSection .DataViewFilter .row .Col-30 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 991.98px) {
  .DataViewSection .DataViewFilter .row .Col-30 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.DataViewSection .DataViewFilter .row .Col-20 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}

@media (max-width: 1399.98px) {
  .DataViewSection .DataViewFilter .row .Col-20 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media (max-width: 991.98px) {
  .DataViewSection .DataViewFilter .row .Col-20 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 499.98px) {
  .DataViewSection .DataViewFilter .row .Col-20 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.DataViewSection .DataViewFilter .row .Col-15 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 15%;
  flex: 0 0 15%;
  max-width: 15%;
}

@media (max-width: 1600.98px) {
  .DataViewSection .DataViewFilter .row .Col-15 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}

@media (max-width: 1399.98px) {
  .DataViewSection .DataViewFilter .row .Col-15 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media (max-width: 991.98px) {
  .DataViewSection .DataViewFilter .row .Col-15 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 499.98px) {
  .DataViewSection .DataViewFilter .row .Col-15 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.DataViewSection .DataViewHeading {
  background: #fff;
  border: 1.5px solid #f1f1f1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  padding: 30px 30px 0;
  -ms-flex-wrap: wrap-reverse;
  flex-wrap: wrap-reverse;
}

@media (max-width: 1600.98px) {
  .DataViewSection .DataViewHeading {
    padding: 25px 30px 0;
  }
}

@media (max-width: 1399.98px) {
  .DataViewSection .DataViewHeading {
    padding: 20px 25px 0;
  }
}

@media (max-width: 499.98px) {
  .DataViewSection .DataViewHeading {
    padding: 10px 10px 0;
  }
}

.DataViewSection .DataViewHeading:not(:first-child) {
  margin-top: 10px;
}

.DataViewSection .DataViewHeading:first-child {
  border-radius: 20px 20px 0 0;
}

.DataViewSection .DataViewHeadingLeftContent {
  margin-bottom: 27px;
  min-height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-width: 250px;
}

@media (max-width: 1600.98px) {
  .DataViewSection .DataViewHeadingLeftContent {
    min-height: 46px;
  }
}

@media (max-width: 1399.98px) {
  .DataViewSection .DataViewHeadingLeftContent {
    min-width: 200px;
    min-height: 40px;
  }
}

@media (max-width: 575.98px) {
  .DataViewSection .DataViewHeadingLeftContent {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }
  .DataViewSection .DataViewHeadingLeftContent > * {
    margin-bottom: 10px;
  }
}

.DataViewSection .DataViewHeadingLeftContent .form-control,
.DataViewSection .DataViewHeadingLeftContent .SelectOption {
  min-width: 200px;
}

@media (max-width: 1199.98px) {
  .DataViewSection .DataViewHeadingLeftContent .form-control,
  .DataViewSection .DataViewHeadingLeftContent .SelectOption {
    min-width: 160px;
  }
}

.DataViewSection .DataViewHeadingRightContent {
  margin-bottom: 10px;
  min-height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  min-width: 250px;
  margin-left: auto;
}

@media (max-width: 1600.98px) {
  .DataViewSection .DataViewHeadingRightContent {
    min-height: 46px;
  }
}

@media (max-width: 1399.98px) {
  .DataViewSection .DataViewHeadingRightContent {
    min-height: 40px;
  }
}

@media (max-width: 991.98px) {
  .DataViewSection .DataViewHeadingRightContent {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 0px;
  }
  .DataViewSection .DataViewHeadingRightContent > * {
    margin-bottom: 10px;
  }
}

@media (max-width: 575.98px) {
  .DataViewSection .DataViewHeadingRightContent {
    min-width: unset;
  }
}

.DataViewSection .DataViewHeading .TabCard {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.DataViewSection .DataViewHeading .TabCard .TabControl {
  padding-bottom: 30px;
  border-bottom: 5px solid transparent;
  margin-top: 15px;
  cursor: pointer;
  color: #a9adb9;
}

@media (max-width: 991.98px) {
  .DataViewSection .DataViewHeading .TabCard .TabControl {
    padding-bottom: 20px;
  }
}

.DataViewSection .DataViewHeading .TabCard .TabControl:hover {
  color: #1c2238;
}

.DataViewSection .DataViewHeading .TabCard .TabControl.Active {
  border-color: #623cea !important;
  color: #1c2238;
}

.DataViewSection .DataViewHeading .TabCard .TabControl:not(:last-child) {
  margin-right: 30px;
}

.DataViewSection .DataViewTable {
  position: relative;
}

.DataViewSection .DataViewTable .TableWrapper {
  display: block;
  width: 100%;
  overflow-x: auto;
}

.DataViewSection .DataViewTable .TableWrapper .DataTable {
  width: calc(100% - 1px);
  color: #212529;
  border-collapse: collapse !important;
}

.DataViewSection .DataViewTable .TableWrapper .DataTable thead tr th {
  padding: 10px;
}

.DataViewSection .DataViewTable .TableWrapper .DataTable thead tr th .sort {
  margin-left: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
}

.DataViewSection
  .DataViewTable
  .TableWrapper
  .DataTable
  thead
  tr
  th
  .sort
  .sort-up,
.DataViewSection
  .DataViewTable
  .TableWrapper
  .DataTable
  thead
  tr
  th
  .sort
  .sort-down {
  font-size: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #c5c8d0;
}

.DataViewSection
  .DataViewTable
  .TableWrapper
  .DataTable
  thead
  tr
  th
  .sort
  .sort-up.active,
.DataViewSection
  .DataViewTable
  .TableWrapper
  .DataTable
  thead
  tr
  th
  .sort
  .sort-down.active {
  color: #1c2238;
}

.DataViewSection
  .DataViewTable
  .TableWrapper
  .DataTable
  thead
  tr
  th
  .sort
  .sort-up {
  margin-bottom: -1px;
}

.DataViewSection
  .DataViewTable
  .TableWrapper
  .DataTable
  thead
  tr
  th
  .sort
  .sort-down {
  margin-top: -1px;
}

.DataViewSection
  .DataViewTable
  .TableWrapper
  .DataTable
  thead
  tr
  th:first-of-type {
  padding-left: 30px;
}

.DataViewSection
  .DataViewTable
  .TableWrapper
  .DataTable
  thead
  tr
  th:last-of-type {
  padding-right: 15px;
}

.DataViewSection
  .DataViewTable
  .TableWrapper
  .DataTable
  thead
  tr
  .TableToggler {
  width: 70px;
}

.DataViewSection
  .DataViewTable
  .TableWrapper
  .DataTable
  thead
  tr
  .TableToggler
  .TogglerIcon {
  height: 30px;
  width: 30px;
  margin-left: auto;
  padding: 7px;
  background: #623cea;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.DataViewSection
  .DataViewTable
  .TableWrapper
  .DataTable
  thead
  tr
  .TableToggler
  .TogglerIcon:hover {
  background: #4f25e8;
}

.DataViewSection
  .DataViewTable
  .TableWrapper
  .DataTable
  thead
  tr
  .TableToggler
  .TogglerIcon
  img {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.DataViewSection .DataViewTable .TableWrapper .DataTable thead .Selector {
  width: 70px;
}

.DataViewSection .DataViewTable .TableWrapper .DataTable thead .dropdown {
  position: static !important;
}

.DataViewSection
  .DataViewTable
  .TableWrapper
  .DataTable
  thead
  .dropdown
  .dropdown-menu {
  left: calc(100% - 250px) !important;
  top: 40px !important;
}

.DataViewSection .DataViewTable .TableWrapper .DataTable tbody {
  background: #fff;
  border: 2px solid #f1f1f1;
  border-bottom: none !important;
}

.DataViewSection .DataViewTable .TableWrapper .DataTable tbody tr td {
  font-size: 0.875rem;
  padding: 15px 10px;
}

.DataViewSection
  .DataViewTable
  .TableWrapper
  .DataTable
  tbody
  tr
  td:first-of-type {
  padding-left: 30px;
}

.DataViewSection
  .DataViewTable
  .TableWrapper
  .DataTable
  tbody
  tr
  td:last-of-type {
  padding-right: 15px;
}

.DataViewSection
  .DataViewTable
  .TableWrapper
  .DataTable
  tbody
  tr
  td.DetailsButtonWrapper
  .LabelButton {
  margin-top: 0;
  visibility: hidden;
  height: 0;
  min-height: 0;
  opacity: 0;
  -webkit-transition: all 0.5s ease-in-out 0.25s;
  transition: all 0.5s ease-in-out 0.25s;
}

.DataViewSection
  .DataViewTable
  .TableWrapper
  .DataTable
  tbody
  tr
  td.ActionButtonColumn {
  width: 220px;
}

@media (max-width: 1600.98px) {
  .DataViewSection
    .DataViewTable
    .TableWrapper
    .DataTable
    tbody
    tr
    td.ActionButtonColumn {
    width: 200px;
  }
}

.DataViewSection
  .DataViewTable
  .TableWrapper
  .DataTable
  tbody
  tr
  td.ActionButtonBigColumn {
  width: 320px;
}

.DataViewSection
  .DataViewTable
  .TableWrapper
  .DataTable
  tbody
  tr
  td.TeamMemberImage {
  width: 110px;
}

.DataViewSection
  .DataViewTable
  .TableWrapper
  .DataTable
  tbody
  tr
  td.TeamMemberImage
  img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

@media (max-width: 1600.98px) {
  .DataViewSection
    .DataViewTable
    .TableWrapper
    .DataTable
    tbody
    tr
    td.TeamMemberImage
    img {
    width: 46px;
    height: 46px;
  }
}

.DataViewSection
  .DataViewTable
  .TableWrapper
  .DataTable
  tbody
  tr:not(:last-of-type) {
  border-bottom: 1.5px solid #f1f1f1;
}

.DataViewSection .DataViewTable .TableWrapper .DataTable tbody tr.Deactivated {
  color: #e5142e;
}

.DataViewSection
  .DataViewTable
  .TableWrapper
  .DataTable
  tbody
  tr:hover
  .DetailsButtonWrapper
  .LabelButton,
.DataViewSection
  .DataViewTable
  .TableWrapper
  .DataTable
  tbody
  tr:focus
  .DetailsButtonWrapper
  .LabelButton {
  margin-top: 10px;
  height: 24px;
  min-height: 24px;
  visibility: visible;
  opacity: 1;
}

.DataViewSection .DataViewTable .TableWrapper .DataTable tbody .Selector {
  width: 70px;
}

.DataViewSection .DataViewTable .TableWrapper .DataTable.TableWithDetails td {
  vertical-align: top !important;
}

.DataViewSection .DataViewTable .TableWrapper:last-child:after {
  content: '';
  width: 100%;
  height: 20px;
  background: #fff;
  display: block;
  border-radius: 0 0 20px 20px;
  border: 1.5px solid #f1f1f1;
  border-top: none !important;
}

.DataViewSection .DataViewTable .TableFooter {
  padding: 0 30px 10px;
  border: 1.5px solid #f1f1f1;
  border-top: 1px solid #f1f1f1;
  border-radius: 0 0 20px 20px;
  background: #fff;
}

@media (max-width: 767.98px) {
  .DataViewSection .DataViewTable .TableFooter {
    padding: 0 25px 10px;
  }
}

@media (max-width: 499.98px) {
  .DataViewSection .DataViewTable .TableFooter {
    padding: 0 10px 5px;
  }
}

.DataViewSection .DataViewTable .TableFooter .TableFooterContent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 25px 0 20px;
}

@media (max-width: 767.98px) {
  .DataViewSection .DataViewTable .TableFooter .TableFooterContent {
    padding: 15px 0 10px;
  }
}

@media (max-width: 499.98px) {
  .DataViewSection .DataViewTable .TableFooter .TableFooterContent {
    padding: 10px 0 5px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .DataViewSection
    .DataViewTable
    .TableFooter
    .TableFooterContent
    > *:first-child {
    margin-bottom: 7px;
  }
}

.ListViewSection {
  background: #fff;
  border: 1.5px solid #f1f1f1;
  border-radius: 20px;
  height: 100%;
  padding: 20px 0;
}

.ListViewSection .ListView {
  width: 100%;
}

.ListViewSection .ListView .ListViewItem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 20px 30px;
}

@media (max-width: 767.98px) {
  .ListViewSection .ListView .ListViewItem {
    padding: 10px 30px;
  }
}

@media (max-width: 499.98px) {
  .ListViewSection .ListView .ListViewItem {
    padding: 5px 15px;
  }
}

.ListViewSection .ListView .ListViewItem.HasMultipleContent {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-bottom: 10px !important;
}

.ListViewSection .ListView .ListViewItem.HasMultipleContent .ListViewContent {
  min-width: calc(33.3333% - 10px);
  margin-bottom: 10px;
}

@media (min-width: 1601px) {
  .ListViewSection
    .ListView
    .ListViewItem.HasMultipleContent
    .ListViewContent:nth-child(1)
    .ListViewContentTitle,
  .ListViewSection
    .ListView
    .ListViewItem.HasMultipleContent
    .ListViewContent:nth-child(4)
    .ListViewContentTitle,
  .ListViewSection
    .ListView
    .ListViewItem.HasMultipleContent
    .ListViewContent:nth-child(7)
    .ListViewContentTitle {
    min-width: 170px;
  }
}

@media (min-width: 768px) and (max-width: 1600.98px) {
  .ListViewSection .ListView .ListViewItem.HasMultipleContent .ListViewContent {
    min-width: calc(50% - 10px);
  }
  .ListViewSection
    .ListView
    .ListViewItem.HasMultipleContent
    .ListViewContent:nth-child(odd)
    .ListViewContentTitle {
    min-width: 170px;
  }
}

@media (min-width: 768px) and (max-width: 1399.98px) {
  .ListViewSection
    .ListView
    .ListViewItem.HasMultipleContent
    .ListViewContent:nth-child(odd)
    .ListViewContentTitle {
    min-width: 140px;
  }
}

@media (max-width: 767.98px) {
  .ListViewSection .ListView .ListViewItem.HasMultipleContent .ListViewContent {
    min-width: calc(100% - 10px);
  }
  .ListViewSection
    .ListView
    .ListViewItem.HasMultipleContent
    .ListViewContent
    .ListViewContentTitle {
    min-width: 120px;
  }
}

.ListViewSection .ListView .ListViewItem .ListViewContent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 499.98px) {
  .ListViewSection .ListView .ListViewItem .ListViewContent {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
}

.ListViewSection
  .ListView
  .ListViewItem
  .ListViewContent
  .ListViewContentTitle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 30px;
}

@media (max-width: 499.98px) {
  .ListViewSection
    .ListView
    .ListViewItem
    .ListViewContent
    .ListViewContentTitle {
    margin-right: 0;
    margin-bottom: 5px;
  }
}

.ListViewSection
  .ListView
  .ListViewItem
  .ListViewContent
  .ListViewContentTitle:before {
  content: '';
  height: 8px;
  width: 8px;
  min-width: 8px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 10px;
}

.ListViewSection .ListView .ListViewItem .ListViewContent .AlertGroup {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: -20px;
  margin-right: -10px;
  margin-left: -10px;
}

@media (max-width: 1600.98px) {
  .ListViewSection .ListView .ListViewItem .ListViewContent .AlertGroup {
    margin-bottom: -10px;
  }
}

@media (max-width: 767.98px) {
  .ListViewSection .ListView .ListViewItem .ListViewContent .AlertGroup {
    margin-bottom: -10px;
    margin-left: 0;
    margin-right: 0;
  }
}

.ListViewSection .ListView .ListViewItem .ListViewContent .AlertGroup .Alert {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(33.333333% - 20px);
  flex: 0 0 calc(33.333333% - 20px);
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

@media (max-width: 1600.98px) {
  .ListViewSection .ListView .ListViewItem .ListViewContent .AlertGroup .Alert {
    margin-bottom: 10px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(50% - 20px);
    flex: 0 0 calc(50% - 20px);
  }
}

@media (max-width: 767.98px) {
  .ListViewSection .ListView .ListViewItem .ListViewContent .AlertGroup .Alert {
    margin-bottom: 10px;
    margin-left: 0;
    margin-right: 0;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(100%);
    flex: 0 0 calc(100%);
  }
}

.ListViewSection
  .ListView
  .ListViewItem
  .ListViewContent
  .AlertGroup
  .Alert.Active {
  position: relative;
}

.ListViewSection
  .ListView
  .ListViewItem
  .ListViewContent
  .AlertGroup
  .Alert.Active:before {
  content: '\e906';
  font-family: 'WP-Insight' !important;
  height: 20px;
  width: 20px;
  font-size: 0.6275rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50px;
  background: #43bc9c;
  color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  -webkit-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
}

.ListViewSection
  .ListView
  .ListViewItem
  .ListViewContent:first-child
  .ListViewContentTitle {
  min-width: 170px;
}

@media (max-width: 1399.98px) {
  .ListViewSection
    .ListView
    .ListViewItem
    .ListViewContent:first-child
    .ListViewContentTitle {
    min-width: 140px;
  }
}

@media (max-width: 767.98px) {
  .ListViewSection
    .ListView
    .ListViewItem
    .ListViewContent:first-child
    .ListViewContentTitle {
    min-width: 120px;
  }
}

.ListViewSection .ListView .ListViewItem .ListViewContent:not(:last-child) {
  margin-right: 10px;
}

.ListViewSection .ListView .ListViewItem:hover,
.ListViewSection .ListView .ListViewItem:focus {
  background: #f9f9fb;
}

.ListViewSection .ListView .ListViewItem:not(:last-child) {
  border-bottom: 1.5px solid #f1f1f1;
}

.DetailsViewSection {
  background: #fff;
  border: 1.5px solid #f1f1f1;
  border-radius: 20px;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.DetailsViewSection .DetailsView {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.DetailsViewSection .DetailsView .DetailsViewHeading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px 30px;
  border-bottom: 1px solid #f1f1f1;
}

@media (max-width: 575.98px) {
  .DetailsViewSection .DetailsView .DetailsViewHeading {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding: 10px 15px;
  }
}

.DetailsViewSection .DetailsView .DetailsViewHeading > *:not(:last-child) {
  margin-right: 10px;
}

@media (max-width: 575.98px) {
  .DetailsViewSection .DetailsView .DetailsViewHeading > *:not(:last-child) {
    margin-right: 0;
    margin-bottom: 5px;
  }
}

.DetailsViewSection .DetailsView .DetailsViewContent {
  padding: 30px;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

@media (max-width: 1600.98px) {
  .DetailsViewSection .DetailsView .DetailsViewContent .row {
    margin-bottom: -15px !important;
  }
  .DetailsViewSection .DetailsView .DetailsViewContent .row .col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 15px !important;
  }
}

@media (max-width: 575.98px) {
  .DetailsViewSection .DetailsView .DetailsViewContent {
    padding: 15px;
  }
  .DetailsViewSection .DetailsView .DetailsViewContent .row {
    margin-bottom: -10px !important;
  }
  .DetailsViewSection .DetailsView .DetailsViewContent .row .col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 10px !important;
  }
}

.DetailsViewSection .DetailsView .DetailsViewContent .PaginationList {
  margin-top: 30px;
}

.DetailsViewSection .DetailsView .ListViewContent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 499.98px) {
  .DetailsViewSection .DetailsView .ListViewContent {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
}

.DetailsViewSection .DetailsView .ListViewContent .ListViewContentTitle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 30px;
}

@media (max-width: 499.98px) {
  .DetailsViewSection .DetailsView .ListViewContent .ListViewContentTitle {
    margin-right: 0;
    margin-bottom: 5px;
  }
}

.DetailsViewSection .DetailsView .ListViewContent .ListViewContentTitle:before {
  content: '';
  height: 8px;
  width: 8px;
  min-width: 8px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 10px;
}

.DetailsViewSection .DetailsView .ListViewContent .AlertGroup {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.DetailsViewSection .DetailsView .ListViewContent .AlertGroup .Alert {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.DetailsViewSection
  .DetailsView
  .ListViewContent
  .AlertGroup
  .Alert:not(:last-child) {
  margin-right: 30px;
}

.DetailsViewSection
  .DetailsView
  .ListViewContent:first-child
  .ListViewContentTitle {
  min-width: 170px;
}

.FormViewSection {
  background: #fff;
  border: 1.5px solid #f1f1f1;
  border-radius: 20px;
  height: 100%;
  padding: 30px;
}

@media (max-width: 499.98px) {
  .FormViewSection {
    padding: 15px;
  }
}

.FormViewSection .FormViewDataWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
}

@media (max-width: 1399.98px) {
  .FormViewSection .FormViewDataWrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
}

.FormViewSection .FormViewDataWrapper .FormViewDataGroupLeft {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.FormViewSection .FormViewDataWrapper .UpdateOption {
  margin-left: 40px;
  width: 120px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

@media (max-width: 1399.98px) {
  .FormViewSection .FormViewDataWrapper .UpdateOption {
    margin-left: 0px;
    margin-bottom: 40px;
  }
}

.FormViewSection .FormViewGroup {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media (max-width: 767.98px) {
  .FormViewSection .FormViewGroup {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.FormViewSection .FormViewGroup .FormViewTitle {
  min-width: 220px;
  margin-right: 20px;
  margin-top: 15px;
}

@media (max-width: 1399.98px) {
  .FormViewSection .FormViewGroup .FormViewTitle {
    min-width: 180px;
    margin-top: 12px;
  }
}

@media (max-width: 767.98px) {
  .FormViewSection .FormViewGroup .FormViewTitle {
    margin-top: 0 !important;
    margin-bottom: 3px;
  }
}

.FormViewSection .FormViewGroup .FormViewData,
.FormViewSection .FormViewGroup .FormViewDataPretext {
  width: 100%;
  padding: 13.5px 20px;
  border: 1.5px solid #f1f1f1;
  border-radius: 10px;
  word-break: break-word;
}

@media (max-width: 1600.98px) {
  .FormViewSection .FormViewGroup .FormViewData,
  .FormViewSection .FormViewGroup .FormViewDataPretext {
    padding: 12.5px 20px;
  }
}

@media (max-width: 1600.98px) {
  .FormViewSection .FormViewGroup .FormViewData,
  .FormViewSection .FormViewGroup .FormViewDataPretext {
    padding: 10.5px 20px;
  }
}

.FormViewSection .FormViewGroup .FormViewData.TextArea,
.FormViewSection .FormViewGroup .TextArea.FormViewDataPretext {
  min-height: 200px;
}

.FormViewSection .FormViewGroup .FormViewDataPretext {
  white-space: pre-wrap;
  background: #1c2238;
  color: #a9adb9;
  padding: 50px;
}

.FormViewSection .FormViewGroup:not(:last-child) {
  margin-bottom: 20px;
}

@media (max-width: 767.98px) {
  .FormViewSection .FormViewGroup:not(:last-child) {
    margin-bottom: 10px;
  }
}

.UserRoleSetTable .SelectOption {
  min-width: 250px;
}

.TabControllerWrapper {
  background: #ededf3;
  padding: 30px;
  border-radius: 20px;
  border: 1px solid #f1f1f1;
}

@media (max-width: 499.98px) {
  .TabControllerWrapper {
    padding: 15px;
  }
}

.TabControllerWrapper .nav-item .TabButton {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .TabControllerWrapper .nav-item .TabButton {
    padding: 4px 10px;
    min-width: 70px;
    min-height: 36px;
  }
}

@media (max-width: 499.98px) {
  .TabControllerWrapper .nav-item .TabButton {
    padding: 4px 10px;
    min-width: 50px;
    min-height: 32px;
  }
}

.TabControllerWrapper .nav-item:first-child .TabButton {
  border-top-left-radius: 10px;
}

@media (max-width: 575.98px) {
  .TabControllerWrapper .nav-item:first-child .TabButton {
    border-top-left-radius: 5px;
  }
}

.TabControllerWrapper .nav-item:last-child .TabButton {
  border-top-right-radius: 10px;
}

@media (max-width: 575.98px) {
  .TabControllerWrapper .nav-item:last-child .TabButton {
    border-top-right-radius: 5px;
  }
}

.TabControllerWrapper .tab-content {
  background: #fff;
  padding: 30px;
  border-radius: 10px;
  border: 1px solid #f1f1f1;
}

@media (max-width: 499.98px) {
  .TabControllerWrapper .tab-content {
    padding: 15px;
  }
}

.TabControllerWrapper .tab-content .CustomFieldCard {
  margin-bottom: 30px;
  height: calc(100% - 30px) !important;
  background: #ededf3;
  border: 1px solid #f1f1f1;
  border-radius: 10px;
}

@media (max-width: 499.98px) {
  .TabControllerWrapper .tab-content .CustomFieldCard {
    margin-bottom: 15px;
    height: calc(100% - 15px) !important;
  }
}

.TabControllerWrapper .tab-content .CustomFieldCard .Checkbox {
  font-size: 1rem;
  display: block;
}

.TabControllerWrapper
  .tab-content
  .CustomFieldCard
  .Checkbox
  input[type='checkbox']
  + span:before {
  color: #623cea;
  font-size: 0.875rem;
}

.TabControllerWrapper
  .tab-content
  .CustomFieldCard
  .Checkbox
  input[type='checkbox']:checked
  + span:before {
  color: #623cea;
}

.TabControllerWrapper .tab-content .CustomFieldCard .Checkbox span {
  color: #1c2238;
}

.TabControllerWrapper .tab-content .CustomFieldCard .Checkbox:not(:last-child) {
  margin-bottom: 7px;
}

.TabControllerWrapper .tab-content .CustomFieldCardHead {
  padding: 15px 20px;
  border-bottom: 1px solid #f9f9fb;
}

@media (max-width: 499.98px) {
  .TabControllerWrapper .tab-content .CustomFieldCardHead {
    padding: 10px 10px;
  }
}

.TabControllerWrapper
  .tab-content
  .CustomFieldCardHead:not(.CheckLeft)
  .Checkbox {
  display: block;
  position: relative;
  padding-right: 15px;
}

.TabControllerWrapper
  .tab-content
  .CustomFieldCardHead:not(.CheckLeft)
  .Checkbox
  span:before {
  position: absolute;
  margin-right: 0;
  right: 0;
}

.TabControllerWrapper .tab-content .CustomFieldCardBody {
  padding: 25px 20px;
}

@media (max-width: 499.98px) {
  .TabControllerWrapper .tab-content .CustomFieldCardBody {
    padding: 15px 10px;
  }
}

.TabControllerWrapper .tab-content:not(:first-child) {
  border-top-left-radius: 0;
}
